import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Util } from '../interceptors/util';
import { UserauthenticationserviceService } from '../services/userauthenticationservice.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit, AfterViewInit {

  constructor(private activatedroute: ActivatedRoute, private userauthservice: UserauthenticationserviceService, private toastr: ToastrService, private router: Router, private util: Util) { }
  async ngAfterViewInit() {
    //debugger;
    // if(this.activatedroute.snapshot.queryParams.externaltoken){
      
    //   const subdomain = this.util.getSubdomain();
    //   const data: any = await this.userauthservice.externalUserAuthentication(this.activatedroute.snapshot.queryParams.externaltoken, subdomain).toPromise().catch(err => { this.toastr.error(err); });
    //   this.userauthservice.setAuthenticatedUserToken(data.token, true);
    //   this.router.navigate(['/exam/userexams']);
    // }
    // else{
      
    // }
    //queryparams will be empty except when login in through external sources
    console.log("queryparams are "+JSON.stringify(this.activatedroute.snapshot.queryParams))
    this.router.navigate(['/access/login'], {queryParams: this.activatedroute.snapshot.queryParams});
  }

  async ngOnInit() {
    
  }

}
