import { Component, ElementRef, EventEmitter, Host, OnInit, Output, ViewChild } from '@angular/core';
import { Participant } from 'src/app/meeting/models/Participant';
import { MeetingHandlerService } from 'src/app/meeting/services/meeting-handler.service';
import {ChatMessage} from '../models/ChatMessage';
import { MeetingComponent } from '../meeting/meeting.component';
import { UtilService } from 'src/app/meeting/services/util.service';

declare const $: any;

@Component({
  selector: 'app-meeting-chat',
  templateUrl: './meeting-chat.component.html',
  styleUrls: ['./meeting-chat.component.scss']
})
export class MeetingChatComponent implements OnInit {
  
  sending: boolean = false;
  opened: boolean = false;
  unreadMessages: number = 0;
  @ViewChild('chattextarea') chattextarea: ElementRef;
  @ViewChild('chatscroll') chatscroll: ElementRef;
  @Output() closeEvent: EventEmitter<void> = new EventEmitter<void>();

  constructor(@Host() private meeting: MeetingComponent, 
              public meetingHandler: MeetingHandlerService,
              public util: UtilService) { 
    this.meeting.chatOpenedClosedSubject.subscribe((opened: boolean) => {
      this.chatOpenedClosed(opened);
    });
  }

  get open(){
    return this.meeting.chatopen;
  }

  async ngOnInit() {
    this.meetingHandler.chatAddedSubject.subscribe((params) => {this.chatAdded(params) ;});
  }

  chatOpenedClosed(opened: boolean){
    this.opened = opened;
    if(this.opened){
      this.scrollToBottomOfChat();
      this.unreadMessages = 0;
    }
  }

  // async getCurrentMessages(){
  //   const temp: any = await this.meeting.sendMediasoupControlRequest('getCurrentChatMessages', {});
  //   //debugger;
  //   for(let i = 0; i < temp.length; i++){
  //     const participant = this.getMessageParticipant(temp[i]);
  //     const chatmessage: ChatMessage = { senderusername: temp[i].username, message: temp[i].message, sendername: participant.name, senderphotourl: participant.photourl };
  //     this.addChat(chatmessage, true);
  //   }
  //   this.scrollToBottomOfChat();
  // }

  async sendMessage(fromKeyDown: boolean = false, event: Event = undefined){
    //console.log(event);
    const messagetext: string = this.chattextarea.nativeElement.value.trim();
    
    if(messagetext){
      this.sending = true;
      try{
        await this.meetingHandler.sendChatMessage(messagetext).catch((reason) => {
          console.error(reason);
          this.sending = false;
        });
        this.meetingHandler.addChat({ senderusername: this.meetingHandler.localuserinfo.username, 
                                      message: messagetext, 
                                      sendername: this.meetingHandler.meParticipant.name, 
                                      senderphotourl: this.meetingHandler.meParticipant.photourl,
                                      sendersocketid: this.meetingHandler.meParticipant.socketid }, false);
        this.chattextarea.nativeElement.value = '';  
        this.scrollToBottomOfChat();
      }
      finally{
        this.sending = false;
      }
    }
  }

  

  chatScrolledToBottom(){
    //debugger;
    const differenceFromBottom = Math.abs($(this.chatscroll.nativeElement).scrollTop() + $(this.chatscroll.nativeElement).innerHeight() - $(this.chatscroll.nativeElement)[0].scrollHeight);
    if( differenceFromBottom <= 5) {//if we are at the bottom or really close to it
      return true;
    }
    else{
      return false;
    }
  }

  scrollToBottomOfChat(){
    setTimeout(() => {
      $(this.chatscroll.nativeElement).scrollTop(100000000);
    }, 50);
  }

  chatAdded(params: any){//TODO: this might not work because the message is now added before this is called
    if(!this.open){
      this.unreadMessages++;
    }
    
    let {message, bulk} = params;

    let scrolledtobottom = false;
    if(!bulk){
      scrolledtobottom = this.chatScrolledToBottom();
    }
    
    if(!bulk && scrolledtobottom){
      this.scrollToBottomOfChat();
    }
  }

  close(){
    this.closeEvent.emit();
  }

}
