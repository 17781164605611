import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ExamDetails, ScheduleQuestionAndOptions } from '../models/Exam';
import { CbtserviceService } from '../services/cbtservice.service';
import { ExamService } from '../services/exam.service';
import { QuestionserviceService } from '../services/questionservice.service';

import { ProctoringService } from '../services/proctoring.service';
import { UserauthenticationserviceService } from '../services/userauthenticationservice.service';
import { environment } from 'src/environments/environment';
import { Util } from '../interceptors/util';
import { QuestionpagefooterComponent } from '../questionpagefooter/questionpagefooter.component';
import { CurrentUser } from '../models/CurrentUser';
import { OnlineService } from '../services/online.service';
import { LanguageService } from '../services/language.service';
import { Subject, Subscription } from 'rxjs';
import { CalculatorToggleService } from '../services/calculator-toggle.service';
@Component({
  selector: 'app-questionlayout',
  templateUrl: './questionlayout.component.html',
  styleUrls: ['./questionlayout.component.css'],
})
export class QuestionlayoutComponent implements OnInit, OnDestroy {
  options: string[];
  countdown: any;
  scheduleid: number;
  questionno: number;
  previousscheduleid: number;
  onblurfunction;
  onfocusfunction;
  usecalc: boolean = false
  calcInterval: any;
  calcmode: any;
  private destroyed: boolean = false;
  isCalculatorVisible = false;

  @ViewChild('quesfooter') private questionFooter: QuestionpagefooterComponent;
  get adminpaused() {
    return this.proctorservice.pauseStatus.adminpaused;
  }
  get proctorpaused() {
    return this.proctorservice.pauseStatus.proctorpaused;
  }
  get pausereason() {
    return this.proctorservice.pauseStatus.pausereason;
  }

  timeleft: number;
  status: string;
  markingerrormessage: string;
  dummyQuestionsForFooter: any[];
  timeSpentInterval: any;
  examid: number;
  loadingSubject = new Subject<boolean>();
  networkFailureSubject = new Subject<boolean>();
  timeElapsedSubscription: Subscription;
  toggleCalculatorSubscription: Subscription;

  constructor(
    public questionservice: QuestionserviceService,
    public cbtservice: CbtserviceService,
    public router: Router,
    public activatedroute: ActivatedRoute,
    public examservice: ExamService,
    public toastr: ToastrService,
    private proctorservice: ProctoringService,
    private userauth: UserauthenticationserviceService,
    public util: Util,
    public onlineService: OnlineService,
    private languageService: LanguageService,
    private calculatorToggleService: CalculatorToggleService
  ) {
    this.onlineService.userOffline.subscribe((offline) => {
      if (offline) {
        this.router.navigate(['/logout'], { queryParams: { stayinseb: 1 } });
      }
    });
  }
  get language() {
    return this.languageService.language;
  }
  question: ScheduleQuestionAndOptions = new ScheduleQuestionAndOptions();
  examination: ExamDetails = new ExamDetails();
  questioncount: number;
  //currentoption
  private isLoadingDataLevel: number = 0;
  losscount: number = 0;
  lossactionmsg: string = '';
  user: CurrentUser = new CurrentUser();
  thisurl = location.origin + '/';
  focuslosswarningmessage: string = '';
  lostfocus: boolean = false;
  retrievecountdown: any;

  answersubmissioncheck: any;
  markingnotworking: boolean = false;
  //answersubmissionservercheck: any;
  unpauseinterval: any;
  revalidateuserinterval: any;

  get isLoadingData() {
    return this.isLoadingDataLevel > 0;
  }
  //we are doing setIsLoadingData this way so that different scopes can set loading status without knowing what the parent/child will do
  setIsLoadingData(isLoading: boolean) {
    this.isLoadingDataLevel += isLoading ? 1 : -1;
    //console.trace(`isLoadingDataLevel: ${this.isLoadingDataLevel}`);
    if (this.isLoadingDataLevel < 0) {
      console.warn(`isLoadingDataLevel went below 0`);
      console.trace(`isLoadingDataLevel: ${this.isLoadingDataLevel}`);
      this.isLoadingDataLevel = 0;
      if (!environment.production) {
        this.toastr.error(`isLoadingDataLevel went below 0`);
        throw new Error(`isLoadingDataLevel went below 0`);
      }
    }
  }

  async ngOnInit() {
    this.loadingSubject.subscribe((loading: boolean) => {
      this.setIsLoadingData(loading);
    });

    this.networkFailureSubject.subscribe((fail: boolean) => {
      this.questionservice.markingnotworking = fail;
    });

    this.timeElapsedSubscription = this.examservice.examTimeElapsed$.subscribe(
      (elapsed) => {
        if (elapsed) {
          this.timeelapsed(elapsed);
        }
      }
    );

    this.toggleCalculatorSubscription = this.calculatorToggleService.toggleCalculator$
    .subscribe(() => {
     this.toggleCalculator();
    });

    this.calcInterval = setInterval(() => {
      this.usecalc = this.util.usecalculator
      this.calcmode = this.util.calculatormode
    }, 1000)

    try {
      this.setIsLoadingData(true);
      //calling loadPageData here to make sure we load the examination details
      //this.questionservice.getexam
      this.scheduleid = this.activatedroute.snapshot.queryParams.scheduleid;
      this.examid = this.activatedroute.snapshot.queryParams.examid;

      try {
        this.examination = await this.examservice.getexamwithscheduleid(
          this.scheduleid,
          this.examid
        );
      } catch (error) {
        this.cbtservice.showHttpError(error);
        throw error;
      }
      await this.loadPageData(this.activatedroute.snapshot.queryParams);

      this.dummyQuestionsForFooter = [];
      const allQuestions =
        await this.questionservice.getAllQuestionDataFromStorage(
          this.scheduleid,
          this.examination.liteMode,
          this.examination.useCaptcha,
          this.examid
        );

      for (let i = 0; i < this.question.questionCount; i++) {
        //
        //when a question is answered, we don't save it to the encrypted storage
        //so, if the page is refreshed, the question selection data is loaded from storage if it exists
        //that storage hasn't been updated with the "answered" status because we shouldn't have to decrypt and encrypt again for each time the question is answered
        //so to know if the question has been answered, we also need to check if the answer is stored in local storage
        //this problem does not exist if you logout and login again because on logout, the local storage is cleared so everything will be loaded from the server afresh
        //and that will have the correct answered status
        let answered;
        if (this.examination.examtype != this.questionservice.Essay) {
          answered =
            !!localStorage.getItem(
              `answerquestion_${allQuestions.questions[i].examQuestion.schedulequestionid}`
            ) || allQuestions.questions[i].examQuestion.answered;
        } else {
          const answer = this.questionservice.getEssayAnswerFromStorage(
            allQuestions.questions[i].examQuestion.schedulequestionid
          );
          answered = this.questionservice.essayAnswerIsNotBlank(answer);
        }
        this.dummyQuestionsForFooter.push({
          questionno: i + 1,
          answered,
          flagged: allQuestions.questions[i].examQuestion.flagged,
        });
      }

      //this.questionservice.startAnswerSubmission();
      this.user = await this.userauth.getCurrentUser();
      if (!this.user) {
        this.toastr.error(this.language.userSessionNotFound);
        this.router.navigate(['/access/login']);
        return;
      }
      //this.proctorservice.stopProctor();//just in case
      this.urlSubsription();

      if (this.examination.useproctor) {
        //this.proctorservice.setProctorPauseStatus(this.user.username, this.proctorpaused, this.adminpaused, this.pausereason);
        this.proctorservice.username = this.user.username;
        this.proctorservice.pauseStatus = {
          proctorpaused: this.proctorpaused,
          adminpaused: this.adminpaused,
          pausereason: this.pausereason,
        };
        this.proctorservice.setProctorPauseStatus();
        this.proctorservice.setCountdownTimer(
          this.user.username,
          this.timeleft
        );
      }
      if (!this.examination.liteMode) {
        let iscountingdown = false;
        this.answersubmissioncheck = setInterval(() => {
          const markingNotWorkingHasChanged =
            this.markingnotworking != this.questionservice.markingnotworking;
          this.markingnotworking = this.questionservice.markingnotworking;
          if (markingNotWorkingHasChanged) {
            this.setIsLoadingData(this.markingnotworking);
          }
          this.markingerrormessage =
            this.language.networkConnectionLost + '...';
          if (this.markingnotworking && !iscountingdown) {
            iscountingdown = true;
            this.onlineService.startCountdown();
          } else if (!this.markingnotworking) {
            iscountingdown = false;
            this.onlineService.reset();
          }
        }, 500);

        //we don't need to keep checking isalive because on the server side, we are ensuring that the user is uploading video when the answer is submitted
        // this.revalidateuserinterval = setInterval(async () => {
        //   //doing this interval to ensure that an invalid candidate can't stay logged in and streaming
        //   const data = await this.userauth.getCurrentUser(true);
        //   if(!data){
        //     this.toastr.error('Invalid session');
        //     this.router.navigate(["/logout"]);
        //   }
        // }, 10 * 1000);

        let notmarkedonserver = this.questionservice
          .getAllQuestionsAnswer()
          .filter((q) => !q.saved)
          .map((x) => x.schedulequestionid);
        if (notmarkedonserver.length == 0) {
          this.questionservice.markingnotworking = false;
        }

        notmarkedonserver.forEach(async (x, index) => {
          let data = this.questionservice.getAnsweredquestion(x);
          data.examid = this.examid;
          this.questionservice.markanswer(data);
          if (index < notmarkedonserver.length - 1) {
            await this.util.sleep(1000); //we don't want to request too many captchas via mark answer with too high a frequency
          }
        });
      }
    } finally {
      this.setIsLoadingData(false);
    }
  }

  ngOnDestroy(): void {
    //this.questionservice.endAnswerSubmission();
    this.destroyed = true;
    this.examservice.resetExamTime();

    if (this.onblurfunction) {
      window.removeEventListener('focus', this.onfocusfunction);
      window.removeEventListener('blur', this.onblurfunction);
    }
    clearInterval(this.retrievecountdown);
    // if (this.answersubmissionservercheck) {
    //   clearInterval(this.answersubmissionservercheck);
    // }
    if (this.answersubmissioncheck) {
      clearInterval(this.answersubmissioncheck);
    }
    this.util.usecalculator = false;
    clearInterval(this.unpauseinterval);
    this.retrievecountdown = null;
    this.unpauseinterval = null;
    clearInterval(this.revalidateuserinterval);
    this.revalidateuserinterval = null;
    clearInterval(this.timeSpentInterval);
    this.timeSpentInterval = null;

    
    this.timeElapsedSubscription?.unsubscribe();
    
    if (this.calcInterval) {
      clearInterval(this.calcInterval);
      this.calcInterval = null;
    }

    this.toggleCalculatorSubscription?.unsubscribe();
  }

  async ngAfterViewInit() {
    console.log('retrieve status');
    this.retrieveExamStatus();
    if (!this.unpauseinterval /*&& this.examination.useproctor*/) {
      this.unpauseinterval = this.pinterval();
    }
    this.timeSpentInterval = this.pingTimeSpentInterval();
  }

  async retrieveStatus() {
    if (
      this.examination.requireadminstart &&
      !this.examination.examstartedbyadmin
    ) {
      try {
        const data = await this.questionservice.getquestionandoptions(
          1,
          this.examination.liteMode,
          this.examination.useCaptcha,
          this.scheduleid,
          this.examination.examid
        );
        this.dataretrievedmanipulation(data);

        if (this.retrievecountdown && this.examination.examstartedbyadmin) {
          clearInterval(this.retrievecountdown);
        }
      } catch (error) {
        this.cbtservice.showHttpError(error);
        throw error;
      }
    }
    // console.log(`exam status ${this.examination.examstartedbyadmin}`);
  }

  retrieveExamStatus() {
    this.retrievecountdown = this.ctdinterval();
  }

  ctdinterval() {
    return setInterval(() => {
      if (!this.examination.examstartedbyadmin) {
        this.retrieveStatus();
      }
    }, 1000 * 2);
  }

  async retrievePauseStatus() {
    if (this.scheduleid) {
      let liteMode = this.examination.liteMode;
      var data: {
        reason: string;
        proctorpaused: boolean;
        adminpaused: boolean;
        tleft: number;
        status: string;
      } = await this.examservice
        .getexampausestatusandtimeleftschedule(
          this.scheduleid,
          liteMode,
          this.examid
        )
        .catch((err) => {
          this.cbtservice.showHttpError(err);
          return;
        });
      if (data) {
        const existingPauseStatus = this.proctorservice.pauseStatus;
        this.proctorservice.pauseStatus = {
          pausereason: data.reason,
          proctorpaused: data.proctorpaused,
          adminpaused: data.adminpaused,
        };
        if (this.examination.useproctor) {
          if (
            existingPauseStatus.proctorpaused !=
              this.proctorservice.pauseStatus.proctorpaused ||
            existingPauseStatus.adminpaused !=
              this.proctorservice.pauseStatus.adminpaused ||
            existingPauseStatus.pausereason !=
              this.proctorservice.pauseStatus.pausereason
          ) {
            this.proctorservice.setProctorPauseStatus();
          }
        }

        if (!liteMode) {
          if (data?.tleft !== undefined) {
            this.timeleft = data.tleft;
            this.examservice.updateExamTime(data.tleft);
          }
          this.examservice.updateExamPaused(
            this.adminpaused || this.proctorpaused
          );

          await this.questionservice.updateQuestionTimeleft(
            this.scheduleid,
            liteMode,
            this.examination.useCaptcha,
            data.tleft,
            this.examid
          );
        }
        this.status = data.status;
      }
      if (!this.adminpaused && !this.proctorpaused) {
        //clearInterval(this.unpauseinterval);
        if (!this.question.examQuestion.questionhtml) {
          //means the question was retreived without html most likely because the exam was paused when the question was being retrieved
          //await this.retrieveStatus(this.questionno);
          await this.loadPageData(this.activatedroute.snapshot.queryParams);
        }
      }
    }
  }

  pinterval() {
    return setInterval(() => {
      this.retrievePauseStatus();
      // if (this.examination.useproctor) {
      //   this.proctorservice.setProctorPauseStatus(this.user.username, this.proctorpaused, this.adminpaused, this.pausereason);
      // }
    }, 1000 * 10);
  }

  onFocus() {
    clearTimeout(this.countdown);
    //console.log('OnPageVisibilityChange => visible');
    if (this.lostfocus && this.focuslosswarningmessage != '') {
      this.toastr.warning(this.focuslosswarningmessage);
    }
  }
  onBlur() {
    if (this.examination.checkfocusloss && !this.proctorservice.chatopened) {
      let audio = new Audio();
      audio.src = '/assets/beep.mp3';
      audio.loop = false;
      audio.load();
      audio.play();
      // console.log("audio played");
      const mythis = this;
      this.countdown = setTimeout(() => {
        if (!mythis.proctorservice.chatopened) {
          this.setres();
        }
        // if ((this.examination.nooffocusloss - this.losscount) === 1) {
        //   audio.play();
        //   console.log("audio played")
        // }
      }, 1000 * this.examination.durationofloss);
    }
  }
  urlSubsription() {
    this.activatedroute.queryParams.subscribe(async (routeParams) => {
      await this.loadPageData(routeParams);
    });

    // console.log(JSON.stringify(this.examination))
    this.cbtservice.getPreviousUrl();
    this.losscount = this.question.focuslosscount;
  }

  private async loadPageData(routeParams) {
    //var tobesaved = this.questionservice.previousquestiondata;
    // console.log("to be saved " + JSON.stringify(tobesaved))
    this.setIsLoadingData(true);
    this.scheduleid = routeParams.scheduleid;

    this.questionno = routeParams.questionno;
    let data: ScheduleQuestionAndOptions;

    try {
      // if (!this.questionservice.nextquestiondata) {
      //   // this.allQuestionData = await this.questionservice.getAllQuestionDataFromStorage(this.scheduleid, this.examination.liteMode, this.examination.useCaptcha);
      //   data = await this.questionservice.getquestionandoptions(this.questionno, this.examination.liteMode, this.examination.useCaptcha, this.scheduleid, this.examination.examid);
      // } else {
      //   data = this.questionservice.nextquestiondata;
      //   // this.isLodingDataLevel=false
      // }

      data = await this.questionservice.getquestionandoptions(
        this.questionno,
        this.examination.liteMode,
        this.examination.useCaptcha,
        this.scheduleid,
        this.examination.examid
      );
      this.dataretrievedmanipulation(data);
    } catch (error) {
      //debugger;
      console.error(error);
      this.cbtservice.showHttpError(error);

      throw error;
    } finally {
      this.setIsLoadingData(false);
    }
    // console.log(JSON.stringify(data) + "is data")
  }

  async dataretrievedmanipulation(data: ScheduleQuestionAndOptions) {
    //debugger;
    // if (data.isnotlatestlogin) {
    //   this.toastr.error(this.language.multipleLoginsDetected);
    //   this.router.navigate(['/logout']);
    //   // window.location.href = `${this.thisurl}logout`;

    // }

    // if (data.logout) {
    //   this.toastr.error(this.language.unauthorizedAccess);

    //   this.router.navigate(['/logout'], { queryParams: { unauthorized: true } });
    //   // window.location.href = `${this.thisurl}logout?unauthorized=true`;

    // }

    if (data.examination.status && data.examination.status != 'InProgress') {
      //debugger;
      this.router.navigate(['/exam/submit'], {
        queryParams: { scheduleid: this.scheduleid, examid: this.examid },
      });
      // window.location.href = `${this.thisurl}exam/submit?scheduleid=${this.scheduleid}`;
    }
    this.examination = data.examination;
    //this.exam = await this.examservice.getexamwithscheduleid(this.scheduleid).toPromise().catch(err => this.toastr.error(err));
    if (this.examination.useproctor) {
      if (
        this.previousscheduleid &&
        this.previousscheduleid != this.scheduleid
      ) {
        await this.proctorservice.stopProctor('Schedule Id Mismatch'); //whenever we move from one exam to another, we have to stop the proctor to ensure another one starts
        if(!environment.production){
          debugger;
          console.trace('going to exams page');
        }
        this.router.navigate(['/exam/userexams']);
        return;
      }
      // //TODO: ensure the following happens in a timely manner...e.g 10 seconds...use Promise.race()
      // await this.proctorservice.startProctor(this.user.username, this.user.fullname, this.exam.examid, this.exam.examname);
    }
    //this.adminpaused = data.exampaused;
    if (data?.timeleft !== undefined) {
      this.timeleft = data.timeleft;
      this.examservice.updateExamTime(data.timeleft);
    }
    this.examservice.updateExamPaused(this.adminpaused || this.proctorpaused);

    //debugger;
    //this.proctorpaused = data.proctorpaused;
    //if (this.proctorpaused || this.adminpaused) {
    if (!this.unpauseinterval /*&& this.examination.useproctor*/) {
      this.unpauseinterval = this.pinterval();
    }
    //}
    //if (!data.essayexamstatusmessage) {

    if (
      this.examination.checkfocusloss &&
      !this.onblurfunction /* && admintestlogin != "True"*/
    ) {
      this.onblurfunction = this.onBlur.bind(this); //have to do it this way because if i just set the event listener to this.setTileSize, the "this" becomes window
      this.onfocusfunction = this.onFocus.bind(this);
      window.addEventListener('focus', this.onfocusfunction);
      window.addEventListener('blur', this.onblurfunction);
    }
    this.question = data;

    //}
    //this.essaystatusmessage = data.essayexamstatusmessage;
    this.util.usecalculator = data.examination.usecalculator;
    this.util.calculatormode = data.examination.calculatormode;
  }

  getoptions(scheduleid = this.question.examQuestion.schedulequestionid) {
    this.options = [];
    var ele = document.getElementsByClassName(scheduleid.toString());
    for (var i = 0; i < ele.length; i++) {
      this.getchecked(ele[i]);
    }
  }
  getchecked(element) {
    if (element.checked) {
      this.options.push(element.value.toString());
    }
  }
  // enableradios() {
  //   var buttons = document.getElementsByName(this.question.examQuestionSchedule.schedulequestionid);
  //   buttons.forEach(this.radioenable)
  //   var butons = document.getElementsByClassName(this.question.examQuestionSchedule.schedulequestionid);
  //   for (var i = 0; i < butons.length; i++) {
  //     this.radioenable(butons[i]);
  //   }
  // }
  // radioenable(button) {
  //   button.disabled = false;
  // }
  async qnoclicked(qno: number) {
    //let answereditor = document.getElementsByClassName('ql-editor');
    // let essayanswer: any = ''
    // if (answereditor && answereditor.length > 0) {
    //   essayanswer = answereditor[0].innerHTML;
    // }
    this.setIsLoadingData(true);

    try {
      this.getoptions();
      //this.questionservice.previousquestiondata = null;
      //this.questionservice.nextquestiondata = null;

      const data = this.questionservice.constructAnswerSaveData(
        this.question.examQuestion.schedulequestionid,
        this.question.examination.scheduleid,
        this.options,
        this.examination.examtype == this.questionservice.Essay,
        this.examid,
        this.examination.examtype == this.questionservice.SAQ,
        this.question.examQuestion.saqanswer
      );
      
      const s = data.scheduleid.toString();
      const n = qno;
      try {
        const res = await this.questionservice.getquestionandoptions(
          n,
          this.question.examination.liteMode,
          this.examination.useCaptcha,
          s,
          this.examination.examid
        );
        if (res) {
          //this.questionservice.nextquestiondata = res;
          this.router.navigate(['/exam/question'], {
            queryParams: {
              scheduleid: this.question.examination.scheduleid,
              questionno: qno,
              examid: this.examid,
            },
          });

          this.questionFooter.scrollToQuestion(qno);
        }
      } catch (error) {
        this.cbtservice.showHttpError(error);
        throw error;
      }
    } finally {
      this.setIsLoadingData(false);
    }
  }

  quesanswered(qno: number) {
    //console.log('Hey '+qno);
    this.questionFooter.answerQuestion(qno);
    if (!this.unpauseinterval && this.examination.useproctor)
      this.unpauseinterval = this.pinterval();
  }
  quesunanswered(qno: number) {
    //console.log('Hey '+qno);
    this.questionFooter.unanswerQuestion(qno);
    if (!this.unpauseinterval && this.examination.useproctor)
      this.unpauseinterval = this.pinterval();
  }
  quesflagged(data: any) {
    this.questionFooter.flagquestion(data);
  }

  async setres() {
    var data: any = await this.examservice
      .setfocuslosscounter(this.scheduleid, this.examid)
      .catch((err) => this.cbtservice.showHttpError(err));
    this.losscount = data.flcount;
    clearTimeout(this.countdown);
    if (this.losscount > this.examination.nooffocusloss) {
      var data2: any = await this.examservice
        .performfocuslossaction(this.scheduleid, this.examid)
        .catch((err) => this.cbtservice.showHttpError(err));

      //debugger;

      if (
        data2.msg == 'ReduceTIme' &&
        this.examination.focuspenalty == 'ReduceTIme'
      ) {
        this.toastr.warning(
          `${this.language.youHaveLost} ${this.examination.reducetimelossduration} ${this.language.leavingExamPage}`
        );
      }
      if (/*data2.msg &&*/ data2.msg != 'ReduceTIme') {
        //debugger;
        // window.location.href = `${this.thisurl}exam/submit?scheduleid=${this.scheduleid}`;
        this.router.navigate(['/exam/submit'], {
          queryParams: { scheduleid: this.scheduleid, examid: this.examid },
        });
      }
    } else {
      this.focuslosswarningmessage = this.language.penalizedWarning;
      this.lostfocus = true;
      this.toastr.warning(this.focuslosswarningmessage);
    }
  }

  async pingTimeSpentInterval() {
    const pingTimeSec = 30;
    return setInterval(async () => {
      if (this.destroyed) {
        // if the user starts exams and leaves the page before 30seconds(Logout), we should stop the API call
        clearInterval(this.timeSpentInterval);
        return;
      }
      await this.examservice.pingandupdateTimeSpent(
        this.scheduleid,
        this.examid
      );
    }, 1000 * pingTimeSec);
  }

  async flagQuestionForReview() {
    this.question.examQuestion.flagged = !this.question.examQuestion.flagged;

    this.quesflagged({
      qno: this.question.examQuestion.questionno,
      flagged: this.question.examQuestion.flagged,
    });

    this.toastr.success(
      `${this.language.questionHasBeen}  ${
        this.question.examQuestion.flagged
          ? this.language.flagged
          : this.language.unflagged
      }`
    );
  }

  async timeelapsed(elapsed: boolean) {
    if (elapsed) {
      let answers = [];
      if (this.question.examination.liteMode) {
        answers = this.questionservice.getAllQuestionsAnswer();
      }
      do {
        try {
          this.setIsLoadingData(true);
          const response: any = await this.examservice.getexamstatus(
            this.scheduleid,
            elapsed,
            this.question.examination.examid
          );

          if (response && response.statusmsg && response.statusmsg != '') {
            localStorage.clear();
            this.router.navigate(['/exam/submit'], {
              queryParams: {
                scheduleid: this.scheduleid,
                examid: this.question.examination.examid,
              },
            });
          } else if (response && response.statusmsg == '')
            self.location.reload();
          break;
        } catch (error) {
          console.error(error);
        }  finally {
          this.setIsLoadingData(false);
        }
      } while (true);
    }
  }
  
  toggleCalculator() {
    this.isCalculatorVisible = !this.isCalculatorVisible;
  }
}
