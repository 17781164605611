import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, scheduled } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CbtserviceService } from './cbtservice.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { EncryptDecryptService } from './encrypt-decrypt.service';
import { Util } from '../interceptors/util';
import { ProctoringService } from './proctoring.service';
import { LanguageService } from './language.service';
import { ExamAndQuestions, ExaminationQuestion, ScheduleQuestionAndOptions, ScheduleQuestionProp } from '../models/Exam';

//import { throwError } from 'rxjs';

declare var grecaptcha: any;
//declare var captchaSiteId: any;
export enum EncryptMode {
  e = "encrypt",
  d = "decrypt"
}
@Injectable({
  providedIn: 'root'
})
export class QuestionserviceService {
  readonly Essay = "Essay";
  readonly SAQ = "Short Answer Question";
  captchaSiteId: string;
  //previousquestiondata: any
  //nextquestiondata: any
  //readonly rootURL = environment.CBTAPIURL;
  readonly rootS3DBURL = environment.CBTS3APIURL;
  req_headers: HttpHeaders;
  public markingnotworking: boolean;
  //markingTimeout: any;
  markingQueue: any[] = [];
  private captchaReadyPromise: Promise<void>;
  public proctoringService: ProctoringService;
  useProctor: boolean = false;
  useCaptcha: boolean = false;
  liteMode: boolean = false;
  readonly essaySavingIntervalSeconds = 5;
  readonly captchaFailureRetryLimit = 2;
  captchaFailureCount = 0;

  constructor(private http: HttpClient, private cbtservice: CbtserviceService, private toastr: ToastrService, private router: Router, public encryptdecryptservice: EncryptDecryptService, private utilService: Util, private languageService: LanguageService) {
    this.req_headers = new HttpHeaders({ "content-type": "application/json", "accept": "application/json", "skip_error_handler_interceptor": "true" });//all the services here should skip the interceptor
    setTimeout(() => this.markingTimeoutLoopFunction());
  }
  get language() {
    return this.languageService.language;
  }
  init(useProctor: boolean, useCaptcha: boolean, liteMode: boolean){
    this.useProctor = useProctor;
    this.useCaptcha = useCaptcha;
    this.liteMode = liteMode;
    this.clearSubmissionQueue();
    if(!liteMode){
      localStorage.clear();
    }
    this.captchaFailureCount = 0;
  }

  clearSubmissionQueue(){
    this.markingQueue.splice(0);
  }


  ///webtest_s3DB API 
  //#region s3DB
  async generatequestions(scheduleid: any,examid,attempt): Promise<any> {
    return await this.cbtservice.tryPost(this.rootS3DBURL + `question/generatequestions?scheduleid=${scheduleid}&examid=${examid}&attempt=${attempt}`, {}, { headers: this.req_headers },1);
  }

  async startexam(scheduleid: any, useCaptcha: boolean,examid:any): Promise<any> {
    let startExamData: any = {scheduleid};
    if(useCaptcha){
      await this.loadCaptchaAndWaitForReadiness();
      startExamData.captchaToken = await grecaptcha.enterprise.execute(this.captchaSiteId, {action: 'startexam'});
    }
    startExamData.examid=examid
    //setting max attempts to 1 because we don't want to resend captchas
    return await this.cbtservice.tryPost(this.rootS3DBURL + `question/startexam`, startExamData, { headers: this.req_headers }, 1,10000);
  }

  async markingTimeoutLoopFunction(){
    try{
      while(this.markingQueue.length > 0) {
        await this.cbtservice.runFunctionWithTimeout(async () => {
          const markingEntry = this.markingQueue[0];
          const data = markingEntry.data;

          if(this.useCaptcha){
            await this.loadCaptchaAndWaitForReadiness();
            const captchaToken = await grecaptcha.enterprise.execute(this.captchaSiteId, {action: 'answer'});
            data.captchaToken = captchaToken;
          }

          const promiseResolve = markingEntry.resolve;
          let lastVideoUploadedDetails = {};
          if(this.useProctor) {
            lastVideoUploadedDetails = await this.proctoringService.getLastVideoChunkUploadedDetails();
          }
          //await this.proctoringService.exam
          let encryptedata = JSON.stringify(this.encryptdecryptservice.decryptEncrpyt(JSON.stringify({...data, ...lastVideoUploadedDetails}), EncryptMode.e));
          //setting max attempts to 1 because we don't want to resend captchas
          const ret = await this.cbtservice.tryPost(this.rootS3DBURL + 'question/saveanswer', encryptedata, { headers: this.req_headers }, 1);
          
          if(ret.captchaFailure){
            //don't proceed and try again up to max retries
            this.captchaFailureCount++;
            if(this.captchaFailureCount < this.captchaFailureRetryLimit){
              await this.utilService.sleep(1000);
              //returning here will keep this answer in the queue for the next pass
              return;
            }
            else{
              this.toastr.error(this.language.verificationError);
              //ret.logout = true;
              this.leaveExam();
            }
          }
          else{
            this.captchaFailureCount = 0;
          }

          if(ret.recordingVerificationFailure){
            this.leaveExam();
          }

          if(ret.saved){
            data.saved = true;
            this.saveQuestionOption(data);
            this.markingQueue.splice(0, 1);
          }
          promiseResolve(ret);
          this.markingnotworking = false;
        }, 30 * 1000);//to make sure a submission attempt never takes more than 30 seconds
        //this way, it can fail, and the user will know there is something wrong
      }
    }
    catch (error) {
      console.error(error);
      this.markingnotworking = true;
    }
    finally {
      setTimeout(() => {
        this.markingTimeoutLoopFunction();
      }, 100);
    }
  }

  async getallquestionandoptions(useCaptcha: boolean, scheduleid: any,examid:any): Promise<{encryptedQuestionsHtmlData: string, encryptedQuestionsSelectionData: string}> {
    let captchaToken;
    if(useCaptcha){
      await this.loadCaptchaAndWaitForReadiness();
      captchaToken = await grecaptcha.enterprise.execute(this.captchaSiteId, {action: 'getquestions'});
    }
    //setting max attempts to 1 because we don't want to resend captchas

    const returns = await Promise.all([
      this.cbtservice.tryPost(this.rootS3DBURL + `question/getallquestionsandoptions?scheduleId=${scheduleid}${useCaptcha ? `&captchaToken=${captchaToken}` : ''}&examid=${examid}`, {}, { headers: { "content-type": "application/json", "accept": "*/*", "skip_error_handler_interceptor": "true" }, responseType: 'text' }, 1,20000),
      this.cbtservice.tryPost(this.rootS3DBURL + `question/getallschedulequestionsselections?scheduleId=${scheduleid}&examid=${examid}`, {}, { headers: this.req_headers }, 1,20000)
    ])
    const encryptedQuestionsHtmlData: string = await this.cbtservice.tryGet(returns[0], { headers: { "content-type": "application/json", "accept": "*/*", "skip_error_handler_interceptor": "true" }, responseType: 'text' }, 1, 20000);
    const encryptedQuestionsSelectionData: string = returns[1];
    return {encryptedQuestionsHtmlData, encryptedQuestionsSelectionData};
    //return encryptedDataJson;
  }
  
  //updating schedule questions out of the marking loop could cause answers to be overwritten
  //so i'm stepping this feature down for now
  //the candidate could still flag. but if they move to another machine, they won't see the flag
  // async flagquestion(scheduleid: any, questionno: number, flagged: boolean, candidateno: any,examid:any) {
  //   return await this.cbtservice.tryPost(this.rootS3DBURL + `question/flagquestionforreview`, { scheduleid, candidateno, questionno, flagged,examid }, { headers: this.req_headers });
  // }
//#endregion





//No API calls

async getquestionandoptions(questionno: number, liteMode: boolean, useCaptcha: boolean, scheduleid: any,examid:any): Promise<ScheduleQuestionAndOptions> {
  return await this.getQuestionNoFromStorage(questionno, scheduleid, liteMode, useCaptcha,examid);
}

  loadCaptchaAndWaitForReadiness(){
    //debugger;
    if(!this.captchaReadyPromise){
      this.captchaReadyPromise = new Promise<void>(async (resolve, reject) => {

        this.captchaSiteId = '6LdJ1DIqAAAAACkgKO0BS7TQVU8Nt9xbex4nhtGc';
        const captchaScriptTag = document.createElement('script');
        captchaScriptTag.setAttribute('src', `https://www.google.com/recaptcha/enterprise.js?render=${this.captchaSiteId}`);
        document.getElementsByTagName('head')[0].appendChild(captchaScriptTag);
        while(typeof grecaptcha === 'undefined'){
          await this.utilService.sleep(200);
        }
        grecaptcha.enterprise.ready(async () => {
          //got text from here: https://developers.google.com/recaptcha/docs/faq
          //document.getElementById('recaptcha-footer').innerHTML = '<span>This site is protected by reCAPTCHA, and Google <a target="_blank" href="https://policies.google.com/privacy">Privacy Policy</a> and <a target="_blank" href="https://policies.google.com/terms">Terms of Service</a> apply</span>';
          //const token = await grecaptcha.enterprise.execute('6LdJ1DIqAAAAACkgKO0BS7TQVU8Nt9xbex4nhtGc', {action: 'LOGIN'});
          resolve();
        });
      });
    }
    return this.captchaReadyPromise;
  }

  async uploadEncryptedEssayAnswer(url: string, encryptedAnswer: string) {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');
    await this.http.put(url, encryptedAnswer, { headers: headers }).toPromise();
  }

  markanswer(data: any) {
    if (this.hasQuestionDataInStorage()) {
      data.saved = false;
      this.saveQuestionOption(data);
    }
    if (!this.liteMode) {
      this.markanswerasync(data);
    }
  }

  async getAnswerSubmissionPromise(data) {
    const promise = new Promise<any>((resolve, reject) => {
      this.markingQueue.push({ data, resolve });
    });
    return promise;
  }

  async waitForAllQuestionsToBeSubmitted() {
    while(this.markingQueue.length > 0){
      await this.utilService.sleep(500);
    }
  }

  leaveExam() {
    if (this.useProctor) {
      this.proctoringService.stopProctor('captcha failure');
    }
    this.clearSubmissionQueue();
    this.captchaFailureCount = 0;
    if(!environment.production){
      debugger;
      console.trace('going to exams page');
    }
    this.router.navigate(['/exam/userexams']);
  }

  async markanswerasync(data) {
    try {
      var saved = await this.getAnswerSubmissionPromise(data);

      if (saved && saved.logout == true) {
        this.toastr.error(saved.msg);
        this.clearSubmissionQueue();
        //this.endAnswerSubmission();
        this.router.navigate(["/logout"], {queryParams: { stayinseb: 1 }});
      }
    }
    catch (err) {
      console.log(err);
      alert(this.language.unexpectedCondition);
    }
  }

  // async getEssayAnswerUrl(schedulequestionid: number, scheduleid: number, examid: number){
  //   let url: string = await this.cbtservice.tryGet(`${this.rootS3DBURL}question/getessayanswerurl?scheduleid=${scheduleid}&examid=${examid}&scheduleQuestionId=${schedulequestionid}`, { headers: this.req_headers });
    
  //   url = this.encryptdecryptservice.decryptEncrpyt(url, EncryptMode.d);
  //   return url;
  // }

  async getEssayAnswerUploadUrl(data: { schedulequestionid: number; scheduleid: number; options: any[]; essay: boolean; examid: number; }){
    let url = await this.cbtservice.tryGet(`${this.rootS3DBURL}question/getessayansweruploadurl?scheduleid=${data.scheduleid}&examid=${data.examid}&scheduleQuestionId=${data.schedulequestionid}`, { headers: this.req_headers });
    
    url = this.encryptdecryptservice.decryptEncrpyt(url, EncryptMode.d);
    return url;
  }

  saveQuestionsToStorage(encryptedQuestionsHtmlData: string, encryptedQuestionsSelectionData: string): void {
    if(encryptedQuestionsHtmlData){
      localStorage.setItem(environment.examQuestionsStore, encryptedQuestionsHtmlData);
    }
    if(encryptedQuestionsSelectionData){
      localStorage.setItem(environment.examSelectionsStore, encryptedQuestionsSelectionData);
    }
  }

  async getAllQuestionDataFromStorage(scheduleid: any, litemode: boolean, useCaptcha: boolean, examid :any): Promise<ExamAndQuestions> {
    let encryptedQuestionsHtmlData = localStorage.getItem(environment.examQuestionsStore);
    let encryptedQuestionsSelectionData = localStorage.getItem(environment.examSelectionsStore);
    if ((!encryptedQuestionsHtmlData || !encryptedQuestionsSelectionData) && !litemode) {
      const questionDetailsAndSelections = await this.getallquestionandoptions(useCaptcha, scheduleid,examid);
      this.saveQuestionsToStorage(questionDetailsAndSelections.encryptedQuestionsHtmlData, questionDetailsAndSelections.encryptedQuestionsSelectionData);
      encryptedQuestionsHtmlData = questionDetailsAndSelections.encryptedQuestionsHtmlData;
      encryptedQuestionsSelectionData = questionDetailsAndSelections.encryptedQuestionsSelectionData;
    }

    //const {encryptedQuestionsHtmlData, encryptedQuestionsSelectionData} = JSON.parse(encryptedstoredData);

    const questionsSelectionData = this.encryptdecryptservice.decryptEncrpyt(encryptedQuestionsSelectionData, EncryptMode.d);
    

    let examAndQuestions: ExamAndQuestions = questionsSelectionData ? JSON.parse(questionsSelectionData) : null;


    const getEssayAnswerPromises: Promise<void>[] = [];

    //debugger;
    examAndQuestions.questions.forEach((question, index) => {
      //whenever we are looking at a question, we want to easily be able to reference the examination
      //the downside of this is that we are saving it to storage, thereby using up more storage
      //but it's not too bad
      question.examination = examAndQuestions.examination;
      question.focuslosscount = examAndQuestions.focuslosscount;
      question.questionCount = examAndQuestions.questions.length;
      question.exampaused = examAndQuestions.exampaused;
      
      const essayAnswerInStorage = this.getEssayAnswerFromStorage(question.examQuestion.schedulequestionid);
      if(examAndQuestions.examination.examtype == this.Essay && (essayAnswerInStorage === null || essayAnswerInStorage === undefined)){
      //before we proceed, load all essay answers into local storage
        getEssayAnswerPromises.push(this.loadEssayAnswer(question.examQuestion.schedulequestionid, scheduleid, examAndQuestions.examination.examid));
      }
    });


    //wait until all essay answers have been loaded into storage
    await Promise.all(getEssayAnswerPromises);

    return examAndQuestions;
  }

  async loadEssayAnswer(schedulequestionid: number, scheduleid: number, examid: number){
    var url = await this.cbtservice.tryGet(this.rootS3DBURL + `question/getessayanswer?scheduleId=${scheduleid}&examId=${examid}&scheduleQuestionId=${schedulequestionid}`, { headers: { "content-type": "application/json", "accept": "*/*", "skip_error_handler_interceptor": "true" }, responseType: 'text' }, 1, 20000);

    let answerHtml = '';
    if(url !== ''){//the url is empty if there is no answer 
      var encryptedEssayAnswerHtml = await this.cbtservice.tryGet(url, { headers: { "content-type": "application/json", "accept": "*/*", "skip_error_handler_interceptor": "true" }, responseType: 'text' }, 1, 20000);
      
      if(encryptedEssayAnswerHtml.trim() != ''){
        answerHtml = this.encryptdecryptservice.decryptEncrpyt(encryptedEssayAnswerHtml, EncryptMode.d);
      }
    }
    this.saveEssayAnswerToStorage(schedulequestionid, answerHtml);
  }

  async getQuestionNoFromStorage(questionNo: number = 1, scheduleid: any, litemode: boolean, useCaptcha: boolean,examid:any) {
    const examAndQuestions = await this.getAllQuestionDataFromStorage(scheduleid, litemode, useCaptcha,examid);
    if (examAndQuestions) {
      const selectedQuestion = examAndQuestions.questions.find(item => item.examQuestion.questionno == questionNo);
      let answerdata = this.getAnsweredquestion(selectedQuestion.examQuestion.schedulequestionid)
      let options = answerdata?.options ?? [];
      //debugger;

      //selectedQuestion.essayAnswer = !!answerdata ? answerdata.essayanswer : selectedQuestion.essayAnswer;
      // Find and update the specified option to selected
      const updatedOptions = selectedQuestion.examQuestionOptions.map(option => {
        if (options.includes(option.optionid.toString())) {
          option.selected = true;
        } else {
          option.selected = answerdata ? false : option.selected;
        }
        return option;
      });

      //load updated saq answer if found
      selectedQuestion.examQuestion.saqanswer = answerdata ? answerdata.saqanswer : selectedQuestion.examQuestion.saqanswer;

      //debugger;
      //if(litemode){
      selectedQuestion.timeleft = examAndQuestions.timeleft;
      // }
      // else{
      //   selectedQuestion.timeleft = questionlist.timeleft;
      // }
      selectedQuestion.examination = examAndQuestions.examination;
      selectedQuestion.focuslosscount = examAndQuestions.focuslosscount;
      selectedQuestion.questionCount = examAndQuestions.questions.length;
      selectedQuestion.exampaused = examAndQuestions.exampaused;
      //selectedQuestion.isnotlatestlogin = questionlist.isnotlatestlogin;
      selectedQuestion.examQuestionOptions = updatedOptions;

      //the actual html of the questions is in a different storage
      let encryptedQuestionsHtmlData = localStorage.getItem(environment.examQuestionsStore);
      const questionsHtmlDataJson = this.encryptdecryptservice.decryptEncrpyt(encryptedQuestionsHtmlData, EncryptMode.d);
      const questionsHtmlData: ExaminationQuestion[] = JSON.parse(questionsHtmlDataJson);

      const index = questionNo - 1;
      selectedQuestion.examQuestion.questionhtml = questionsHtmlData[index].question.questionhtml;
      selectedQuestion.examQuestion.hasMultipleAnswers = questionsHtmlData[index].question.hasMultipleAnswers;
      selectedQuestion.examQuestion.maxwordcount = questionsHtmlData[index].question.maxwordcount;
      selectedQuestion.examQuestion.category = questionsHtmlData[index].question.category;
      selectedQuestion.examQuestion.instructions = questionsHtmlData[index].question.instructions;
      
      selectedQuestion.examQuestionOptions.forEach((option, innerIndex) => {
        option.optionhtml = questionsHtmlData[index].options[innerIndex].optionhtml;
      });

      //console.log('Retrieved specific array:', selectedQuestion);

      return selectedQuestion;
    }
    return null;
  }

  async updateQuestionTimeleft( scheduleid: any, litemode: boolean, useCaptcha: boolean,timeleft:any,examid:any){
    const examAndQuestions = await this.getAllQuestionDataFromStorage(scheduleid, litemode, useCaptcha,examid);
    if(examAndQuestions){
      examAndQuestions.timeleft = timeleft;
      var encryptedQuestionlist = this.encryptdecryptservice.decryptEncrpyt(JSON.stringify(examAndQuestions),EncryptMode.e)
      this.saveQuestionsToStorage(null, encryptedQuestionlist);
    }
  }

  clearQuestionStorage(): void {
    localStorage.clear();
  }

  hasQuestionDataInStorage(): boolean {
    const encryptedstoredData = localStorage.getItem(environment.examQuestionsStore);
    // let storedData = this.encryptdecryptservice.decryptEncrpyt(encryptedstoredData,EncryptMode.d);
    // let storedData = this.encryptdecryptservice.decryptUsingAES256(encryptedstoredData);
    // storedData = storedData ? storedData : null;
    return !!encryptedstoredData;
  }

  saveQuestionOption(data: any) {
    localStorage.setItem(`answerquestion_${data.schedulequestionid}`, JSON.stringify(data));
    // this.updateQuestionOption(data.schedulequestionid, data.options, data.essayanswer)
  }

  saveTimeForLiteMode() {
    localStorage.setItem(`timeAdded`, new Date().toUTCString());
  }

  getTimeleftForLiteMode(timeleft) {
    const timeAdded = new Date(localStorage.getItem("timeAdded")).getTime() / 1000;
    //debugger;
    const currentTime = new Date(new Date().toUTCString()).getTime() / 1000;
    timeleft = timeleft - (currentTime - timeAdded);
    return timeleft;
  }

  getAllQuestionsAnswer(): any[] {
    const storedData: any[] = [];

    // Iterate over all keys in local storage
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);

      // Check if the key matches the pattern "answerquestion_"
      if (key && key.startsWith('answerquestion_')) {
        // Retrieve the data for the matching key
        const data = localStorage.getItem(key);

        if (data) {
          // Parse the data and add it to the result array
          storedData.push(JSON.parse(data));
        }
      }
    }
    return storedData;
  }

  getAnsweredquestion(schedulequestionid: any) {
    const storedData = localStorage.getItem(`answerquestion_${schedulequestionid}`);
    return JSON.parse(storedData);
  }

  saveEssayAnswerToStorage(scheduleQuestionId: number, essayAnswer: string){
    localStorage.setItem(`essay_answerquestion_${scheduleQuestionId}`, essayAnswer);
  }

  getEssayAnswerFromStorage(scheduleQuestionId: number){
    const ret = localStorage.getItem(`essay_answerquestion_${scheduleQuestionId}`);
    return ret;
  }

  async saveEssayAnswer(answerHtml: any, data: {
    schedulequestionid: number; scheduleid: number; options: any[];
    saqanswer: string;
    saq: boolean;
    //essayanswer: any;
    essay: boolean; examid: number;
  }) {
    this.saveEssayAnswerToStorage(data.schedulequestionid, answerHtml);
    
    try{
      const answerUploadUrl = await this.getEssayAnswerUploadUrl(data);
      const encryptedAnswer: string = this.encryptdecryptservice.decryptEncrpyt(answerHtml, EncryptMode.e);

      await this.uploadEncryptedEssayAnswer(answerUploadUrl, encryptedAnswer);
      this.markingnotworking = false;
    }
    catch(error){
      this.markingnotworking = true;
      throw error;
    }
    
    this.markanswer(data);
  }

  public constructAnswerSaveData(schedulequestionid: number, scheduleid: number, options: any[], essay: boolean, examid: number, saq: boolean=false, saqanswer: string=''): {
    schedulequestionid: number;
    scheduleid: number;
    options: any[];
    saqanswer: string;
    //essayanswer: any;
    essay: boolean;
    saq: boolean;
    examid: number;
    } {
    return {
      schedulequestionid: schedulequestionid,
      scheduleid: scheduleid,
      options: options,
      //"essayanswer": questionHtml,
      essay: essay,
      examid: examid,
      saq: saq,
      saqanswer: saqanswer     
    };
  }

  essayAnswerIsNotBlank(htmlString: string) {
    if (!htmlString || !htmlString.trim()) {
      return ''; // Return an empty string if the input is empty or whitespace
    }
  
    // Create a temporary DOM element
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlString;
  
    // Remove script and style elements to exclude their content
    tempDiv.querySelectorAll('script, style').forEach((element) => element.remove());
  
    // Get the text content from the remaining HTML
    return !!tempDiv.textContent.trim();
  }

  //i don't need this method anymore because we now save once you leave once the essay answering interface is destroyed anyway
  // async submitAllEssayQuestions(){
    
  //   await this.utilService.sleep(this.essaySavingIntervalSeconds * 2);
  //   for (let i = 0; i < localStorage.length; i++) {
  //     const key = localStorage.key(i);

  //     // Check if the key matches the pattern "answerquestion_"
  //     if (key && key.startsWith('essay_answerquestion_')) {
  //       // Retrieve the data for the matching key
  //       const answerHtml = localStorage.getItem(key);

  //       await this.saveEssayAnswer(answerHtml, )
  //     }
  //   }
  // }
}
