export const environment = {
  production: true,
  CBTAPIURL: "https://cbtapi.webtest.ng/",
  CBTS3APIURL: 'https://cbts3api.webtest.ng/',
  //thisURL: "https://cbt.webtest.ng/",
  s3passportbucketurl: 'https://cbtpassportstorage.s3.us-west-2.amazonaws.com/',
  sebKeyStore: 'seb',
  examQuestionsStore: 'examquestions',
  examSelectionsStore: 'examquestionselections',
  userTokenStore : "cbttkn",
  languageStore: 'lang',
  timezoneapikey:"0d933aa277de4030ae2203539fdf47c4",
  seamlessScalingError: 'Seamless Scaling. Please retry'.toUpperCase(),
  maxRequestRetries: 3,
  vcirclecookiekeyname: 'vcircle-public-user',
  mediasoupScheme: 'https',
  meetingServiceRootUrl: 'https://api.vcircle.ng/',
  quitseburl: "https://webtest.ng/",
  aiServerUrl: 'https://aiproctor.webtest.ng/',
  EncryptKey:"TS32digitencryptcharacterkey1234",
  isSeb: navigator.userAgent.includes('SEB/')
}
