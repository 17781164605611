import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class Util {
  usecalculator:boolean=false;
  calculatormode:any;
  constructor(private datePipe: DatePipe  ) { 

  }

  getSubdomain(){
    const location = window.location.hostname;
      const splitlocation = location.split('.');
      let subdomain;
      if(splitlocation.length >= 2){
        subdomain = splitlocation[0];
        if(subdomain == 'www' || subdomain == 'cbt'){
          subdomain = '';
        }
      }
      else{
          subdomain = '';
      }
      return subdomain;
  }

  formatDate(date: Date | string) {
    if(date){
      const value = new Date(date);
      // Convert from UTC to local time
      const localDate = new Date(value.getTime() - value.getTimezoneOffset() * 60000);
      return this.datePipe.transform(localDate, 'medium');
    }
  }

  getDateString(date: Date){
    if(date){
      //, 'yyyy-mm-dd HH:mm'
    }
  }

  sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }


  
}
