import { Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { ScheduleOptionsProp } from '../models/Exam';

@Component({
  selector: 'app-option-check',
  templateUrl: './option-check.component.html',
  styleUrls: ['./option-check.component.scss']
})
export class OptionCheckComponent implements OnInit {
  @Input() option: ScheduleOptionsProp;
  @Output() checkchanged: EventEmitter<ScheduleOptionsProp> = new EventEmitter();
@Input() schedulequestionid:number;
  ngOnInit(): void {
  }
  checkChanged(e, option: ScheduleOptionsProp) {
    //this.disableradios()
    if (e.target.checked) {
      option.selected = true;
    }
    else {
      option.selected = false;
    }

    this.checkchanged.emit(option);
  }
  // disableradios() {
  //   var buttons = document.getElementsByClassName(this.schedulequestionid.toString());
  //   for (var i = 0; i < buttons.length; i++) {
  //     this.radiodiabbletoggle(buttons[i]);
  //   }
  // }
  // radiodiabbletoggle(button) {
  //   button.disabled = true;
  // }
}
