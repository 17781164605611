<div style="display:block; max-width: 500px;">
	<input type="text" [ngModel]="formattedTokens" class="form-control-sm form-control text-right" readonly>

	<input type="text" [ngModel]="input" class="form-control form-control-lg text-right" readonly>

	<div class="row mt-1">
		<div class="col-3">
			<button class="btn btn-secondary btn-block" (click)="reset()">AC</button>
		</div>
		<div class="col-3">
			<button class="btn btn-primary btn-block" (click)="execOperator('(')">(</button>
		</div>
		<div class="col-3">
			<button class="btn btn-primary btn-block" (click)="execOperator(')')">)</button>
		</div>
		<div class="col-3">
			<button class="btn btn-primary btn-block" (click)="execOperator('/')">/</button>
		</div>
	</div>
	<div class="row mt-1">
		<div class="col-3">
			<button class="btn btn-secondary btn-block" (click)="insertChar('1')">1</button>
		</div>
		<div class="col-3">
			<button class="btn btn-secondary btn-block" (click)="insertChar('2')">2</button>
		</div>
		<div class="col-3">
			<button class="btn btn-secondary btn-block" (click)="insertChar('3')">3</button>
		</div>
		<div class="col-3">
			<button class="btn btn-primary btn-block" (click)="execOperator('*')">x</button>
		</div>
	</div>
	<div class="row mt-1">
		<div class="col-3">
			<button class="btn btn-secondary btn-block" (click)="insertChar('4')">4</button>
		</div>
		<div class="col-3">
			<button class="btn btn-secondary btn-block" (click)="insertChar('5')">5</button>
		</div>
		<div class="col-3">
			<button class="btn btn-secondary btn-block" (click)="insertChar('6')">6</button>
		</div>
		<div class="col-3">
			<button class="btn btn-primary btn-block" (click)="execOperator('+')">+</button>
		</div>
	</div>
	<div class="row mt-1">
		<div class="col-3">
			<button class="btn btn-secondary btn-block" (click)="insertChar('7')">7</button>
		</div>
		<div class="col-3">
			<button class="btn btn-secondary btn-block" (click)="insertChar('8')">8</button>
		</div>
		<div class="col-3">
			<button class="btn btn-secondary btn-block" (click)="insertChar('9')">9</button>
		</div>
		<div class="col-3">
			<button class="btn btn-primary btn-block" (click)="execOperator('-')">-</button>
		</div>
	</div>
	<div class="row mt-1">
		<div class="col-3">
			<button class="btn btn-danger btn-block" (click)="backspace()">
				<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-delete"><path d="M21 4H8l-7 8 7 8h13a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z"></path><line x1="18" y1="9" x2="12" y2="15"></line><line x1="12" y1="9" x2="18" y2="15"></line></svg>			</button>
		</div>
		<div class="col-3">
			<button class="btn btn-secondary btn-block" (click)="insertChar('0')">0</button>
		</div>
		<div class="col-3">
			<button class="btn btn-secondary btn-block" (click)="insertChar('.')">.</button>
		</div>
		<div class="col-3">
			<button autofocus class="btn btn-primary btn-block" (click)="evaluate()">=</button>
		</div>
	</div>

</div>