import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../services/language.service';

@Component({
  selector: 'app-p404',
  templateUrl: './p404.component.html',
  styleUrls: ['./p404.component.css']
})
export class P404Component implements OnInit {
  searchText:any;
  constructor(private languageService: LanguageService) { }
  get language(){
    return this.languageService.language;
  }

  ngOnInit(): void {
  }
  goToUrl(): void {
    window.open('https://google.com/search?q='+this.searchText,'_blank');
}
}
