import { isOperator } from './model';
import {Big } from 'big.js';

/**
 * Evaluates a list of tokens in Reverse Polish Notation (RPN) and returns the result as a `Big` number.
 * 
 * @param tokens - An array of strings representing the RPN expression. Each token can be an operand or an operator.
 * 
 * @returns The result of the RPN expression as a `Big` number rounded to 10 decimal places.
 * 
 * @throws Will throw an error if there are not enough operands for an operator or if there is a division by zero.
 * 
 * @example
 * ```typescript
 * const result = rpn(['3', '4', '+', '2', '*', '7', '/']);
 * console.log(result.toString()); // Output: "2.0000000000"
 * ```
 */

export function rpn(tokens: string[]): Big {
  const stack: Big[] = [];

  tokens.forEach(token => {
    if (!isOperator(token)) {
      stack.push(Big(token));
    } else if (stack.length < 2) {
      throw new Error('Syntax error.');
    } else {
      const val2 = stack.pop();
      const val1 = stack.pop();

      switch (token) {
        case '+':
          stack.push(val1.add(val2));
          break;
        case '*':
          stack.push(val1.mul(val2));
          break;
        case '/':
          if (val2.eq(0)) {
            throw new Error('Division by zero.');
          }

          stack.push(val1.div(val2));
          break;
        case '-':
          stack.push(val1.minus(val2));
          break;
      }
    }
  });

  return stack.pop().round(10);
}