import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { } from '../models/model'
import { CbtserviceService } from '../services/cbtservice.service';
import { ExamService } from '../services/exam.service';
import { QuestionserviceService } from '../services/questionservice.service';
import { LanguageService } from '../services/language.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-questionpagefooter',
  templateUrl: './questionpagefooter.component.html',
  styleUrls: ['./questionpagefooter.component.css']
})
export class QuestionpagefooterComponent implements OnInit, AfterViewInit {
  page = 1;
  @Input() noofquestions: number = 0;
  //numbers: any;
  @Input() questions: any;
  @Input() examid: number
  @Input() scheduleid: number
  @Input() qno: number;
  @Input() schedulequestionid: any;
  @Input() showSubmitButtonOnEveryQuestion: boolean
  @Input() liteMode: boolean;
  @Input() url: any

  @Output() qnoclicked: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild('pagination') pagination!: ElementRef;
  constructor(public questionservice: QuestionserviceService, public toastr: ToastrService,
    public examservice: ExamService, public cbtservice: CbtserviceService, private languageService: LanguageService, public router: Router,) {
  
  }

  ngAfterViewInit(): void {
    this.scrollToQuestion(this.qno);
    const ulElement = this.pagination.nativeElement;
    if (ulElement.scrollHeight > ulElement.clientHeight) {
      ulElement.style.height = '52px';
    }
  }

  get language() {
    return this.languageService.language;
  }

  ngChange() {
    debugger;
    this.scrollToQuestion(this.qno);
  }

  async ngOnInit() {}

  noclicked(qno: number) {
    this.qnoclicked.emit(qno);
  }

  answerQuestion(questionNo: number) {
    this.questions.forEach(element => {
      if (element.questionno == questionNo) {
        element.answered = true;
      }
      return element;
    });
  }

  unanswerQuestion(questionNo: number) {
    this.questions.forEach(element => {
      if (element.questionno == questionNo) {
        element.answered = false;
      }
      return element;
    });
  }

  flagquestion(data: any) {
    this.questions.forEach(element => {
      if (element.questionno == data.qno) {
        element.flagged = data.flagged;
      }
      return element;
    });
  }

  scrollToQuestion(questionNo: number) {
    const element = document.getElementById(questionNo.toString());
    if (element) {
       element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
    }
  }

  async finishclicked() {
    if (!this.liteMode) {
      await this.questionservice.waitForAllQuestionsToBeSubmitted();
    }
    this.router.navigate(['/exam/submit'], { queryParams: { scheduleid: this.scheduleid, examid: this.examid, url: this.url } });
    localStorage.removeItem(`${this.scheduleid}_post_finish_data`);
  }
}
