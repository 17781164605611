<div class="toolbar" role="banner">
  <div class="float-left" [ngClass]="{'mobilehide': timeRemaining !== null}">
    <h2 class="logo ml-2">
      <a style="color: white !important; cursor: pointer;" routerLink="/exam/userexams">
        <img src="/assets/images/main-logo.png" width="180" alt="logo" />
      </a>
    </h2>
  </div>
  <div class="spacer h5 d-flex justify-content-between align-items-center w-100">
    <span class="ml-5 mobilehide" style="color: white !important;">
      <span class="welcome">{{language.welcome}},</span>
      <span class="user_name">{{this.user.fullname}}</span>
    </span>
  
    <div class="d-flex mobile-full-width" [ngClass]="{'justify-content-end': timeRemaining === null ,'justify-content-between': timeRemaining !== null }">
      <div *ngIf="timeRemaining !== null">
        <span class="timer-wrap">
          <countdown #countdown [config]="{leftTime: timeRemaining, notify: [300, 600]}" (event)="countDownEventHandler($event)"></countdown>
        </span>
      </div>
     <div>
      <span style="color: white !important; margin-right: 2vw;">
        <a style="color: white !important; cursor: pointer;" routerLink="/logout">
          <i class="fa fa-sign-out"></i> {{language.logout}}
        </a>
      </span>
     </div>
    </div>
  </div>
  
</div>

<!-- Main Content with Sidebar and Content Areas -->
<div class="container-fluid">
  <div class="overlay" [ngClass]="{'show': loading}"></div>
  <div class="spanner" [ngClass]="{'show': loading}">
    <div class="spin-wrap">
      <div class="spin-wrap-content">
        <div class="spinner-container">
          <div class="lds-ripple"></div>
        </div>
      </div>
    </div>
  </div>

  <div class="inner_exam_wrap">
    <div class="row" style="min-height: calc(100dvh - 4.375rem);">
      <!-- Mobile Accordion -->
      <div class="mobile my-2">
        <div class="accordion" id="mobileaccordion">
          <div class="card-header p-2" id="passportsectionmobile" data-toggle="collapse"
            data-target="#passportsectionmobilecollapse" aria-expanded="false"
            aria-controls="#passportsectionmobilecollapse">
            <h5 class="mb-0">
              <span> {{language.passportSection}}</span>
            </h5>
          </div>
          <div id="passportsectionmobilecollapse" class="collapse " aria-labelledby="#passportsectionmobile"
            data-parent="#mobileaccordion">
            <div class="card-body">
              <div class="col-md-2 border border-right usericon">
                <!-- Card body -->
                <div class="mt-2">
                  <div class="row mb-1">
                    <div class="col-md-12">
                      <div class="user_profile_wrap">
                        <h6 *ngIf="togglepassportui" style="text-align: center; font-weight:bold;">
                          {{language.maxFileUpload1mb}}
                        </h6>
                        <img *ngIf="imageUrl" [src]="imageUrl" class="rounded img-center img-fluid"
                          style="width: 160px;">
                        <div *ngIf="canUploadPassport">
                          <h6 *ngIf="showlimittext" style="text-align: center;" class="text-danger">{{limittext}}</h6>
                          <input *ngIf="togglepassportui" accept="image/png, image/jpeg" type="file"
                            (change)="onFileChange($event)" style="margin-top:8px" />
                          <div (click)="showwebcammodal()" class="d-flex justify-content-around align-items-center"
                            style="background-color: #74bbf7; width: 90%; padding-left: 2px; padding-right: 2px; margin: 5px auto; border-radius: 5px; cursor: pointer;">
                            <i class="fa fa-camera"></i>
                            <p style="font-weight:bold;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;">
                              {{language.takePicture}}</p>
                          </div>
                        </div>
                        <div *ngIf="isInIframe()" class="candidate-video-div">
                          <video class="candidate-video" #candidateVideo playsinline autoplay muted></video>
                          <div class="text-info text-center">
                            <ul>
                              <li> {{language.clearFaceInstruction}}</li>
                              <li> {{language.brightLightInstruction}}</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col text-center">
                    <button title="Requests Attention" (click)="raiseHandClicked()"
                      class="btn btn-sm btn-primary p-1 m-2" *ngIf="isProctorAssigned()">
                      {{language.requestAttention}}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Sidebar (Desktop) with Content -->
      <div class="col-md-4 col-lg-3 col-xl-3 desktop left-div border border-right desk">
        <!-- Card body -->
        <div class="d-flex flex-column h-100">
          <div class="d-flex mb-1" style="max-height: 65vh; padding: 10px; overflow-y: auto;">
            <div class="w-100">
              <div class="user_profile_wrap">
                <h6 *ngIf="togglepassportui" style="text-align: center; font-weight:bold;">
                  {{language.maxFileUpload1mb}}
                </h6>
                <img *ngIf="imageUrl" [src]="imageUrl" class="rounded img-center img-fluid" style="width: 160px;">
                <div *ngIf="canUploadPassport">
                  <h6 *ngIf="showlimittext" style="text-align: center;" class="text-danger">{{limittext}}</h6>
                  <input *ngIf="togglepassportui" accept="image/png, image/jpeg" type="file"
                         (change)="onFileChange($event)" style="margin-top:8px" />

                  <div (click)="showwebcammodal()" class="d-flex justify-content-around align-items-center"
                       style="background-color: #74bbf7; width: 90%; padding-left: 2px; padding-right: 2px; margin: 5px auto; border-radius: 5px; cursor: pointer;">
                    <i class="fa fa-camera"></i>
                    <p style="font-weight:bold;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;">
                      {{language.takePicture}}
                    </p>
                  </div>
                </div>

                <!-- Video Instructions -->
                <div *ngIf="isInIframe()" class="candidate-video-div">
                  <video class="candidate-video" #candidateVideo playsinline autoplay muted></video>
                  <div class="text-info text-center">
                    <ul>
                      <li> {{language.clearFaceInstruction}}</li>
                      <li> {{language.brightLightInstruction}}</li>
                    </ul>
                  </div>
                </div>
              </div>

              <!-- Request Attention Button -->
              <div class="text-center">
                <button title="Requests Attention" (click)="raiseHandClicked()" class="btn btn-sm btn-primary p-1 m-2"
                        *ngIf="isProctorAssigned()">
                  {{language.requestAttention}}
                </button>
              </div>
            </div>
          </div>

          <!-- Toggle Calculator Button -->
          <div *ngIf="usecalc" class="mt-auto text-center p-2">
            <button title="Toggle Calculator" 
            (click)="toggleCalculator()"
            class="btn btn-sm btn-primary p-1 m-2 w-full">
            <i class="fa fa-calculator fa-lg mr-2"></i>
              {{language.toggleCalculator}}
            </button>
          </div>
        </div>
      </div>

      <!-- Main Content Area -->
      <div class="col-md-8 col-lg-9 col-xl-9 right-div">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>

<!-- Webcam Modal -->
<div class="modal fade camtake" id="webcammodal" tabindex="1" data-keyboard='true' aria-hidden="true" role="dialog"
  style="height: auto !important; margin: 5vw; ">
  <div class="modal-dialog" style="max-width: 100% !important; height: 100%;">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title "> {{language.takePicture}}</h3>
        <button type="button" class="close h3" data-dismiss="modal" aria-label="Close">&times;</button>
      </div>
      <div class="modal-body">
        <app-webcam-passport style="height: 100% !important;" *ngIf="webcammode" [examno]="this.user.username"
          (imagefile)="imagefilereceived($event)">
        </app-webcam-passport>
      </div>
    </div>
  </div>
</div>
