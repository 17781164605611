<div class="overlay" [ngClass]="{'show': isLoadingData}">
</div>
<div class="spanner" [ngClass]="{'show': isLoadingData}">
  <div class="spin-wrap">
    <div class="spin-wrap-content">
      <div class="spinner-container">
        <div class="lds-ripple">
          <p *ngIf="isLoadingData && markingerrormessage && markingnotworking">{{markingerrormessage}}</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="d-flex flex-column h-100 w-100 border" role="main">
  <div *ngIf="adminpaused || proctorpaused" class="d-flex flex-column justify-content-center align-items-center p-4 h-100">
    <h4>{{language.pleaseHold}}</h4>
    <h3 class="mt-3">{{language.examHasBeenPaused}}.</h3>
    <h4 *ngIf="pausereason" class="mt-3">{{language.reason}}: {{pausereason | uppercase}}</h4>
    <h5 class="mt-4">{{language.willNotLoseTime}}</h5>
  </div>

  <!-- Question Header -->
  <div class="question-header border-bottom" *ngIf="!adminpaused && !proctorpaused">
    <app-questionpageheader *ngIf="scheduleid && question.questionCount"  
      [position]="'top'" 
      [qno]="question.examQuestion.questionno"
      [qnolabel]="question.examQuestion.questionnolabel"
      [qCount]="question.questionCount"
      [qcatergoy]="question.examQuestion.category"
      [islast]="question.questionCount == question.examQuestion.questionno" 
      [scheduleid]="scheduleid" 
      [url]="this.router.url"
      (nextclicked)="qnoclicked($event)" 
      (previousclicked)="qnoclicked($event)"
      [essayexam]="examination.examtype==this.questionservice.Essay || examination.examtype==this.questionservice.SAQ"
      [adminpaused]="adminpaused" 
      [proctorpaused]="proctorpaused"
      [status]="status"
      [useProctor]="question.examination.useproctor" 
      [liteMode]="question.examination.liteMode" 
      [examid]="examid"
      [showSubmitButtonOnEveryQuestion]="examination.showSubmitButtonOnEveryQuestion">
    </app-questionpageheader>
  </div>

  <!-- Question Content -->
  <div id="question-container" class="d-flex question-container border-bottom" *ngIf="!adminpaused && !proctorpaused">
    <app-question class="w-100" *ngIf="question.questionCount" [isloadingdata]="isLoadingData" 
      (quesunanswered)="quesunanswered($event)" (quesanswered)="quesanswered($event)" [question]="question"
      [essayexam]="examination.examtype==this.questionservice.Essay || examination.examtype==this.questionservice.SAQ"
      [issaq]="examination.examtype==this.questionservice.SAQ" [url]="this.router.url" [adminpaused]="adminpaused"
      [proctorpaused]="proctorpaused" (quesflagged)="quesflagged($event)" [status]="status"
      [examid]="examid" [scheduleid]="scheduleid" [loadingSubject]="this.loadingSubject"
      [networkFailureSubject]="this.networkFailureSubject">
    </app-question>
    <!-- bookmark button -->
    <button *ngIf="question.questionCount"
     class="badge-flagged bookmark-float" title="Flag a question to revisit later" [ngClass]="{'badge-unanswered':! question.examQuestion.flagged ,'badge-flagged': question.examQuestion.flagged }" (click)="flagQuestionForReview()">
      <i class="fa fa-flag fa-lg"></i>
    </button>

    <!-- calculator button -->
    <button *ngIf="usecalc"
     class="calculator-float" title="Show Calculator" (click)="toggleCalculator()">
      <i class="fa fa-calculator fa-lg"></i>
    </button>
  </div>

  <!-- Question Footer -->
  <div class="question-footer mt-auto border-top py-1 px-2" *ngIf="!adminpaused && !proctorpaused">
    <app-questionpagefooter #quesfooter *ngIf="question.questionCount"
      [qno]="question.examQuestion.questionno"
      [noofquestions]="question.questionCount" 
      [scheduleid]="examination.scheduleid" 
      [examid]="examination.examid"
      [schedulequestionid]="question.examQuestion.schedulequestionid" 
      [questions]="dummyQuestionsForFooter"
      (qnoclicked)="qnoclicked($event)"
      [liteMode]="question.examination.liteMode"
      [url]="this.router.url"
      [showSubmitButtonOnEveryQuestion]="examination.showSubmitButtonOnEveryQuestion">
    </app-questionpagefooter>
  </div>
</div>

<!-- Calculator Modal -->
<div *ngIf="isCalculatorVisible" class="calculator-container shadow-sm border rounded bg-white" 
     [style.transform]="'translate(-50%, -50%)'"
     cdkDrag>
  <div class="calculator-header d-flex justify-content-between align-items-center p-2 bg-light"  matTooltip="Drag to move">
    <h5 class="mb-0">Calculator</h5>
    <button type="button" class="close" aria-label="Close" (click)="toggleCalculator()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="p-0">
    <app-scientific-calc-v3 *ngIf="calcmode === 'Scientific'"></app-scientific-calc-v3>
    <app-calculator *ngIf="calcmode === 'Simple'"></app-calculator>
  </div>
</div>



