import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UtilService } from '../meeting/services/util.service';
import { CurrentUser } from '../models/CurrentUser';
import { ExamInfo } from '../models/Exam';
import { CbtserviceService } from '../services/cbtservice.service';
import { ExamService } from '../services/exam.service';
import { ProctoringService } from '../services/proctoring.service';
import { QuestionserviceService } from '../services/questionservice.service';
import { UserauthenticationserviceService } from '../services/userauthenticationservice.service';
import { LanguageService } from '../services/language.service';

@Component({
  selector: 'app-userexaminations',
  templateUrl: './userexaminations.component.html',
  styleUrls: ['./userexaminations.component.css'],
})
export class UserexaminationsComponent implements OnInit, AfterViewInit, OnDestroy {
  exams: ExamInfo[];
  user: CurrentUser = new CurrentUser();
  isloading: boolean;
  interval: any;
  hasloaded: boolean = false;
  isDestroyInterval: boolean;
  constructor(
    public activatedroute: ActivatedRoute,
    public router: Router,
    public examservice: ExamService,
    public toastr: ToastrService,
    private userauth: UserauthenticationserviceService,
    private proctorservice: ProctoringService,
    private questionservice:QuestionserviceService,
      private cbtservice: CbtserviceService, private languageService: LanguageService,private util: UtilService) { }
    get language() {
      return this.languageService.language;
    }

  async ngOnInit() {
    //debugger;
    const langCode = this.languageService.getLanguageCode();

    localStorage.clear();
    //this.questionservice.previousquestiondata = null;
    //this.questionservice.nextquestiondata = null;
    this.isloading = true;
    try{
      this.user = await this.userauth.getCurrentUser();
    }
    catch(err){
      this.isloading = false;
      throw err;
    }
    this.languageService.setLanguageCodeToCookie(langCode);

    let candidateno = this.user.username;
    //await this.proctorservice.stopProctor(); //just in case
    await this.getuserexams(candidateno);
    this.isloading = false;

    this.hasloaded = true;
    this.interval = setInterval(() => { this.getuserexams(candidateno); }, 60000);

  }
  
  async getuserexams(candidateno: any) {
    this.isloading = true;
    this.exams = await this.examservice
      .getuserexams(candidateno)
      .catch((err) => this.cbtservice.showHttpError(err))
      .finally(() => (this.isloading = false));

    this.exams.forEach((exam: ExamInfo) => {
      console.log(JSON.stringify(exam))
      if (exam.useCaptcha && exam.canstart) {
        //if there is any exam using captcha, just load captcha early
        this.questionservice.loadCaptchaAndWaitForReadiness();
      }
    })
  }

  async ngAfterViewInit() {
    //debugger;

    //no need to await the below
    await this.proctorservice.stopProctor('User exams after view init'); //just in case
    if (!this.activatedroute.snapshot.queryParams.skipMediaTest) {
      await this.proctorservice.startProcteeTestVideo();
    }
  }

  ngOnDestroy() {
    if (this.interval) {
      //debugger;
      clearInterval(this.interval);
      this.interval = undefined;
    }
  }

  destroyInterval(isDestroyInterval: boolean) {
    //debugger;
    clearInterval(this.interval);
    this.interval = undefined;
  }
  resumeInterval(e) {
    if (!this.interval) {
      this.interval = setInterval(() => { this.getuserexams(this.user.username); }, 60000);
    }
  }
}
