import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

declare function decrypt(input): any
declare function encrypt(input): any
enum EncryptMode {
    e = "encrypt",
    d = "decrypt"
}

@Injectable({
    providedIn: 'root'
})

export class EncryptDecryptService {
    // private key = CryptoJS.enc.Utf8.parse(environment.EncryptKey);
    // private iv = CryptoJS.enc.Utf8.parse(environment.EncryptIV);
    // constructor(private toastr: ToastrService) { }
    // // Methods for the encrypt and decrypt Using AES
    // encryptUsingAES256(text): any {
    //    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), this.key, {
    //        keySize: 128 / 8,
    //        iv: this.iv,
    //        mode: CryptoJS.mode.CBC,
    //        padding: CryptoJS.pad.Pkcs7
    //    });
    //    return encrypted.toString();
    // }


    // decryptUsingAES256(decString) {
    //    var decrypted = CryptoJS.AES.decrypt(decString, this.key, {
    //        keySize: 128 / 8,
    //        iv: this.iv,
    //        mode: CryptoJS.mode.CBC,
    //        padding: CryptoJS.pad.Pkcs7
    //    });
    //    return decrypted.toString(CryptoJS.enc.Utf8);
    // }

//method for encrypt and decrypt wasm
    public decryptEncrpyt(input, mode: EncryptMode) {
        if (mode == EncryptMode.e) {
            return encrypt(input);
        }
        else if (mode == EncryptMode.d) {
            return decrypt(input);
        }
        else {
            const error = "encrypt/decrypt error. Invalid EncryptMode"
            //this.toastr.error(error);
            throw error;
        }
    }

}
