<div *ngIf="!hasloaded" class="overlay" [ngClass]="{'show': isloading}"></div>
<div *ngIf="!hasloaded" class="spanner" [ngClass]="{'show': isloading}">

  <div class="spin-wrap">
    <div class="spin-wrap-content">
      <div class="spinner-container">
        <div class="lds-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- </div> -->
<div class="card h-100" id="exam_start_card">
  <!-- Card header -->
  <div class="card-header">
    <app-language-layout></app-language-layout>
    <!-- Title -->
    <h5 class="h3 mb-0">{{language.examinationList}}</h5>
  </div>
  <!-- Card body -->
  <div class="card-body">
    <!-- List group -->
    <div class="col-md-12">
      <!-- Card header -->
      <div class="card-title">
        <!-- remove border-0 -->
        <h3 class="mb-0">{{language.scheduledExaminations}}</h3>
      </div>

      <!-- Light table -->
      <div *ngIf="exams && exams.length>0" class="card-body">
        <div class="row">
          <div class="col-sm-12 exam-type-sec">
            <div class="my-2">
              <div class="classmaterial" *ngFor="let exam of exams">
                <app-userexamination [exam]="exam" (destroyInterval)="destroyInterval($event)" (resumeInterval)="resumeInterval($event)"></app-userexamination>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="exams && exams.length==0">
        {{language.noExamsFound}}
      </div>
    </div>
  </div>
</div>
