import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ScheduleQuestionAndOptions } from '../models/Exam';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CbtserviceService } from '../services/cbtservice.service';
import { EncryptDecryptService } from '../services/encrypt-decrypt.service';
import { ExamService } from '../services/exam.service';
import { LanguageService } from '../services/language.service';
import { QuestionserviceService } from '../services/questionservice.service';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-saq-answer',
  templateUrl: './saq-answer.component.html',
  styleUrls: ['./saq-answer.component.css']
})
export class SaqAnswerComponent implements OnInit {
  @Input() question: ScheduleQuestionAndOptions;
  @Input() examid: number;
  @Input() issaq: boolean;
  @Input() loadingSubject: Subject<boolean>;
  @Input() networkFailureSubject: Subject<boolean>;

  essayanswer: string;
  blurred = false
  focused = false;
  @ViewChild('answerInput') answerInput: ElementRef<HTMLInputElement>;
  //i = 0;
  previousAnswer: string;
  maxwordcount: number = 0;
  scheduleid: number;
  schedulequestionid: number;
  thisurl = location.origin + '/';
  @Output() updatedanswer: EventEmitter<string> = new EventEmitter();
  higlight: boolean;
  previousQuestion: ScheduleQuestionAndOptions;
  destroyed = false;
  inputChangeTimeout = undefined;
  constructor(public questionservice: QuestionserviceService, public examservice: ExamService, public toastr: ToastrService, public router: Router, private cbtservice: CbtserviceService, private languageService: LanguageService, private encryptdecryptservice: EncryptDecryptService) { }

  async ngOnDestroy(): Promise<void> {
    this.destroyed = true;
    if(this.question?.examQuestion?.schedulequestionid){
      this.saveData(this.question?.examQuestion?.schedulequestionid, null);
    }
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    
    //here, we need to know when we are switching from one question to another so that we save the data before displaying the next question
    //save the previous html before we set a new answer
    if (this.question?.examQuestion?.schedulequestionid != this.previousQuestion?.examQuestion?.schedulequestionid) {
      this.setFocus();
      //if the question has changed, save the html in the editor to the html of the previous question
      if (this.previousQuestion) {
        this.saveData(this.previousQuestion?.examQuestion?.schedulequestionid, null);
      }
      if (this.question) {
        this.previousQuestion = this.question;
        
        this.scheduleid = this.question.examination.scheduleid;
        
        this.schedulequestionid = this.question.examQuestion.schedulequestionid;
        this.maxwordcount = this.question.examQuestion.maxwordcount;
        this.previousAnswer = this.question.examQuestion.saqanswer;
        this.essayanswer = this.question.examQuestion.saqanswer;

        if(!environment.production){
          console.log(`loaded ${this.schedulequestionid}`);
        }
      }
      if(this.answerInput?.nativeElement){
        this.setAnswer();
      }
    }

  }

  get language() {
    return this.languageService.language;
  }


  async ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.setAnswer();
    //this.callSaveData();
    this.setFocus();
  }

  onAnswerChange() {
    this.saveData(this.schedulequestionid, null);
  }

  
  onAnswerInputChange(){
    //save it in 2 seconds if it hasn't been saved already
    //this is just in case the user never leaves the control or navigates away
    clearTimeout(this.inputChangeTimeout);
    this.inputChangeTimeout = setTimeout(() => {
      this.saveData(this.schedulequestionid, null);
    }, 2 * 1000);
  }

  saveData(schedulequestionid: number, callback) {
    
    if(this.inputChangeTimeout){
      if(!environment.production){
        console.log(`clearing input change timeout`);
      }
      clearTimeout(this.inputChangeTimeout);
    }
    
    //never use this.question here
    //this.question may have been updated earlier than the other relevant info
    //those are manually changed in onChanges()
    try {
      let answer = this.answerInput.nativeElement.value;

      //debugger;
      if (this.previousAnswer !== answer) {
        
        const data = this.questionservice.constructAnswerSaveData(schedulequestionid, this.scheduleid, [], false, this.examid, true, answer);
        this.questionservice.markanswer(data);
        this.updatedanswer.emit(answer)
        //console.log(`saved data ${this.i}`);
        if(!environment.production){
          console.log(`saved ${schedulequestionid}. previous answer '${this.previousAnswer}' != current answer '${answer}'`);
        }
        this.previousAnswer = answer;
      }
      else {
        if(!environment.production){
          console.log(`Not saving ${schedulequestionid} because answer has not changed. previous answer '${this.previousAnswer}' == current answer '${answer}'`);
        }
        //if (callback) callback();
      }
    }
    finally {
      if (!this.destroyed) {
        if (callback) callback();
      }
    }
  }

  // callSaveData() {
  //   this.i++;
  //   console.log(`saving data ${this.i}`);
  //   this.saveData(() => {
  //     setTimeout(() => { this.callSaveData(); }, 1000 * this.questionservice.essaySavingIntervalSeconds);//2s seconds
  //   });
  // }

  // savebtnclicked() {
  //   this.saveData(this.question?.examQuestion?.schedulequestionid, null);
  // }

  setAnswer() {
    if (this.essayanswer) {
      this.answerInput.nativeElement.value = this.essayanswer;
    } else {
      this.answerInput.nativeElement.value = '';
    }
  }
  private setFocus(): void {
    setTimeout(() => {
      if (this.answerInput?.nativeElement) {
        this.answerInput.nativeElement.focus();
  }
    },500);
  }
  preventComma(event: KeyboardEvent) {
    if (event.key === ',') {
      event.preventDefault();
    }
  }
  
}
