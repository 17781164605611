import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Util } from '../interceptors/util';
import { CbtserviceService } from '../services/cbtservice.service';
import { ExamService } from '../services/exam.service';
import { UserauthenticationserviceService } from '../services/userauthenticationservice.service';
import * as $ from 'jquery';
import { ExamInfo } from '../models/Exam';
import { CandidateComplaint, ComplaintCateory } from '../models/model';
import { LanguageService } from '../services/language.service';

@Component({
  selector: 'app-complaint',
  templateUrl: './complaint.component.html',
  styleUrls: ['./complaint.component.css']
})
export class ComplaintComponent implements OnInit {

  isLoading: boolean = false;
  logourl: any;
  currentTab = 0;
  disableCandidateinformation: boolean = false;
  selectedExaminationSch: ExamInfo = new ExamInfo;
  exams: ExamInfo[];
  complainthelptexts: any[];
  candidateComplaint: CandidateComplaint = new CandidateComplaint;
  examNo = "";
  lastName = "";
  isAdditional = false;
  response = "";
  complaintCateory: ComplaintCateory;
  complaintCateoryNumber: string;
  Cateories: ComplaintCateory[];
  lastBtn: boolean = false;
  constructor(public util: Util,
    public userauthservice: UserauthenticationserviceService,
    public toastr: ToastrService, private cbtservice: CbtserviceService,
    public examservice: ExamService, public router: Router, public activatedroute: ActivatedRoute, private languageService: LanguageService) { }

    get language() {
      return this.languageService.language;
    }
  

  async ngOnInit() {
    const subdomain = this.util.getSubdomain();
    this.isLoading = true;
    if (subdomain) {
      this.isLoading = true;
      var data: any = await this.userauthservice.getsubdomaincompanyinfo(subdomain).toPromise().catch(err => this.toastr.error(err)).finally(() => this.isLoading = false)
      this.logourl = data ? data.logourl : "";
    }
  }
  async nextPrev(n) {
    try {
      this.isLoading = true;

      let x = document.getElementsByClassName("step");
      if (n == 1 && !this.validateForm()) return false;
      (x[this.currentTab] as HTMLElement).style.display = "none";
      this.currentTab += n;
      if (this.currentTab >= x.length) {
        await this.submitForm();
      }

      if (this.currentTab == 1) {
        this.exams = await this.examservice.getusercomplaintexams(this.examNo, this.lastName);
      }
      if (this.currentTab == 2) {
        if (this.selectedExaminationSch?.scheduleid==0) {
          this.toastr.error('Please select an exam to continue');
          this.currentTab -= n;
        } else {
          this.Cateories = await this.examservice.getcomplaintcategory(this.examNo, this.selectedExaminationSch.scheduleid)
        }

      }
    } catch (error) {
      console.log(error);
    } finally {
      this.showTab(this.currentTab);
      this.isLoading = false;
    }

  }
  validateForm() {
    let valid = true;
    let x = document.getElementsByClassName("step");
    let y = x[this.currentTab].getElementsByTagName("input");
    let z = x[this.currentTab].getElementsByTagName("select");

    // Validate input elements
    for (let i = 0; i < y.length; i++) {
      if (y[i].type === "radio") {
        let radioGroup = document.getElementsByName(y[i].name);
        let groupValid = false;
        for (let j = 0; j < radioGroup.length; j++) {
          if ((radioGroup[j] as HTMLInputElement).checked) {
            groupValid = true;
            break;
          }
        }
        if (!groupValid) {
          for (let j = 0; j < radioGroup.length; j++) {
            let parentDiv = (radioGroup[j] as HTMLElement).closest('.form-check');
            if (parentDiv) {
              parentDiv.classList.add("invalid");
            }
          }
          valid = false;
        }
      } else if (y[i].value === "") {
        y[i].className += " invalid";
        valid = false;
      }
    }

    // Validate select elements
    for (let j = 0; j < z.length; j++) {
      if (z[j].value === "" || z[j].value === null) {
        z[j].className += " invalid";
        valid = false;
      } else {
        z[j].classList.remove("invalid");
      }
    }

    return valid;
  }


  async submitForm() {
    try {
      this.isLoading = true;
      this.candidateComplaint.lastName = this.lastName;
      this.candidateComplaint.examNo = this.examNo;
      this.candidateComplaint.scheduleId = this.selectedExaminationSch.scheduleid;
      this.candidateComplaint.examId = this.selectedExaminationSch.examid;
      this.candidateComplaint.response = this.response;
      this.candidateComplaint.complaintCategory = this.complaintCateory;
      let complaintID = (await this.examservice.submitComplaint(this.candidateComplaint)).complaintID;
      this.toastr.success('Complaint submitted successfully');
      this.router.navigate([`/complaint/${complaintID}/${this.examNo}`]);

    } catch (error) {
      this.currentTab -= 1;
      this.cbtservice.showHttpError(error);

    } finally {
      this.isLoading = false;
    }

  }

  showTab(n) {
    let x = document.getElementsByClassName("step");
    (x[n] as HTMLElement).style.display = "block";
    let progress = (n / (x.length - 1)) * 100;
    (document.querySelector(".progress-bar") as HTMLElement)
      .style.width = progress + "%";
    document.querySelector(".progress-bar")
      .setAttribute("aria-valuenow", "" + progress);
    document.getElementById("nextBtn").innerHTML = n == x.length - 1 ? this.language.submit : this.language.next;
    this.lastBtn = n == x.length - 1 ? true : false;
  }

  SelectExamiantion(exam: ExamInfo) {
    this.selectedExaminationSch = exam
  }

  changeAdditional() {
    this.isAdditional = !this.isAdditional;
  }


  setCategory(evt) {
    this.complaintCateoryNumber = evt.target.value;
    this.complaintCateory = this.Cateories.find(c => c.categoryID == this.complaintCateoryNumber);
  }
}
