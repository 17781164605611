import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CalculatorToggleService {
  private toggleCalculatorSubject = new Subject<void>();

  toggleCalculator$ = this.toggleCalculatorSubject.asObservable();

  constructor() { }
  
  toggle() {
    this.toggleCalculatorSubject.next();
  }
}
