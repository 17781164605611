import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ScheduleOptionsProp } from '../models/Exam';
import { Subject } from 'rxjs';
//import { OptionDetails } from '../models/model';

@Component({
  selector: 'app-questionoptions',
  templateUrl: './questionoptions.component.html',
  styleUrls: ['./questionoptions.component.css']
})
export class QuestionoptionsComponent implements OnInit {
  ngOnInit(): void {
  }
@Input() schedulequestionid:number;
  @Input() options : ScheduleOptionsProp[];
  @Input() multiple:boolean;
  @Input() loadingSubject: Subject<boolean>;
  @Input() networkFailureSubject: Subject<boolean>;
  // [
  //   {
  //     "optionhtml": "<em><b>something is happening</b></em>",
  //     "optionid": 123,
  //     "optionno": 1,
  //   },
  //   {
  //     "optionhtml": "<em><b>something is happening</b></em>",
  //     "optionid": 124,
  //     "optionno": 2,
  //   },
  //   {
  //     "optionhtml": "<em><b>something is happening</b></em>",
  //     "optionid": 125,
  //     "optionno": 3,
  //   },
  //   {
  //     "optionhtml": "<em><b>something is happening</b></em>",
  //     "optionid": 125,
  //     "optionno": 3,
  //   },   
  // ];

  @Output() radioselectedvalue: EventEmitter<ScheduleOptionsProp> = new EventEmitter();
  @Output() checkselectedvalue: EventEmitter<ScheduleOptionsProp> = new EventEmitter();
  
  constructor() { }

  OnRadioSelected(option: ScheduleOptionsProp) {
    this.radioselectedvalue.emit(option);
  }

  oncheckchanged(option:ScheduleOptionsProp){
    this.checkselectedvalue.emit(option);
  }
}
