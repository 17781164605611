import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CbtserviceService } from '../services/cbtservice.service';
import { ExamService } from '../services/exam.service';
import { LanguageService } from '../services/language.service';

@Component({
  selector: 'app-webcam-passport',
  templateUrl: './webcampassport.component.html',
  styleUrls: ['./webcampassport.component.css']
})
export class WebcamComponent implements OnInit, AfterViewInit {
  @Input() examno
  @Output() imagefile: EventEmitter<any> = new EventEmitter<any>();

  constructor(public examservice: ExamService, private cbtService: CbtserviceService,private languageService: LanguageService) { }
  get language() {
    return this.languageService.language;
  }
  ngOnInit(): void {
  }

  @ViewChild("video")
  public video: ElementRef;

  @ViewChild("canvas")
  public canvas: ElementRef;

  captured: string = "";
  error: any;
  isCaptured: boolean;

  async ngAfterViewInit() {
    await this.checkdevice();
  }

  async checkdevice() {
    //if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        const stream = await this.cbtService.getVideoStream();
        if (stream) {
          this.video.nativeElement.srcObject = stream;
          this.video.nativeElement.play();
          this.error = null;
        } else {
          this.error = this.language.youhavenooutputvideodevice;
        }
      } catch (ex) {
        this.error = ex;
      }
    //}
  }

  async capture() {
    this.drawImageToCanvas(this.video.nativeElement);
    this.captured = this.canvas.nativeElement.toDataURL("image/jpeg");
    this.isCaptured = true;
  }

  async save() {
    const arr = this.captured.split(",");
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const file: File = new File([u8arr], this.examno, { type: "jpeg" });

    this.imagefile.emit(file);

    console.log(file);
  }

  removeCurrent() {
    this.isCaptured = false;
  }

  // setPhoto() {
  //   this.isCaptured = true;
  //   var image = new Image();
  //   image.src = this.captured;
  //   this.drawImageToCanvas(image);
  // }

  drawImageToCanvas(video: any) {
    //the radius of the circle displayed is 40%. Which should make the total diameter (width) 80%
    const clippedWidth = video.videoWidth * .8;
    const clippedHeight = clippedWidth;
    this.canvas.nativeElement.width=clippedWidth
    this.canvas.nativeElement.height=clippedHeight
    const context = this.canvas.nativeElement.getContext("2d");
    context.scale(-1, 1);
    context.drawImage(video, (video.videoWidth - clippedWidth) / 2, (video.videoHeight - clippedHeight) / 2, clippedWidth, clippedHeight, 0, 0, clippedWidth*-1, clippedHeight);

    // this.canvas.nativeElement.width=video.videoWidth
    // this.canvas.nativeElement.height=video.videoHeight
    // const context = this.canvas.nativeElement.getContext("2d");
    // context.scale(-1, 1);
    // context.drawImage(video, 0, 0, this.canvas.nativeElement.width*-1, this.canvas.nativeElement.height);
  }
}
