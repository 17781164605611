import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root'
})
export class CbtserviceService {
  private previousUrl: string = undefined;
  private queryparam:any;
  private currentUrl: string = undefined;
  private getMediaStreamPromise: Promise<MediaStream>;

  constructor(private router: Router, private toastr: ToastrService, private http: HttpClient, private languageService: LanguageService) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        //console.log(event.url);
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }
  get language() {
    return this.languageService.language;
  }
  public getPreviousUrl() {
    return  this.previousUrl;
  } 

  showHttpError(error: HttpErrorResponse){
    console.error(error);
    if(error.status === 0){
      this.toastr.warning(this.language.unabletoconnecttoserver, this.language.connectionError, { timeOut : 5000 });      
    }
    else if(error.status === 400){   
      console.log(error);
      this.toastr.error(error.error && error.error.message ? error.error.message : this.language.unknownserversideerror, this.language.error, { timeOut : 8000 });
    }
    else{
      if(typeof(error) == 'string'){
        this.toastr.error(error, this.language.error, { timeOut : 8000 });
      }
      else if(error && error.error && error.error.message && typeof(error.error.message) == 'string'){
        this.toastr.error(error.error.message, this.language.error, { timeOut : 8000 });
      }
      else if(error && error.error && typeof(error.error) === 'string'){
        this.toastr.error(error.error, this.language.error, { timeOut : 8000 });
      }
      else if(error && error.error && error.error.error && typeof(error.error.error) == 'string'){
        this.toastr.error(error.error.error, this.language.error, { timeOut : 8000 });
      }
      else if(error && error.statusText){
        this.toastr.error(error.statusText, this.language.error, { timeOut : 8000 });
      }
      else{
        this.toastr.error(this.language.error, this.language.error, { timeOut : 8000 });
      }
      
      
    }
  }

  async tryGet(url: string, options: any, maxAttempts = environment.maxRequestRetries,timeout=7000): Promise<any>{
    let attempts = 0;
    while(true){
      attempts++;
      try{
        return await this.http.get(url, options).toPromise();
      }
      catch(error){
        if(attempts < maxAttempts && (error.status === 0 || (error.error && error.error.message && error.error.message.toUpperCase().includes(environment.seamlessScalingError)))){
          //return await this.getuserexams(candidateno);
          //probably gateway timeout (when status === 0) or seamless scaling error. retry
        }
        else{
          //this.cbtservice.showHttpError(error);
          //this.isloading = false;
          throw error;
        }
      }
    }
  }

  async tryPost(url: string, data: any, options: any, maxAttempts = environment.maxRequestRetries,timeout=5000): Promise<any>{
    let attempts = 0;
    while(true){
      attempts++;
      //console.log(attempts)
      try{
        return await this.http.post(url, data, options).toPromise();
      }
      catch(error){
        if(attempts < maxAttempts && (error.status === 0 || !error.status || (error.error && error.error.message && error.error.message.toUpperCase().includes(environment.seamlessScalingError)))){
          //console.log(`attempt: ${attempts}`);
          console.error(error);
          //return await this.submitexam(scheduleid, elapsed);
          //probably gateway timeout (when status === 0) or seamless scaling error. retry
        }
        else{
          //this.cbtservice.showHttpError(error);
          //this.isloading = false;
          throw error;
        }
      }
    }
  }

  async getVideoStream(){
    if(this.getMediaStreamPromise === undefined){
      this.getMediaStreamPromise = navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: 'user'
        }
      });
    }

    const stream = await this.getMediaStreamPromise;
    return stream;
  }

  async runFunctionWithTimeout<T>(fn: () => Promise<T>, timeoutMs: number): Promise<T> {
    const sleepPromise = new Promise<T>(async (resolve, reject) => {
      await this.sleep(timeoutMs);
      reject('timeout');
    });

    const functionPromise = new Promise<T>(async (resolve, reject) => {
      const ret = await fn();
      resolve(ret);
    })

    const ret = await Promise.race([functionPromise, sleepPromise]);

    return ret;
  }

  sleep(milliseconds){
    return new Promise((resolve, reject) =>{
      setTimeout(resolve, milliseconds);
    });
  }
}

