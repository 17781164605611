import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ILanguage } from '../languages/ILanguage';
import { Gibberish } from '../languages/Gibberish';
import { English } from '../languages/English';
import { French } from '../languages/French';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private _languages: any = { 'en': new English(), 'fr': new French() };
  private _language: ILanguage;
  constructor() {
    if (!environment.production) {
      this._languages.gib = new Gibberish();
    }

    this.init();
  }

  private init() {
    //this null check is not necessary but should incase
    const languageCode = this.getLanguageCode() ?? 'en';
    this._language = this._languages[languageCode];
  }

  getLanguageCodeFromCookie() {
    const langStore = environment.languageStore + "=";
    let cookieData = document.cookie.split(';');
    for (let i = 0; i < cookieData.length; i++) {
      let trimmedCookieData = cookieData[i].trimStart();
      if (trimmedCookieData.indexOf(langStore) == 0) return trimmedCookieData.substring(langStore.length, trimmedCookieData.length);
    }
    return null;
  }

  setLanguageCodeToCookie(lang: string) {
    const cookieName = environment.languageStore;
    //cookies uses Path scoping By using the root path (/), the cookie will apply to all requests on the domain
    const cookieValue = `${cookieName}=${lang}; path=/`;
    document.cookie = cookieValue;
    this.init();
  }
  get language(): ILanguage {
    return this._language;
  }

  getLanguageCode() {
    // Check if a language code is already stored in the cookie
    if (!this.getLanguageCodeFromCookie()) {
      var userLangCode = (navigator.language).slice(0, 2);
      // console.log("The language is: " + userLangCode);

      // Check if the userLangCode exists in the _languages 
      if (this._languages.hasOwnProperty(userLangCode)) {
        // If the language is supported(added), set it in the cookie and return
        this.setLanguageCodeToCookie(userLangCode);
        return userLangCode;
      } else {
        // If the language is not supported, default to 'en'
        return 'en';
      }
    } else {
      // If a language code is found in the cookie, return it
      return this.getLanguageCodeFromCookie();
    }
  }

  examTypeTranslation(examtype: string) {
    switch (examtype) {
      case "Multiple-Choice":
        return this.language.multipleChoice;
      case "Interview":
        return this.language.interview;
      case "Essay":
        return this.language.essay;
      case "Short Answer Question":
        return this.language.shortAnswerQuestion;
      default:
        return examtype;
    }
  }
}
