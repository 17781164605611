import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Util } from 'src/app/interceptors/util';
import { CandidateComplaint } from 'src/app/models/model';
import { CbtserviceService } from 'src/app/services/cbtservice.service';
import { ExamService } from 'src/app/services/exam.service';
import { LanguageService } from 'src/app/services/language.service';
import { UserauthenticationserviceService } from 'src/app/services/userauthenticationservice.service';

@Component({
  selector: 'app-all-complaint',
  templateUrl: './all-complaint.component.html',
  styleUrls: ['./all-complaint.component.css']
})
export class AllComplaintComponent implements OnInit {
  examNo = "";
  lastName = "";
  isLoading: boolean = false;

  allComplaints: CandidateComplaint[];
  constructor(public util: Util,
    public userauthservice: UserauthenticationserviceService,
    public toastr: ToastrService, private cbtservice: CbtserviceService,
    public examservice: ExamService, public router: Router, public activatedroute: ActivatedRoute, private languageService: LanguageService) { }
    
  get language() {
    return this.languageService.language;
  }
  async ngOnInit() {
    this.examNo = this.activatedroute.snapshot.params.examno;
    this.lastName = this.activatedroute.snapshot.params.lastname;
    this.isLoading = true;
    try {
      if (this.examNo && this.lastName) {
        await this.examservice.getallusercomplaints(this.examNo, this.lastName).then((result: any) => {
          this.allComplaints = result;
        }).catch((err) => {
          this.isLoading = false;
          this.cbtservice.showHttpError(err);

          this.router.navigate(['/404']);
        })
          .finally(() => (this.isLoading = false));
      }

    } catch (error) {
      this.cbtservice.showHttpError(error);
    } finally {
      this.isLoading = false;
    }
  }

  GotoComplaint(complaint: any) {
    this.router.navigate([`/complaint/${complaint.complaintID}/${complaint.examNo}`]);
  }
}
