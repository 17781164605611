import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LanguageService } from 'src/app/services/language.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-language-layout',
  templateUrl: './language-layout.component.html',
  styleUrls: ['./language-layout.component.css']
})
export class LanguageLayoutComponent implements OnInit {
  selectedLanguage: string = null;
  languages: any = [{ lang: 'English', code: 'en' }, {lang: 'Français', code: 'fr'}];
  constructor(private languageService: LanguageService, public activatedroute: ActivatedRoute,) {
    if(!environment.production){
      this.languages.push({ lang: 'Gibberish', code: 'gib' });
    }
  }
 
  get language(){
    return this.languageService.language;
  }
  ngOnInit() {
    let langcode = this.activatedroute.snapshot.queryParams.langcode;
    if(langcode){
      this.languageService.setLanguageCodeToCookie(langcode);
    }
    this.selectedLanguage = this.languageService.getLanguageCodeFromCookie();
    
  }

  languageChanged(event: any) {
    let v = event.target.value;
    this.languageService.setLanguageCodeToCookie(v);
    this.selectedLanguage = v
    window.location.reload();
  }

}
