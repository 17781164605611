import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Util } from '../interceptors/util';
import { loginDetails } from '../models/model'
import { CbtserviceService } from '../services/cbtservice.service';
import { ProctoringService } from '../services/proctoring.service';
import { UserauthenticationserviceService } from '../services/userauthenticationservice.service';
import { LanguageService } from '../services/language.service';
import { environment } from 'src/environments/environment';
declare const SafeExamBrowser;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  logininfo: loginDetails;
  username: string;
  password: string
  isLoading: boolean = false;
  logourl: any;
  externaltoken: string;
  externalportalname: string;
  fromexternalauth: string;//this is set to true when redirected from logout. it is used to hide the login form when it should't be used...such users shoud login from their external website
  seb: string;
  constructor(public userauthservice: UserauthenticationserviceService, public toastr: ToastrService, public activatedroute: ActivatedRoute, public router: Router, private proctorservice: ProctoringService, public util: Util, private cbtservice: CbtserviceService,private languageService: LanguageService) { }
  get language(){
    return this.languageService.language;
  }
  async ngOnInit() {
    //     var req = new XMLHttpRequest();
    // req.open('GET',document.location.href, false);
    // req.send(null);
    // var headers = req.getAllResponseHeaders().toLowerCase();
    // alert(headers);
    //debugger;
    // setTimeout(() => {
    //   alert(SafeExamBrowser.security.configKey);
    //   alert(window.location.href);
    // },
    // 5000)
    await this.proctorservice.stopProctor('Login Page Init');
    var unauthorized = this.activatedroute.snapshot.queryParams.unauthorized
    if (unauthorized) {
      this.toastr.error(this.language.userMis_match);
    }
    //debugger
    const subdomain = this.util.getSubdomain();
    if (subdomain) {
      this.isLoading = true;
      var data: any = await this.userauthservice.getsubdomaincompanyinfo(subdomain).toPromise().catch(err => this.toastr.error(err)).finally(() => this.isLoading = false)
      this.logourl = data ? data.logourl : "";
    }

    this.externaltoken = this.activatedroute.snapshot.queryParams.externaltoken;
    console.log("externaltoken is " + this.externaltoken)

    this.externalportalname = this.activatedroute.snapshot.queryParams.externalportalname;

    //debugger;
    this.fromexternalauth = this.activatedroute.snapshot.queryParams.fromexternalauth;
    //this.seb = this.activatedroute.snapshot.queryParams.seb;
    if(!this.seb && typeof SafeExamBrowser !== 'undefined'){
      this.seb = await this.getSEBConfigKey();
      //alert(SafeExamBrowser.version);
    }
    //console.log("seb is " + this.seb);
    //
    //the auto login was disabled so that pre authed users can use the functionality on the login page. e.g. run system check and make complaints
    // if (this.externaltoken) {
    //   console.log(`is here`)
    //   await this.loginclicked();
    // }
  }

  async getSEBConfigKey(){
    if(typeof SafeExamBrowser !== 'undefined'){
      let configKey: string;
      if(self.top != self){
        //if we are inside proctor, we have to ask the proctor for the api key because it'll be different for different urls
        configKey = await this.proctorservice.getSebConfigKey();
      }
      else{
        configKey = SafeExamBrowser?.security?.configKey;
      }
      return configKey;
      //alert(SafeExamBrowser.version);
    }
    else{
      return undefined;
    }
  }


  async loginclicked() {
    //debugger
    let token = "";
    if (this.externaltoken) {
      this.isLoading = true;
      const subdomain = this.util.getSubdomain();
      const data: any = await this.userauthservice.externalUserAuthentication(this.externaltoken, subdomain, this.externalportalname).toPromise().catch(err => { this.cbtservice.showHttpError(err); this.isLoading = false; });
      token = data.token;
      //this.userauthservice.setAuthenticatedUserToken(data.token, true);
      //this.goToExamsPage();
    }
    else {
      const logininfo = {
        username: this.username,
        password: this.password
      }

      // console.log(logininfo.username);
      this.isLoading = true;
      const data: any = await this.userauthservice.userAuthentitcation(logininfo.username, logininfo.password).toPromise().catch(err => { this.cbtservice.showHttpError(err); this.isLoading = false; });
      token = data.token;
    }
    
    this.userauthservice.setAuthenticatedUserToken(token, false, this.seb);
    
    //console.log(`authentication set ${token}`)
    //debugger;
    if (environment.isSeb) {
      try{
      const valid = await this.userauthservice.checksebconfig(this.seb).toPromise();
        if(valid){
          this.goToExamsPage();
        }
      }
      catch(err){
        this.cbtservice.showHttpError(err);
        this.isLoading = false;
      }
      //await this.proctorservice.hideSEBPowerButton(this.username);
    }
    else{
      this.goToExamsPage();
    }
  }

  private goToExamsPage() {
    console.log(`exam called`)
    this.isLoading = false;
    this.userauthservice.loggedOut = false;
    if (this.activatedroute.snapshot.queryParams.url) {
      this.router.navigateByUrl(this.activatedroute.snapshot.queryParams.url);
    }
    else {
      this.router.navigate(['/exam/userexams']);
    }
  }

}
