<div class="overlay" [ngClass]="{'show': isloading}"></div>
<div class="spanner" [ngClass]="{'show': isloading}">
    <!-- <div class="loader"></div> -->
    <div class="spin-wrap">
        <div class="spin-wrap-content">
            <div class="spinner-container">
                <div class="lds-ripple">
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <div *ngIf="isloading" class="p-5"
    style="display: flex; justify-content: center;align-items: center; text-align: center; height: 80vh; width: 80vw;">
    <app-loading-spinner></app-loading-spinner>
</div> -->

<div class="card h-100 mx-auto">
    <!-- Card header -->
    <div class="card-header submit-exam">
        <!-- Title -->
        <h5 class="mb-0">{{language.submitExamination}}</h5>
    </div>
    <!-- Card image -->
    <!-- List group -->
    <!-- Card body -->
    <div class="card-body d-flex flex-column align-items-center justify-content-center w-100">
        <div *ngIf="!msg && !isloading" class="home-text" style="width: 90%; text-align: center;">
            <h2 class="mb-5">{{language.submitConfirmationMessage}}</h2>


            <button class="btn btn-primary  mr-5" (click)="noclicked()" [disabled]="this.submitting">
                <h2 style="color: white !important;">{{language.no}}</h2>
            </button>

            <button class="btn btn-danger" (click)="yesclicked()" [disabled]="this.submitting">
                <h2 style="color: white !important;">{{this.submitting ? language.submitting : language.yes}}</h2>
            </button>
        </div>
        <app-examfeedback #feedmodal></app-examfeedback>
        <div *ngIf="msg" class="h2 mb-5" style="width: 90% ; text-align: center;" id="exammessage" [innerHTML]="msg">
        </div>
        <div *ngIf="postmsg" class="h2 mb-5" style="width: 90% ; text-align: center;" id="exammessage" [innerHTML]="postmsg">
        </div>
        <div *ngIf="score && msg" class="h1 mb-5" style="width: 90% ; text-align: center;">
             {{language.yourScore}} {{score}}
        </div>
        <div *ngIf="msg" style="width: 90%; text-align: center;">
            <a style="cursor:pointer;" class="regular-button start_new_exm" (click)="newexam()">{{language.startNewExam}}</a>
        </div>
        <div class="d-flex justify-content-center mt-5" style="width: 90%;">
            <a class="complaint" (click)="gotoComplaint()">{{language.makeComplaint}}</a>
         </div>
    </div>
</div>