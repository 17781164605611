import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scientific-calc-v3',
  templateUrl: './scientific-calc-v3.component.html',
  styleUrls: ['./scientific-calc-v3.component.css']
})
export class ScientificCalcV3Component {


}
