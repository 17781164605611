<div class="" *ngIf="!adminpaused && !proctorpaused">
  <section id="" class="mobiletop p-0" style="width: 100%;">
    <div class="overlay" [ngClass]="{'show': isloadingdata}">
    </div>
    <div class="spanner" [ngClass]="{'show': isloadingdata}">
      <div class="spin-wrap">
        <div class="spin-wrap-content">
          <div class="spinner-container">
            <div class="lds-ripple">
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 mx-auto border border-0 rounded-lg w-100">
      <div class="row pb-0 paddedrow">
        <div class="col-lg-12 d-flex flex-column">
          <div *ngIf="question.examQuestion.instructions" style="border: 1px #ccc solid; padding:2px">
            <p><strong> {{language.instructions}}:</strong> {{question.examQuestion.instructions}}</p>
          </div>
          <div class="icon-box justify-content-center mx-3 pl-2 mb-2 h3" data-aos="fade-up" data-aos-delay="100">
            <section #questionhtmlsection class="lead main pt-0"
              style="font-size: 20px !important; color: #000; font-family: Poppins !important;"></section>
          </div>
        </div>
      </div>
      <div class="row pb-0 paddedrow questionborder">
      </div>
      <div class="row pb-0 paddedrow" style="width: 100%;">
        <div class="col-lg-12 d-flex flex-column" *ngIf="!essayexam">
          <div class="section-title">
          </div>
          <div class="icon-box justify-content-center h3" data-aos="fade-up" data-aos-delay="100">
            <app-questionoptions style="font-family: Poppins !important;" *ngIf="this.question.examQuestionOptions"
              [options]="this.question.examQuestionOptions"
              [schedulequestionid]="question.examQuestion.schedulequestionid"
              [multiple]="question.examQuestion.hasMultipleAnswers" (radioselectedvalue)="OnRadioSelected($event)"
              (checkselectedvalue)="oncheckchanged($event)" [loadingSubject]="this.loadingSubject"
              [networkFailureSubject]="this.networkFailureSubject"></app-questionoptions>
          </div>
        </div>

        <app-essay class="col-lg-12 d-flex flex-column" *ngIf="essayexam && !issaq"
          (updatedanswer)="updatedanswer($event)" [question]="this.question" [examid]="this.question.examination.examid"
          [loadingSubject]="this.loadingSubject" [networkFailureSubject]="this.networkFailureSubject"></app-essay>

        <app-saq-answer class="col-lg-12 d-flex flex-column" *ngIf="essayexam && issaq"
          (updatedanswer)="updatedanswer($event)" [question]="this.question" [issaq]="issaq"
          [examid]="this.question.examination.examid" [loadingSubject]="this.loadingSubject"
          [networkFailureSubject]="this.networkFailureSubject"></app-saq-answer>
      </div>
    </div>
  </section>
</div>