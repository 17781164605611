<div class="row m-2 exam-start-wrap">
  <div class="col-md-3 user_exam_type">
    <div class="media align-items-center">
      <!-- <a href="#" class="avatar rounded-circle mr-3">
          <img alt="Image placeholder" src="../../assets/img/theme/bootstrap.jpg">
      </a> -->
      <div class="media-body exam-start-wrap">
        <h4><span class="name m-0">{{exam.name}}</span></h4>
      </div>
    </div>
  </div>
  <div *ngIf="!exam.selfschedule" class="col-md-2 mt-2 exam-start-wrap">
    <h5>{{this.localexamdate | date:'medium'}}</h5>
  </div>
  <div class="col-md-2 mt-2 exam-start-wrap">
    <h5>{{languageService.examTypeTranslation(exam.examtype)}}</h5>
  </div>
  <div class="col-md-2 mt-2 exam-start-wrap">
    <h5>{{exam.duration}} {{language.minutes}}</h5>
  </div>
  <div *ngIf="exam.examscorestring != '' && !isloading" class="col-md-1 mt-2 exam-start-wrap">
    <h5>{{exam.examscorestring}}</h5>
  </div>
  <div class="col-md-2 exam-buttons h5">
    <!-- remove class m-0 p-0 -->

    <ng-template [ngIf]="this.isinterviewexam">
      <button *ngIf="!exam.useSEB || (exam.useSEB && this.runningInSEB); else sebInstructionsButton_Start" type="button"
        [disabled]="!exam.canstart" (click)="getInstructions()" class="btn btn-sm  btn-success statusbtn">
        {{language.join}}
      </button>

      <ng-template #sebInstructionsButton_Start>
        <button type="button" (click)="goToSEBInstructionsPage()" class="btn btn-sm  btn-success statusbtn">
          {{language.takeExam}}
        </button>
      </ng-template>
    </ng-template>


    <ng-template [ngIf]="this.exam.status==pending && !isloading && !exam.selfschedule && exam.examtype!='Interview'">
      <button *ngIf="!exam.useSEB || (exam.useSEB && this.runningInSEB); else sebInstructionsButton_Start" type="button"
        [disabled]="!exam.canstart" (click)="getInstructions()" class="btn btn-sm  btn-success statusbtn">
        {{language.start}}
      </button>

      <ng-template #sebInstructionsButton_Start>
        <button type="button" (click)="goToSEBInstructionsPage()" class="btn btn-sm  btn-success statusbtn">
          {{language.takeExam}}
        </button>
      </ng-template>
    </ng-template>

    <ng-template [ngIf]="!isloading && this.iscontinue">
      <button type="button"
        *ngIf="!exam.useSEB || (exam.useSEB && this.runningInSEB); else sebInstructionsButton_Continue" type="button"
        (click)="gotoexam(false)" class="btn btn-sm btn-primary statusbtn">
        {{language.continue}}
      </button>

      <ng-template #sebInstructionsButton_Continue>
        <button type="button" type="button" (click)="goToSEBInstructionsPage()"
          class="btn btn-sm btn-primary statusbtn">
          {{language.takeExam}}
        </button>
      </ng-template>
    </ng-template>


    <!-- Scheduling Button Div -->
    <button *ngIf="exam.selfschedule && exam.status==pending && !isloading && !exam.canstart" type="button"
      (click)="showExamScheduleModal()" class="btn btn-sm btn-primary">
      {{language.scheduleExam}}
    </button>
   
    <label *ngIf="isloading" class="btn btn-sm btn-secondary statusbtn disabled">{{language.pleaseWait}}...</label>
    <label *ngIf="exam.status==finished && !isloading"
      class="btn btn-sm btn-danger statusbtn">{{language.finished}}</label>
    <label type="button" *ngIf="exam.status==timeElapsed && !isloading" class="btn btn-sm btn-danger statusbtn">
      {{language.timeElapsed}}</label>
    <label type="button" *ngIf="exam.status==disqualified && !isloading"
      class="btn btn-sm btn-danger statusbtn">{{language.disqualified}}</label>
    <label type="button" *ngIf="exam.status==stopped && !isloading"
      class="btn btn-sm btn-danger statusbtn">{{language.stopped}}</label>

    <span class="" *ngIf="isloading" style="float: right !important;">
      <img style="height:30px;"
           src="../../../assets/img/spinner.gif" />
    </span>
    <i *ngIf="exam.proctorerror || exam.passporterror" class="bi bi-info-circle text-danger"
       (click)="toggleerror()"></i>
  </div>
</div>
<div class="d-flex justify-content-end  m-2 mt-0 mb-5">
  <div class="col-md-2 exam-buttons h5">
    <button *ngIf="(exam.allowreschedule || exam.allowrescheduleAfter) && exam.status==pending && !isloading"
      type="button" (click)="showExamScheduleModal()" class="btn btn-sm btn-primary">
      {{language.rescheduleExam}}
    </button>
    <button *ngIf="exam.candidateCanRetake" type="button" class="btn btn-sm  btn-primary statusbtn" data-toggle="modal" [attr.data-target]="'#restartModal' + exam.examid">
      {{language.retakeExam}}
    </button>
  </div>

</div>
<div *ngIf="showerror" class="mx-auto" style="width:fit-content">
  <h5 class="text-danger">{{exam.proctorerror}}</h5>
  <h5 class="text-danger">{{exam.passporterror}}</h5>
</div>

<div class="modal fade" id="imodal{{exam.examid}}" tabindex="1" data-backdrop="static" aria-hidden="true" role="dialog"
     style="width: 70vw !important; margin-left: 15vw;">
  <div class="modal-dialog" style="max-width: 100% !important;">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="icon icon-close"></i>
        </button> -->
        <h4 class="modal-title ">{{language.instructions}}</h4>
      </div>
      <div class="modal-body p-0">
        <div class="card p-2">

          <div class="card-body text-center p-2" style="color: #74bbf7;">

            <div class="accordion" id="instructionaccordion{{exam.examid}}">
              <div *ngIf="instructions.webtestHTML" class="card">
                <div class="card-header" id="webtest{{exam.examid}}" data-toggle="collapse"
                     [attr.data-target]="getHTMLIDRef('webtest',exam.examid)"
                     [attr.aria-expanded]="instructions.webtestHTML ? 'true' : 'false'"
                     [attr.aria-controls]="getHTMLIDRef('webtest',exam.examid)">
                  <h6 class="mb-0">
                    <span>WEBTEST {{language.webtestInstruction}}</span>
                  </h6>
                </div>
                <div id="collapsewebtest{{exam.examid}}" class="collapse"
                    [ngClass]="{'show': instructions.webtestHTML, 'hide': !instructions.webtestHTML}"
                     [attr.aria-labelledby]="getHTMLIDAria('webtest',exam.examid)"
                     [attr.data-parent]="getHTMLIDParent(exam.examid)">
                  <div class="card-body instruct" [innerHTML]="webtesthtml">
                  </div>
                </div>
              </div>
              <div *ngIf="instructions.companyHTML" class="card">
                <div class="card-header" id="company{{exam.examid}}" data-toggle="collapse"
                     [attr.data-target]="getHTMLIDRef('company',exam.examid)"
                     [attr.aria-expanded]="!instructions.webtestHTML && instructions.companyHTML ? 'true' : 'false'"
                     [attr.aria-controls]="getHTMLIDRef('company',exam.examid)">
                  <h6 class="mb-0">
                    <span>{{instructions.company | uppercase}} {{language.instructions| uppercase}}</span>
                  </h6>
                </div>
                <div id="collapsecompany{{exam.examid}}" class="collapse" 
                    [ngClass]="{'show': !instructions.webtestHTML && instructions.companyHTML, 'hide': !(!instructions.webtestHTML && instructions.companyHTML)}"
                    [attr.aria-labelledby]="getHTMLIDAria('company',exam.examid)"
                    [attr.data-parent]="getHTMLIDParent(exam.examid)">
                  <div class="card-body instruct" [innerHTML]="companyhtml">
                  </div>
                </div>
              </div>
              <div *ngIf="instructions.examHTML" class="card">

                <div class="card-header" id="exam{{exam.examid}}" data-toggle="collapse"
                     [attr.data-target]="getHTMLIDRef('exam',exam.examid)"
                     [attr.aria-expanded]="!instructions.webtestHTML && !instructions.companyHTML && instructions.examHTML ? 'true' : 'false'"
                     [attr.aria-controls]="getHTMLIDRef('exam',exam.examid)">
                  <h6 class="mb-0">
                    <span>{{exam.name | uppercase}} {{language.instructions| uppercase}}</span>
                  </h6>
                </div>
                <div id="collapseexam{{exam.examid}}" class="collapse"
                    [ngClass]="{'show': !instructions.webtestHTML && !instructions.companyHTML && instructions.examHTML, 'hide': !(!instructions.webtestHTML && !instructions.companyHTML && instructions.examHTML)}"
                    [attr.aria-labelledby]="getHTMLIDAria('exam',exam.examid)"
                    [attr.data-parent]="getHTMLIDParent(exam.examid)">
                  <div class="card-body instruct" [innerHTML]="examhtml">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="row m-2">
        <div class="col-md-4"></div>
        <div class="modal-footer col-md-4 p-0">
          <span><input class="mr-2" type="checkbox" id="confirmation"
              (change)="confirmationchecked($event)"><span><b>{{language.agreeToInstructions}}</b></span></span>
          <input [disabled]="!confirmed" class="form-control btn btn-success" type="button" data-dismiss="modal"
            (click)="gotoexam(true)" [value]="language.startExam" />
          <input class="form-control btn btn-danger" type="button" id="clear-options" (click)="resumeinterval()"
            data-dismiss="modal" [value]="language.cancel" />
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="scheduleModal" tabindex="1" data-backdrop="static" aria-hidden="true" role="dialog"
     style="width: 50% !important; margin-left: 25%;">

  <div class="modal-dialog" style="max-width: 100% !important;">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title "> {{language.examSchedule}}</h4>
        <p>{{exam.name}}</p>
      </div>

      <div class="card">

        <div class="card-body">
          <form class="p-2">
            <div class="row">
              <div class="col-md-6">
                <label> {{language.scheduleDate}}</label>

                <div class="form-group" *ngIf="!usedropdown">
                  <input class="form-control" required type="date" (change)="onDateChange($event)" [min]="minDate"
                         [max]="maxDate">

                </div>

                <div class="form-group" *ngIf="usedropdown">
                  <select class="form-control form-select" required (change)="onDateChange($event)">
                    <option value="" disabled selected *ngIf="isDate">{{language.selectDate}} (yyyy-MM-dd)</option>
                    <option value="" disabled selected *ngIf="!isDate">{{language.noAvailableDate}}</option>
                    <option *ngFor="let scheduledDate of availabeDates" [value]="scheduledDate">
                      {{ scheduledDate }}
                    </option>
                  </select>

                </div>
              </div>
              <div class="col-md-6">
                <label>{{language.scheduleTime}}</label>
                <div class="form-group">

                  <select class="form-control form-select" required (change)="onTimeChange($event)">
                    <option value="" disabled selected *ngIf="isTime">{{language.selectTime}}</option>
                    <option value="" disabled selected *ngIf="!isTime">{{language.noAvailableTime}}</option>
                    <option *ngFor="let time of availabeTimes" [value]="time.position">
                      {{ time.displaytime }}
                    </option>
                  </select>

                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <button type="submit" class="btn form-control btn-primary"
                  [disabled]="!scheduledPosition || !scheduledDate" data-dismiss="modal" (click)="onScheduleClicked()">
                  <span class="" *ngIf="isloading" style="float: right !important;"><img style="height:30px;"
                      src="../../../assets/img/spinner.gif" /> </span> Schedule{{language.schedule}}</button>
              </div>

              <div class="col-md-6">

                <div class=""><input class="form-control btn btn-danger" type="button" id="clear-options"
                    (click)="resumeinterval()" data-dismiss="modal" [value]="language.cancel" /></div>
              </div>
            </div>


          </form>


        </div>
      </div>
    </div>
  </div>




</div>

<div class="modal fade" id="restartModal{{exam.examid}}" data-backdrop="static" aria-labelledby="restartModalLabel" aria-hidden="true"
  style="width: 50% !important; margin-left: 25%;" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="restartModalLabel">Reason for Retake</h5>
        <button type="button" class="close h3" data-dismiss="modal" aria-label="Close">&times;</button>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <label for="reason" class="form-label">Reason</label>
          <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="..."
            [(ngModel)]="retakeReason"></textarea>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal"
          aria-label="Close">{{language.cancel}}</button>
        <button type="button" class="btn btn-success" data-dismiss="modal" [disabled]="!retakeReason"
          (click)="retakeExam()">{{language.submit}}</button>
      </div>
    </div>
  </div>
</div>