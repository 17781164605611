import { AfterViewInit, Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UserauthenticationserviceService } from '../services/userauthenticationservice.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { ProctoringService } from '../services/proctoring.service';
import { Util } from '../interceptors/util';
import { CbtserviceService } from '../services/cbtservice.service';
import { ExamService } from '../services/exam.service';
import { PassportData } from '../models/model';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { CurrentUser } from '../models/CurrentUser';
import { LanguageService } from '../services/language.service';
import { CountdownComponent } from 'ngx-countdown';
import { CalculatorToggleService } from '../services/calculator-toggle.service';

declare var $: any

@Component({
  selector: 'app-exam-layout',
  templateUrl: './exam-layout.component.html',
  styleUrls: ['./exam-layout.component.css']
})
export class ExamLayoutComponent implements OnInit, AfterViewInit, OnDestroy {
  name: any = '';
  imageUrl: SafeUrl;
  thisurl = location.origin + '/';
  logourl: any;
  subdomain: any;
  loading: boolean = false;
  file: File;
  showlimittext: boolean;
  limittext: string;
  togglepassportui: boolean = false;
  uploadActionText: string = "Upload Passport";
  passportData: PassportData = {
    examno: ""
  };
  canUploadPassport: boolean;
  user: CurrentUser = new CurrentUser();
  webcammode: boolean = false;
  timeRemaining: number | null = null;
  examTimeLeftSubscription: Subscription | null = null;
  examPausedSubscription: Subscription | null = null;
  usecalc: boolean = false
  calcInterval: any;

  @ViewChild('countdown') countdown: CountdownComponent;

  constructor(public userauth: UserauthenticationserviceService,
    public toastr: ToastrService,
    public sanitizer: DomSanitizer,
    public util: Util,
    public proctorservice: ProctoringService,
    public examservice: ExamService,
    private cbtservice: CbtserviceService,
    private router: Router,
    private languageService: LanguageService,
    private calculatorToggleService: CalculatorToggleService) { }
    
  ngOnDestroy(): void {
    this.examTimeLeftSubscription?.unsubscribe();
    this.examPausedSubscription?.unsubscribe();

    if (this.calcInterval) {
      clearInterval(this.calcInterval);
      this.calcInterval = null;
    }
  }

    get language() {
      return this.languageService.language;
    }

  async ngAfterViewInit() {
    //  if (this.candidateVideo && !this.candidateVideo.nativeElement.srcObject) {
    // this.candidateVideo.nativeElement.srcObject =  await this.cbtservice.getVideoStream();
    //  }
    //  if (this.candidateVideoMobile && !this.candidateVideoMobile.nativeElement.srcObject) {
    //   this.candidateVideoMobile.nativeElement.srcObject =  await this.cbtservice.getVideoStream();
    //    }
    var elements: any[] = Array.from(document.getElementsByClassName('candidate-video'))
    await elements.forEach(async element => {
      element.srcObject = await this.cbtservice.getVideoStream();
    });
  }

  async ngOnInit() {
    //debugger;
    this.loading = true;
    try{
      await this.loadUser();
    }
    catch(e){
      this.loading = false;
      throw e;
    }

    if (this.user) {
      this.name = this.user.fullname;
      //this.name = this.userauth.currentUser.fullname;
      //this.userauth.getuserprofileimage(user.username).toPromise().then(data => {
      //  const urlCreator = window.URL;

      //  var imageData = 'data:image/png;base64,' + data.imgdata;
      //  this.imageUrl = this.sanitizer.bypassSecurityTrustUrl(imageData);
      //}).catch(err => this.toastr.error(err))

      // this.imageUrl = `${environment.CBTAPIURL}users/getimagebytes?examno=${this.user.username}&company=${this.user.company}`;
      await this.getUserImageUrl();
      this.examservice.CheckPassportCanUpload(this.user.username)
        .then(result => {
          this.canUploadPassport = result;
        })
        .catch(err => {
          if (err.error.Error) {
            this.toastr.error(err.error.Error);
          } else {
            this.toastr.warning(this.language.unableToConnectToServer);
          }
        })
    }
    else if (this.userauth.getCurrentUserToken()) {
      this.userauth.userLogout();
    }
    this.subdomain = this.util.getSubdomain();
    if (this.subdomain) {
      var data: any = await this.userauth.getsubdomaincompanyinfo(this.subdomain).toPromise().catch(err => { this.cbtservice.showHttpError(err); this.loading = false });
      this.logourl = data.logourl;
    }
    this.loading = false;
    this.examTimeLeftSubscription = this.examservice.examTimeLeft$.subscribe(timeLeft => {
      this.timeRemaining = timeLeft;
    });

    this.examPausedSubscription = this.examservice.examPaused$.subscribe(paused => {
      if (this.countdown) {
        if (paused) {
          this.countdown.pause();
        } else {
          this.countdown.resume();
        }
      }
    });

    this.calcInterval = setInterval(() => {
      this.usecalc = this.util.usecalculator
    }, 1000)

  }

  private async getUserImageUrl() {
    this.imageUrl = this.user.imageversion <= 0 ? "/assets/images/defaultuserimage.jpeg" : await this.examservice.getcandidatepassporturl(this.user.company, this.user.username, this.user.imageversion, this.user.imagetype);
    //debugger
  }

  private async loadUser() {
    this.user = await this.userauth.getCurrentUser(true);
  }

  async raiseHandClicked() {
    await this.proctorservice.raiseHand(this.user.username);
    this.toastr.info(this.language.waitForProctorOk);
  }

  async checkcanuploadpassportstatus(): Promise<boolean> {
    //this.user = await this.userauth.getCurrentUser();
    var d: boolean = await this.examservice.CheckPassportCanUpload(this.user.username).catch(err => {
      if (err.error.Error) {
        this.toastr.error(err.error.Error);
      } else {
        this.toastr.warning(this.language.unableToConnectToServer);
      }
      return false;
    });
    if (d === false) {
      this.togglepassportui = false;
      this.limittext = this.language.passportChangeErr;
      this.showlimittext = true;
      setTimeout(() => { this.canUploadPassport = d }, 5000);
      return false;
    }
    else
      return true;
  }

  async uploadPassport() {
    var canupload: boolean = await this.checkcanuploadpassportstatus();
    if (this.file != null && this.uploadActionText === "Save Passport" && canupload) {
      await this.uploadcandidateimagefile();
    }
    this.toggleUploadUI();
  }

  async uploadcandidateimagefile() {
    this.passportData.examno = this.user.username;
    this.passportData.File = this.file;
    const formData = new FormData();
    formData.append("ExamNo", this.passportData.examno);
    formData.append("File", this.passportData.File);
    //check isface
    // const faceResult = await this.examservice.confirmPassportHasFace(this.file);
    // if (!faceResult.hasface) {
    //   this.toastr.error(`No Face was found in the Passport`);
    //   return;
    // }
    // if (faceResult.hasface  && faceResult.multipleFaces) {
    //   this.toastr.error(`Multiple Face was found in the Passport`);
    //   return;
    // }
    this.examservice.UploadPassport(formData)
      .then(async d => {
        this.toastr.success(this.language.uploadSuccessful);
        //self.location.reload();
        //this.user.imageversion++;//we can assume the image version has increased by 1
        //await this.getUserImageUrl();
        //we are doing thbis to force the component to reload, as passport upload may be what is required to allow the user to start his exam
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/exam/userexams'], { queryParams: { skipMediaTest: true } })
        });
        //window.location.reload();
      })
      .catch(err => {
        if (err.error.Error) {
          this.toastr.error(err.error.Error);
        } else {
          this.toastr.warning(err);
          console.log(err);
        }
      })
  }

  async imagefilereceived(file) {

    $('#webcammodal').modal('hide');
    if (await this.checkcanuploadpassportstatus()) {
      this.file = file
      await this.uploadcandidateimagefile();
    }
  }

  onFileChange(evt): void {
    if (evt.target.files && evt.target.files[0]) {
      if (evt.target.files[0].size > 1000000) {
        this.limittext = this.language.maxFileUpload1mb;
        this.showlimittext = true;
        return;
      }
      this.file = evt.target.files[0];
      const reader = new FileReader();
      reader.onload = e => this.imageUrl = reader.result;
      reader.readAsDataURL(this.file);
    }
    this.showlimittext = false;
  }

  toggleUploadUI() {
    this.togglepassportui = !this.togglepassportui
    this.uploadActionText = this.togglepassportui ? this.language.savePassport : this.language.changePassport;
  }

  isProctorAssigned(): boolean {
    return this.proctorservice.proctorAssigned;
  }
  // examlist() {
  //   window.location.href = `${this.thisurl}exam/userexams`;

  // }

  // logout() {
  //   window.location.href = `${this.thisurl}logout`;+.
  async showwebcammodal() {
    this.webcammode = true
    $('#webcammodal').modal('show');
  }

  isInIframe() {
    return self != top;
  }

  updateImageUrl(e: any) {
    //debugger;
    this.imageUrl = "/assets/images/defaultuserimage.jpeg";
  }

  countDownEventHandler($event: any) {
     if ($event.action == "done") {
      this.examservice.notifyExamTimeElapsed(true);
     }
    }
  
  toggleCalculator() {
   this.calculatorToggleService.toggle();
  }
}
