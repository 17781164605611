import { AfterViewInit, Component, ElementRef, Host, Input, OnChanges, OnDestroy, OnInit, Optional, SimpleChanges, ViewChild } from '@angular/core';
import { Participant } from '../models/Participant';
import { MeetingSettings } from '../models/MeetingSettings';
import { MeetingHandlerService } from '../services/meeting-handler.service';
import { MeetingComponent } from '../meeting/meeting.component';
import { AudioProcessingService } from '../services/audio-processing.service';

@Component({
  selector: 'app-participant-audio',
  templateUrl: './participant-audio.component.html',
  styleUrls: ['./participant-audio.component.scss']
})
export class ParticipantAudioComponent implements OnInit, AfterViewInit, OnDestroy {
  //@ViewChild('audio') audio: ElementRef;
  __participant: Participant;
  audioConsumer;
  audioStream: MediaStream;
  settingStream: boolean = false;
  audio: HTMLAudioElement;
  //playInterval: any;

  constructor(public settings: MeetingSettings, private meetingHandler: MeetingHandlerService, @Host() private meeting: MeetingComponent, private audioProcessingService: AudioProcessingService) { 
    // this.playInterval = setInterval(this.playIntervalHandler.bind(this), 1000);
    this.audio = new Audio();
  }
  
  ngOnDestroy(): void {
    //this.participant.participantAudioComponent = null;
    //clearInterval(this.playInterval);
    this.meeting.participantAudioComponentsMap.delete(this.participant.socketid);
    this.audioProcessingService.endMonitorAudioLevel(this.participant.socketid);
  }

  ngAfterViewInit(): void {
    //this.setStreams();
    //this.participant.participantAudioComponent = this;
    this.setStream();
  }

  ngOnInit(): void {
  }

  @Input()
  set participant(value: Participant){
    this.__participant = value;
    //this.__participant.participantAudioComponent = this;
    if(this.participant && this.participant.socketid){
      this.meeting.participantAudioComponentsMap.set(this.participant.socketid, this);
    }
  }

  get participant(){
    return this.__participant;
  }

  async setStream(){
    //debugger;
    if(!this.settingStream){
      this.settingStream = true;
      try{
        if(this.participant.audioProducerId && (!this.audioConsumer || (this.audioConsumer.producerId != this.participant.audioProducerId))){
          if(this.audioConsumer){
            await this.meetingHandler.closeConsumer(this.participant.socketid, this.audioConsumer);
          }
          console.log('participant changes: ', this.participant);
          const createConsumerInfo = await this.meetingHandler.createConsumer(this.participant.socketid, this.participant.audioProducerId, 'audio', false);
          this.audioConsumer = createConsumerInfo.consumer;
          
          this.audioStream = new MediaStream([this.audioConsumer.track]);
          //if the stream is not attached to an audio player, it won't play at all even when merged in an audio context
          //so we add the stream to a non-playing audio element that isn't even added on the page
          this.audio.srcObject = this.audioStream;
          this.participant.audioAvailable = !createConsumerInfo.data.producerPaused;
          this.audioConsumer.resume();//it should be paused on the server, but resumed here...so that when the producer becomes loud, the server will resume it on the server, and it won't need to send a message here
          //await this.audioProcessingService.monitorAudioLevels(this.participant.socketid, this.audioStream);
          this.audioProcessingService.mergeRemoteAudioStream(this.participant.socketid, this.audioStream, false);
          // const player: any = document.getElementById('audioPlayer');
          // player.srcObject = this.audioStream;
        }
        else if(!this.participant.audioProducerId && this.audioConsumer){
          await this.meetingHandler.closeConsumer(this.participant.socketid, this.audioConsumer);
          this.audioStream = null;
          this.audio.srcObject = null;
          this.participant.audioAvailable = false;
          this.audioProcessingService.endMonitorAudioLevel(this.participant.socketid);
        }
      }
      finally{
        this.settingStream = false;
      }
    }

    
    // const audioTrack = this.audio.nativeElement.srcObject?.getAudioTracks()[0];
    // if(audioTrack){
    //   this.recordingService.mergeAudioTrack(audioTrack);
    // }

  }

  // async monitorAudioLevel(){
  //   if(this.audioStream){
  //     //using an empty participant id because it's for the current user
  //     await this.audioProcessingService.mergeAudioStream(this.participant.socketid, this.audioStream);
  //   }
  //   else{
  //     this.audioProcessingService.endMonitorAudioLevel(this.participant.socketid);
  //   }
  // }

  // playIntervalHandler(){
  //   //on mobile, audio/video may have been stopped because the user left the application and played audio from a different application
  //   if(this.audio?.nativeElement.srcObject && this.audio.nativeElement.paused && !this.audio.nativeElement.ended){
  //     this.audio.nativeElement.play();
  //   }
  // }

}
