import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Util } from '../interceptors/util';
import { CbtserviceService } from '../services/cbtservice.service';
import { UserauthenticationserviceService } from '../services/userauthenticationservice.service';
import { LanguageService } from '../services/language.service';

@Component({
  selector: 'app-login-layout',
  templateUrl: './login-layout.component.html',
  styleUrls: ['./login-layout.component.css']
})
export class LoginLayoutComponent implements OnInit {
  logourl: any;

  constructor(public util:Util,public toastr:ToastrService, public userauthservice:UserauthenticationserviceService, private cbtservice: CbtserviceService,private languageService: LanguageService) { }
  get language(){
    return this.languageService.language;
  }

  async ngOnInit() {
    const subdomain = await this.util.getSubdomain();
    if (subdomain) {
      var data: any = await this.userauthservice.getsubdomaincompanyinfo(subdomain).toPromise().catch(err => { this.toastr.error(err); });
      this.logourl=data? data.logourl:"";
    }
  }

}
