<div class="container-fluid">
  <div class="d-flex align-items-center justify-content-between">
    <div [ngClass]="{'pagination-container': showSubmitButtonOnEveryQuestion, 'pagination-container-full': !showSubmitButtonOnEveryQuestion}">
      <ul #pagination class="pagination mb-0 d-flex">
        <li class="mb-1" *ngFor="let question of questions" [id]="question.questionno"
          [ngClass]="{'active': qno == question.questionno, 'page-item':true}">
          <a href="javascript:void(0)" class="page-link h4"
            [ngClass]="{'badge-answered': question.answered && !question.flagged,'badge-unanswered': !question.answered && !question.flagged ,'badge-flagged':question.flagged, 'badge-custom-label': !!question.questionnolabel }"
            (click)="noclicked(question.questionno)">{{question.questionnolabel ? question.questionnolabel : question.questionno}}</a>
        </li>
      </ul>
    </div>
    <div *ngIf="showSubmitButtonOnEveryQuestion" class="button-container">
      <button type="button" class="btn btn-danger btn-smaller"  (click)="finishclicked()">
        <span class="btn-text">{{language.finishAndSubmit}}</span>
      </button>
    </div>
  </div>
</div>
