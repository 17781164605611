import { query } from '@angular/animations';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RegisterCandidate } from '../models/model';
import { ExamInfo, ExamDetails } from '../models/Exam';
import { CbtserviceService } from './cbtservice.service';
import { QuestionserviceService } from './questionservice.service';
import { EncryptDecryptService } from './encrypt-decrypt.service';
//import { throwError } from 'rxjs';
enum EncryptMode {
  e = "encrypt",
  d = "decrypt"
}
@Injectable({
  providedIn: 'root'
})
export class ExamService {

  readonly rootURL = environment.CBTAPIURL;
  readonly rootS3DBURL = environment.CBTS3APIURL;

  req_headers: HttpHeaders;

  private examTimeLeftSource = new BehaviorSubject<number | null>(null);
  public examTimeLeft$ = this.examTimeLeftSource.asObservable();

  private examPausedSource = new BehaviorSubject<boolean>(false);
  public examPaused$ = this.examPausedSource.asObservable();

  private examTimeElapsedSource = new Subject<boolean>();
  public examTimeElapsed$ = this.examTimeElapsedSource.asObservable();

  constructor(private cbtservice: CbtserviceService, public questionservice: QuestionserviceService, public encryptdecryptservice: EncryptDecryptService) {
    this.req_headers = new HttpHeaders({ "content-type": "application/json", "accept": "application/json", "skip_error_handler_interceptor": "true" });//all the services here should skip the interceptor
  }

  updateExamTime(timeLeft: number | null): void {
    this.examTimeLeftSource.next(timeLeft);
  }

  resetExamTime(): void {
    this.examTimeLeftSource.next(null);
  }

  updateExamPaused(isPaused: boolean): void {
    this.examPausedSource.next(isPaused);
  }

  notifyExamTimeElapsed(elapsed: boolean): void {
    this.examTimeElapsedSource.next(elapsed);
  }

  async getuserexams(candidateno: any): Promise<any> {
    // let attempts = 0;
    // while(true){
    //   attempts++;
    //   try{
    //     return await this.http.get(this.rootURL + `Exam/getuserexams?examno=${candidateno}`, {headers: this.req_headers}).toPromise();
    //   }
    //   catch(error){
    //     if(attempts < environment.maxRequestRetries && (error.status === 0 || (error.error && error.error.message && error.error.message.toUpperCase().includes(environment.seamlessScalingError)))){
    //       //return await this.getuserexams(candidateno);
    //       //probably gateway timeout (when status === 0) or seamless scaling error. retry
    //     }
    //     else{
    //       //this.cbtservice.showHttpError(error);
    //       //this.isloading = false;retrieveexaminfo
    //       throw error;
    //     }
    //   }
    // }
    return await this.cbtservice.tryGet(this.rootS3DBURL + `Exam/getuserexams?examno=${candidateno}`, { headers: this.req_headers });
  }


  async getInterviewInfo(username, name, accessCode): Promise<any> {

    return await this.cbtservice.tryGet(this.rootURL + `Exam/getinterviewinfo?accesscode=${encodeURI(accessCode)}&username=${encodeURI(username)}&name=${encodeURI(name)}&host=false`, { headers: this.req_headers });
  }
  
  async submitfeedback(data: any): Promise<any> {

    return await this.cbtservice.tryPost(this.rootURL + `Exam/submitexamfeedback`, data, { headers: this.req_headers });
  }

  async getfeedbackquestions(): Promise<any> {

    return await this.cbtservice.tryGet(this.rootURL + `Exam/getexamfeedbackquestions`, { headers: this.req_headers });
  }

  async validateExamID(examid: string) {
    return await this.cbtservice.tryGet(this.rootURL + `Exam/validateexam/${examid}`, { headers: this.req_headers });
  }

  async registerExamCandidate(data: any) {
    // registernewexamcandidate

    this.req_headers = new HttpHeaders({
      'Content-Disposition': 'multipart/form-data',
      "skip_error_handler_interceptor": "true"
    });//all the services here should skip the interceptor

    return await this.cbtservice.tryPost(this.rootURL + `Exam/registernewexamcandidate`, data, { headers: this.req_headers });
  }

  async sendVerifyEmail(email: string, examid: number) {
    return await this.cbtservice.tryPost(this.rootURL + `Users/sendverificationemail?email=${encodeURIComponent(email)}&examId=${examid}`, {}, { headers: this.req_headers });
  }
  async verifyEmail(token: string) {
    return await this.cbtservice.tryGet(this.rootURL + `Users/verifyemail?token=${token}`, { headers: this.req_headers });
  }
  async getuserscheduleDates(examid: number): Promise<any> {
    return await this.cbtservice.tryGet(this.rootURL + `Exam/getuserscheduledates?examid=${encodeURIComponent(examid)}`, { headers: this.req_headers });
  }

  async schedulecandidate(data: any): Promise<any> {
    return await this.cbtservice.tryPost(this.rootURL + `Exam/schedulecandidate`, data, { headers: this.req_headers });
  }

  async UploadPassport(data: any) {
    var reqheaders = new HttpHeaders({
      'Content-Disposition': 'multipart/form-data',
      "skip_error_handler_interceptor": "true"
    });//all the services here should skip the interceptor

    return await this.cbtservice.tryPost(this.rootS3DBURL + `Exam/uploadpassport`, data, { headers: reqheaders });
  }

  async CheckPassportCanUpload(examno: string) {
    return await this.cbtservice.tryGet(this.rootS3DBURL + `exam/canuploadpassport?ExamNo=${encodeURI(examno)}`, { headers: this.req_headers });
  }

  async getuserscheduleTimes(examid: number, selecteddate: any): Promise<any> {
    return await this.cbtservice.tryGet(this.rootURL + `Exam/getuserscheduletimes?examid=${examid}&selecteddate=${selecteddate}`, { headers: this.req_headers });
    }
    async getusercomplaintexams(candidateno: any, lastName: any): Promise<any> {
        return await this.cbtservice.tryGet(this.rootURL + `Complaint/getusercomplaintexams?examNo=${candidateno}&lastName=${lastName}`, { headers: this.req_headers });
    }
    async submitComplaint(complaintData: any): Promise<any> {
        return await this.cbtservice.tryPost(this.rootURL + `Complaint/submitcomplaint`, complaintData, { headers: this.req_headers });
    }
    async getcomplaint(complaintID: any, candidateno: any,): Promise<any> {
        return await this.cbtservice.tryGet(this.rootURL + `Complaint/getcomplaint?complaintID=${complaintID}&examNo=${candidateno}`, { headers: this.req_headers });
    }
    async getcomplaintcategory(candidateno: any, scheduleId: number): Promise<any> {
        return await this.cbtservice.tryGet(this.rootURL + `Complaint/getcomplaintcategory?examno=${candidateno}&scheduleId=${scheduleId}`, { headers: this.req_headers });
    }
    async getallusercomplaints(candidateno: any, lastName: any): Promise<any> {
        return await this.cbtservice.tryGet(this.rootURL + `Complaint/getallusercomplaints?examNo=${candidateno}&lastName=${lastName}`, { headers: this.req_headers });
    }

    async submitReply(complaintReplyData: any): Promise<any> {
        return await this.cbtservice.tryPost(this.rootURL + `Complaint/savecomplaintreply`, complaintReplyData, { headers: this.req_headers });
    }

    async closecomplaint(examNo: string,complaintID:string): Promise<any> {
        return await this.cbtservice.tryPost(this.rootURL + `Complaint/closecomplaint?complaintID=${complaintID}&examno=${examNo}`, null, { headers: this.req_headers });
    }

    async selfrestartExam(data: any): Promise<any> {
        return await this.cbtservice.tryPost(this.rootURL + `Exam/candidateselfrestartexam`, data, { headers: this.req_headers });
    }
   
  async getSEBInstructionsURL(scheduleid: number, examid: number) {
    //sebs:cbtapi.webtest.ng/Exam/downloadsebconfig?&company=workforce
    let url = `${this.rootS3DBURL}Exam/getschedulesebinstructionsurl?scheduleid=${scheduleid}&examid=${examid}`;
    let data = await this.cbtservice.tryPost(url, {}, { headers: this.req_headers });
    return data.url_data;
  }





  ///webtest_s3DB API 
  //#region s3DB
  async getInstructions(id: number): Promise<any> {
    return await this.cbtservice.tryGet(this.rootS3DBURL + `Exam/getinstructions?examid=${id}`, { headers: this.req_headers });
  }
  async pingandupdateTimeSpent(scheduleid: any,examid:any): Promise<any> {
    return await this.cbtservice.tryPost(this.rootS3DBURL + `Exam/examinationscheduleping?scheduleid=${scheduleid}&examid=${examid}`, {}, { headers: this.req_headers });
}
  async getexamwithscheduleid(scheduleid: any, examid: any): Promise<any> {
    return await this.cbtservice.tryGet(this.rootS3DBURL + `Exam/getexamwithscheduleid?scheduleid=${scheduleid}&examid=${examid}`, { headers: this.req_headers });
  }

  async aiPreventExamStart(scheduleid: number, examid: any): Promise<any> {
    return await this.cbtservice.tryGet(this.rootS3DBURL + `Exam/aipreventexamstart?scheduleid=${scheduleid}&examid=${examid}`, { headers: this.req_headers });
  }

  async getlastansweredqno(scheduleid: any, essay: boolean, examid: any,attempt:number): Promise<any> {
    return await this.cbtservice.tryGet(this.rootS3DBURL + `Exam/lastqno?scheduleid=${scheduleid}&essay=${essay}&examid=${examid}&attempt=${attempt}`, { headers: this.req_headers });
  }

  async submitexam(scheduleid: any, liteMode: any, examid: any, elapsed: boolean = false, answers: any[] = undefined): Promise<any> {
    if (!answers && liteMode) {
      answers = this.questionservice.getAllQuestionsAnswer().filter(q => !q.saved);
    }
    let data = {
      "answerdetails": answers,
      "scheduleId": scheduleid,
      "elapsed": elapsed,
      "liteMode": liteMode,
      "examid": examid
    }
    let encryptedata = JSON.stringify(this.encryptdecryptservice.decryptEncrpyt(JSON.stringify(data), EncryptMode.e));

    return await this.cbtservice.tryPost(this.rootS3DBURL + `question/saveallquestionsanwser?scheduleid=${scheduleid}&elapsed=${elapsed}&examid=${examid}`, encryptedata, { headers: this.req_headers });
  }


  async getexamstatus(scheduleid: any, elapsed: boolean, examid: any): Promise<any> {
    //debugger;
    return await this.cbtservice.tryGet(this.rootS3DBURL + `Exam/getexamstatus?scheduleid=${scheduleid}&elapsed=${elapsed}&examid=${examid}`, { headers: this.req_headers });
  }

  async setfocuslosscounter(scheduleid: any, examid: any): Promise<any> {
    return await this.cbtservice.tryGet(this.rootS3DBURL + `Exam/setcounter?scheduleid=${scheduleid}&examid=${examid}`, { headers: this.req_headers });
  }

  async performfocuslossaction(scheduleid: any, examid): Promise<any> {
    return await this.cbtservice.tryGet(this.rootS3DBURL + `Exam/performfocuslossaction?scheduleid=${scheduleid}&examid=${examid}`, { headers: this.req_headers });
  }

  async getexampausestatusandtimeleftschedule(scheduleid: number, liteMode: any,examid:any): Promise<any> {
    if (liteMode) {
      return {
        "tleft": "",
        "status": "In Progress",
        "adminpaused": false,
        "proctorpaused": false,
        "reason": ""
      }
    }
    return await this.cbtservice.tryGet(this.rootS3DBURL + `Exam/getexampausestatusandtimeleftschedule?scheduleid=${scheduleid}&examid=${examid}`, { headers: this.req_headers });
  }
  //#endregion




  //AI
  async getcandidatepassporturl(company: string, username, imageversion, imagetype) {
    let url = `${environment.s3passportbucketurl}${company.toLowerCase()}/passports/${username}_${imageversion}.${imagetype}`;
    //check that passport exists
    // await this.http.get(url, { headers: this.req_headers }).toPromise().catch((err) => {
    //debugger;
    //   //if (err.status === 400) { return "/assets/images/defaultuserimage.jpeg"; }
    //   //removed this because the xhr request to get the passport photo fails as a result of cors anyway. so we won't really be able to tell if the photo exists or not
    //   //this means (obviously) that whenever we delete photos, we have to remember to set the image version of the candidate
    // });
    return url;
  }

  async confirmPassportHasFace(imgData: File) {
    const imgDataURL = await this.imageToBase64String(imgData);
    const ret = await this.cbtservice.tryPost(`${environment.aiServerUrl}checkface`, { imgDataURL }, { headers: this.req_headers });
    return ret;
  }

  private imageToBase64String(data: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onloadend = () => {
        if (reader.readyState === FileReader.DONE) {
          if (reader.error) {
            console.error("Error reading file:", reader.error);
            reject(reader.error);
          } else {
            resolve(reader.result as string);
          }
        }
      };
    });
  }


}
