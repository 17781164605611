import { ILanguage } from "./ILanguage";

export class English implements ILanguage {
    shortAnswerQuestion: string= "Short Answer Question";
    systemCheck: string = "Run System Check";
    desktop: string = "Desktop";
    mobile: string = "Mobile";
    unknown: string = "Unknown";
    selectLanguage: string = 'Select Language';
    languageName = 'English';
    submitConfirmationMessage: string = 'Are you sure you want to submit? This will end the Exam';
    backToLogin: string = 'Back to Login';
    allComplaints: string = 'All Complaints';
    complaintID: string = 'Complaint ID';
    examNo: string = 'Exam Number';
    lastName: string = 'Last Name';
    firstName: string = 'First Name';
    status: string = 'Status';
    submittedDate: string = 'Submitted Date';
    noComplaint: string = 'No complaint found';
    complaint: string = 'Complaint';
    selectExamComplaint: string = 'Please select the Examination you intend to make a complaint about.';
    natureOfComplaint: string = 'Please select the nature of your complaint.';
    closeComplaints: string = 'Close Complaint';
    closeReply: string = 'Close Reply';
    replyComplaint: string = 'Reply Complaint';
    reply: string = 'Reply';
    enterResponseDetails: string = 'Enter the details of your Response';
    submit: string = 'Submit';
    supportDiscussion: string = 'Support Discussion';
    admin: string = 'Admin';
    you: string = 'You';
    complaintsClosed: string = 'Complaints Closed';
    replySent: string = 'Reply Sent';
    candidateInformation: string = 'Candidate Information';
    viewAllComplaints: string = 'View All Complaints';
    examinationSelection: string = 'Examination Selection';
    complaintsDisabled: string = 'No examination found or Complaints have been disabled for this exam';
    complaintCategory: string = 'Complaint Category';
    selectOption: string = 'Select Option';
    complaintDetails: string = 'Complaint Details';
    solvedYourIssue: string = 'Has the above process solved your issue';
    yes: string = 'Yes';
    no: string = 'No';
    enterComplaintDetails: string = 'Enter the details of your complaint';
    previous: string = 'Previous';
    cancel: string = 'Cancel';
    next: string = 'Next';
    limitOf: string = 'Limit of';
    wordsMaximum: string = 'words maximum';
    welcome: string = 'Welcome';
    logout: string = 'Logout';
    passportSection: string = 'PASSPORT SECTION';
    maxFileUpload1mb: string = 'Max. File Upload is 1mb';
    takePicture: string = 'Take Picture';
    clearFaceInstruction: string = 'Please ensure your face is clearly visible at all times.';
    brightLightInstruction: string = 'Please ensure there are no bright lights or windows behind you.';
    requestAttention: string = 'Request Attention';
    uploadPassport: string = 'Upload Passport';
    unableToConnectToServer: string = 'Unable to connect to server';
    waitForProctorOk: string = 'Please wait for the proctor to contact you';
    passportChangeErr: string = "Passport cannot be changed after exam starts"
    uploadSuccessful: string = 'Upload Successful';
    savePassport: string = 'Save Passport';
    changePassport: string = 'Change Passport';
    examinationFeedBack: string = 'Examination FeedBack';
    happy: string = 'Happy';
    neutral: string = 'Neutral';
    unhappy: string = 'Unhappy';
    close: string = 'Close';
    problemCategories: string = 'Problem categories';
    othersProblemCategories: string = 'Others (Kindly provide details)';
    login: string = 'Login';
    username: string = 'Username';
    password: string = 'Password';
    makeComplaint: string = 'Make Complaint';
    loggedOut: string = 'You are logged out';
    userMis_match: string = 'User Mis-match. Unauthorized Access';
    loginToTakeExam: string = 'Login to take Exam';
    aboutUs: string = 'About Us';
    // allComplaints:string = 'meedsting components';
    error: string = 'Error';
    somethingWentWrong: string = 'Oops! Something went wrong.';
    pleaseConatctAdministrator: string = 'Please conatct the administrator';
    instructions: string = 'Instructions';
    question: string = 'Question';
    pleaseHold: string = 'PLEASE HOLD';
    examHasBeenPaused: string = 'YOUR EXAMINATION HAS BEEN PAUSED';
    reason: string = 'REASON';
    willNotLoseTime: string = 'DURING THIS PERIOD, YOU WILL NOT LOSE ANY TIME AS YOUR EXAMINATION TIMER HAS BEEN PAUSED';
    userSessionNotFound: string = 'User session not found';
    networkConnectionLost: string = 'Network connection lost. Attempting to reconnect';
    multipleLoginsDetected: string = 'Multiple Logins Detected';
    unauthorizedAccess: string = 'Unauthorized Access';
    penalizedWarning: string = 'You are not allowed to leave the exam page. Doing so results in being penalized';
    youHaveLost: string = 'You have lost';
    leavingExamPage: string = 'minutes as the penalty for leaving the exam page';
    finishAndSubmit: string = 'Finish/Submit';
    questionHasBeen: string = 'Question has been';
    verifyingEmail: string = 'Verifying Email';
    registration: string = 'Registration';
    emailAddress: string = 'Email Address';
    verifyEmailAddress: string = 'Verify Email Address';
    otherNames: string = 'Other Names';
    phoneNumber: string = 'Phone Number';
    gender: string = 'Gender';
    selectGender: string = 'Select Gender';
    male: string = 'Male';
    female: string = 'Female';
    scheduleDate: string = 'Schedule Date';
    scheduleTime: string = 'Schedule Time';
    selectTime: string = 'Select Time';
    noAvailableTime: string = 'No availabe time';
    noAvailableDate: string = 'No availabe Date';
    register: string = 'Register';
    registrationSuccessful: string = 'Registration Successful';
    yourRegistrationFor: string = 'Your registration for';
    wasSuccessful: string = 'was successful.';
    yourExaminationNumberIs: string = 'Your Examination Number is';
    checkYourEmailForMoreInformation: string = 'Check your email for more information on the examination.';
    imageSizeIsGreaterthan1mb: string = 'Image size is to greater than 1MB.';
    invalidImageFormat: string = 'Invalid image format';
    pleaseSelectGender: string = 'Please select gender';
    pleaseFillExaminationSchedule: string = 'Please fill Examination Schedule details';
    pleaseUploadPassportPicture: string = 'Please upload passport picture';
    registrationComplete: string = 'Registration Complete';
    verificationEmailSent: string = 'A Verification Email Has been sent to ';
    invalidEmail: string = 'Invalid Email';
    emailVerified: string = 'Email Verified';
    invalidCode: string = 'Invalid code';
    unavailableDateSelected: string = 'Unavailable Date Selected';
    examPlatformUsageInst: string = 'EXAM PLATFORM USAGE INSTRUCTIONS';
    useSafeExamBrowser: string = 'To take this exam, you will be required to use the Safe Exam Browser (SEB) on a computer. To use it, please follow the instructions below.';
    installSafeExamBrowser: string = "If you haven't already installed it, please use the appropriate link below to download it.  If you already have it installed, ";
    ensureCurrentSEBVersionIsInstalled: string = "please ensure you have the current version.";
    sebUsageInstructionsSkipStep3: string = " If you already have the current version installed, you can skip to step 3.";
    sebDownloadLinkForWindows: string = 'Secure Exam Browser download for Windows';
    sebDownloadLinkForMac: string = 'Secure Exam Browser download for Mac';
    installTheSoftware: string = 'Once downloaded, install the software';
    onceSEBIsInstalled: string = 'Once SEB is installed, click ';
    here: string = 'here';
    downloadSEBexamfileforyourexam: string = 'to download the SEB exam file for your exam.';
    youshouldseefile: string = 'You should see a file that looks like this';
    double_clickdownloadedfile: string = 'To start your exam, double-click the downloaded file.';
    confirmInstallSafeExamBrowser: string = "Note: If the SEB exam file doesn't work, please confirm that you have properly installed SEB, and you are using the correct file downloaded in the link above.";
    unabletoconnecttoserver: string = 'Unable to connect to server';
    connectionError: string = 'Connection Error';
    unknownserversideerror: string = 'Unknown server side error';
    youhavebeenpreventedfromstarting: string = 'You have been prevented from starting the exam because';
    connectiontomediaserverlost: string = 'Connection to media server lost. Please reconnect';
    verificationError: string = 'Verification Error occured on your system. If this issue persists, please refresh the page or restart your browser';
    unexpectedCondition: string = 'Unexpected Condition. markanswerasync exception';
    loading: string = 'Loading';
    submitExamination: string = 'SUBMIT EXAMINATION';

    submitting: string = 'Submitting';
    yourScore: string = ' Your Score is';
    startNewExam: string = 'Start a new Exam';
    takeExam: string = 'Take Exam';
    join: string = 'Join';
    minutes: string = 'minutes';
    start: string = 'Start';
    continue: string = 'Continue';
    scheduleExam: string = 'Schedule Exam';
    pleaseWait: string = 'Please Wait';
    finished: string = 'Finished';
    timeElapsed: string = 'Time Elapsed';
    disqualified: string = 'Disqualified';
    stopped: string = 'Stopped';
    rescheduleExam: string = 'Reschedule Exam';
    webtestInstruction: string = 'USAGE INSTRUCTIONS';
    agreeToInstructions: string = 'I agree that I have read and understood the above instructions';
    startExam: string = 'Start Exam';
    examSchedule: string = 'Exam Schedule';
    selectDate: string = 'Select Date';
    schedule: string = 'Schedule';
    examScheduled: string = 'Exam Scheduled. Please Check your mail';
    examinationList: string = 'Examination List';
    scheduledExaminations: string = 'Scheduled Examinations';
    ensureyourfacefillsthecircle: string = 'Please ensure your face fills up most of the circle';
    capturePhoto: string = 'Capture Photo';
    retake: string = 'Retake';
    upload: string = 'UPLOAD';
    youhavenooutputvideodevice: string = 'You have no output video device';
    errorloadingcustomcssfile: string = 'Error loading custom css file';
    noExamsFound: string = 'No exams found';
    multipleChoice: string = "Multiple-Choice";
    interview: string = "Interview";
    essay: string = "Essay";
    flagged: string = "Flagged";
    unflagged: string = "Unflagged";
    duplicateCameratyprDetected: string = "Multiple camera type detected";
    errorCheckingDuplicateCameras: string = "Error checking for duplicate cameras";
    itRecommendedUseDesktopDevice: string = "It is recommended to use a desktop device for this proctoring system.";
    virtualMachineDetected: string = "Virtual machine detected and running.";
    noWorkingCamerafound: string = "No working camera found.";
    failedToAccessCamera: string = "Failed to access camera:";
    noWorkingMicrophoneFound: string = "No working microphone found.";
    failedToAccessMicrophone: string = "Failed to access microphone:";
    failedToPlaySound: string = "Failed to play sound:";
    failedToEnumerateDevices: string = "Failed to enumerate devices: ";
    developerToolsAreOpen: string = "Developer tools are open";
    available: string = "Available";
    notAvailable: string = "Not Available";
    proctoringSystemCheck: string = "Proctoring System Check";
    camera: string = "Camera";
    speaker: string = "Speaker";
    microphone: string = "Microphone";
    recording: string = "Recording";
    osVersion: string = "OS Version";
    browserVersion: string = " Browser Version";
    deviceType: string = "Device Type";
    virtualMachine: string = " Virtual Machine";
    safeExamBrowser: string = "Safe Exam Browser(SEB)";
    runCheck: string = "Run Check";
    mediaChecks: string = "Media Checks";
    cameraCheck: string = "Camera Check";
    microphoneCheck: string = "Microphone Check";
    speakerCheck: string = "Speaker Check";
    playTestSound: string = "Play Test Sound";
    playingTestSound: string = "Playing test sound";
    unknownBrowser: string = "Unknown Browser";
    unknownVersion: string = "Unknown Version";
    notUsed: string = "Not Used";
    used: string = "Used";
    detected: string = "Detected";
    notDetected: string = "Not detected";
    canHearBeepSound: string = "Did you hear the sound";
    canSeeSelf: string = "Can you see yourself clearly";
    audioNotAvailablePlease:string="Audio is not available. Please check the volume or try again.";
    cameraNotAvailablePlease:string="Camera is not available. Please check the settings or try again.";
    retakeExam: string = "Retake Exam";
    reasonForRetake: string= "Reason for Retake";
    sebMinimumOSVersionWindows: string = "Windows 8.1 or later required";
    sebMinimumOSVersionMac: string = "Mac OS Ventura or later required";
    abbreviatedQuestionNo: string = "Q.No";
    toggleCalculator: string = "Toggle Calculator";
}