<div>
    <div>
        <div class="col-md-12 mx-auto text-center">
            <h1> {{language.examPlatformUsageInst}}</h1>
            <!-- Tabs with icons on Card -->
        </div>
        <div>
                <div>{{language.useSafeExamBrowser}}</div>
                <p></p>
                <ol>
                    <li>{{language.installSafeExamBrowser}}  <b><em>{{language.ensureCurrentSEBVersionIsInstalled}}</em></b>. {{language.sebUsageInstructionsSkipStep3}}
                        <ul>
                            <li><a href="{{this.sebDownloadLinkForWindows}}"> {{language.sebDownloadLinkForWindows}} v({{this.sebCurrentWindowsVersion}})</a>. ({{language.sebMinimumOSVersionWindows}})</li>
                            <li><a href="{{this.sebDownloadLinkForMac}}"> {{language.sebDownloadLinkForMac}} v({{this.setCurrentMacVersion}})</a>. ({{language.sebMinimumOSVersionMac}})</li>
                            <!-- <li><a href="/assets/downloads/SEB.dmg">MAC OS</a></li> -->
                        </ul>
                    </li>
                    <li>{{language.installTheSoftware}}</li>
                    <li>{{language.onceSEBIsInstalled}}<a [href]="this.getSEBConfigUrl()" download><b>{{language.here}}</b></a> {{language.downloadSEBexamfileforyourexam}}
                         {{language.youshouldseefile}}:
                        <div style="margin-top: 5px;">
                            <img src="/assets/images/SEB_5.png" alt="SEB download example" />
                        </div>
                    </li>
                    <li>{{language.double_clickdownloadedfile}}</li>
                </ol>
               {{language.confirmInstallSafeExamBrowser}}
                
                <!-- <div class="nav-tabs-navigation">
                    <div class="nav-tabs-wrapper">
                        <ngb-tabset [justify]="'center'">
                            <ngb-tab title="DOWNLOAD AND INSTALL SAFE EXAM BROWSER">
                                <ng-template ngbTabContent>
                                    <div class="card card-nav-tabs">
                                        <div class="card-header card-header-primary">
                                            <div class="nav-tabs-navigation">
                                                <div class="nav-tabs-wrapper">
                                                    <ul class="nav nav-tabs" data-tabs="tabs">
                                                        <li class="nav-item">
                                                            <a class="nav-link" href="#wprofile" data-toggle="tab">
                                                                WINDOWS
                                                            </a>
                                                        </li>
                                                        <li class="nav-item">
                                                            <a class="nav-link" href="#macprofile" data-toggle="tab">
                                                                MAC OS <div class="ripple-container"></div>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="card-body ">
                                                    <div class="tab-content text-center">
                                                        <div class="tab-pane" id="wprofile">
                                                            <div class="bs-docs-section">

                                                                <h3 class="page-header sebnavbar-link" id="download"><a
                                                                        class="anchorjs-link " href="#download"
                                                                        aria-label="Anchor link for: download"
                                                                        data-anchorjs-icon=""
                                                                        style="font-family: anchorjs-icons; font-style: normal; font-variant: normal; font-weight: normal; position: absolute; margin-left: -1em; padding-right: 0.5em;"></a>
                                                                    <a class="anchorjs-link" href="#download"></a>
                                                                    User Manual
                                                                </h3>

                                                                <p>You can download the setup program of SEB for Windows
                                                                    (also containing the SEB Windows configuration tool)
                                                                    <a
                                                                        href="https://safeexambrowser.org/download_en.html#Windows"><b>on
                                                                            the download page.</b></a>
                                                                    <br />
                                                                <ul>
                                                                    <li>Click on Safe Exam Browser 3.4.1 for Windows
                                                                        just below the Windows</li>
                                                                    <img src="/assets/images/SEB_1.png"
                                                                        style="width: 30vw;" alt="SEB_1">
                                                                    <li>Click on the file downloaded on your system</li>
                                                                    <img src="/assets/images/SEB_2.png"
                                                                        style="width: 30vw;" alt="SEB_2">

                                                                    <li>Click the checkbox "I agree to the license terms
                                                                        and conditions" and click Install</li>
                                                                    <img src="/assets/images/SEB_3.png"
                                                                        style="width: 30vw;" alt="SEB_3">


                                                                    <li>After installing the browser click on the
                                                                        <strong>Exam Configuration File button</strong>
                                                                        on the <strong>"Download Examination
                                                                            Configuration File" Tab, Open the file after
                                                                            downloading to start your exam.</strong>
                                                                    </li>
                                                                    <img src="/assets/images/SEB_4.png"
                                                                        style="width: 30vw;" alt="SEB_4">

                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div class="tab-pane" id="macprofile">
                                                            <div class="bs-docs-section">

                                                                <h3 class="page-header sebnavbar-link" id="download"><a
                                                                        class="anchorjs-link " href="#download"
                                                                        aria-label="Anchor link for: download"
                                                                        data-anchorjs-icon=""
                                                                        style="font-family: anchorjs-icons; font-style: normal; font-variant: normal; font-weight: normal; position: absolute; margin-left: -1em; padding-right: 0.5em;"></a>
                                                                    <a class="anchorjs-link" href="#download"></a>
                                                                    User Manual
                                                                </h3>

                                                                <p>You can download the setup program of SEB for macOS
                                                                    (also containing the SEB macOS configuration tool)
                                                                    <a
                                                                        href="https://safeexambrowser.org/download_en.html#MacOSX">on
                                                                        our download page.</a>
                                                                    <br />
                                                                <ul>
                                                                    <li>Click on Safe Exam Browser 3.4.1 for macOS just
                                                                        below the macOS</li>

                                                                    <li>Click on the file downloaded on your system</li>


                                                                    <li>Click the checkbox "I agree to the license terms
                                                                        and conditions" and click Install</li>



                                                                    <li>After installing the browser click on the
                                                                        <strong>Exam Configuration File button</strong>
                                                                        on the <strong>"Download Examination
                                                                            Configuration File" Tab,Open the file after
                                                                            downloading to start your exam</strong>
                                                                    </li>
                                                                    <img src="/assets/images/SEB_4.png"
                                                                        style="width: 30vw;" alt="SEB_4">
                                                                </ul>

                                                                <h2>NOTE: For proctored examinations, Mac-OS is
                                                                    incompatible</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </ngb-tab>
                            <ngb-tab title="DOWNLOAD EXAMINATION CONFIGURATION FILE">
                                <ng-template ngbTabContent>
                                    <div class="card">
                                        <div class="card-body card-header-primary">
                                            <button type="button" [disabled]="isLoading" (click)="downloadsebconfig()"
                                                class="btn btn-sm  btn-primary statusbtn">
                                                EXAM CONFIGURATION FILE
                                            </button><span class="" *ngIf="isLoading"><img style="height:30px;"
                                                    src="../../../assets/img/spinner.gif" /></span>
                                        </div>
                                    </div>
                                </ng-template>
                            </ngb-tab>
                        </ngb-tabset>
                    </div>
                </div> -->
        </div>
    </div>
</div>