<div class="row justify-content-center login-container">

    <div class="col-lg-12 login-form-right">

        <div class="container">
            <h1 class="title text-center welcome-users">{{language.complaint}}</h1>
            <div class="alert center" style="padding: 0.75em 0; text-align: center;">
                <p class="font-weight-bold" *ngIf="isLoading" style="color:white; background-color: cornflowerblue;">
                    <span><img style="height:40px;" src="/assets/img/spinner.gif" /></span>
                </p>
            </div>
            <div class="progress">
                <div class="progress-bar
                            progress-bar-striped bg-purple" role="progressbar" style="width: 0%" aria-valuenow="0"
                    aria-valuemin="0" aria-valuemax="100"></div>
            </div>
            <form name="complaint" style="border: 0px !important;">

                <!-- Candidate Info -->
                <div class="step active">
                    <p class="text-center mb-4">  {{language.candidateInformation}}</p>
                    <div class="mb-3">
                        <input type="text" [placeholder]="language.examNo" name="examNo" [(ngModel)]="examNo">
                    </div>
                    <div class="mb-3">
                        <input type="text" [placeholder]="language.lastName" name="lastName" [(ngModel)]="lastName">
                    </div>
                </div>

                <!-- Examination Selection -->
                <div class="step">
                    <h5 class="text-center"><a class="complaint" [routerLink]="'/complaints/'+examNo+'/'+lastName"> {{language.viewAllComplaints}}</a></h5>
                    <p class="text-center mb-4">  {{language.examinationSelection}}</p>
                    <div class="row mb-2">

                        <div class="col-sm-6">
                            <label> {{language.examNo}}</label>
                            <p class="border" name="examNo">{{examNo}}</p>

                        </div>
                        <div class="col-sm-6">
                            <label for=""> {{language.lastName}}</label>
                            <p class="border" name="lastName">{{lastName}}</p>

                        </div>
                    </div>
                    <div class="mb-3">
                        <h3> {{language.selectExamComplaint}}</h3>
                        <div class="form-check mb-1" *ngFor="let exam of exams">
                            <label class="form-check-label text-muted cursor-pointer" [for]="'examination' + exam.id" (click)="SelectExamiantion(exam)">

                            <input class="form-check-input" type="radio" name="examination"
                                [id]="'examination' + exam.examid"
                                [checked]="exam.examid == selectedExaminationSch?.examid">
                                {{exam.name}} | <span>{{exam.status }}</span> | <span>{{
                                    this.util.formatDate(exam.examdate) }}</span>
                            </label>
                            <hr>
                        </div>
                        <p *ngIf="exams?.length<=0"> {{language.complaintsDisabled}}</p>
                    </div>
                </div>

                <div class="step">
                    <p class="text-center mb-4"> {{language.complaintCategory}}</p>
                    <div class="mb-3">
                        <h3> {{language.natureOfComplaint}}</h3>
                        <select id="" name="complaintCateory" class="form-control form-select form-select-lg"
                            (change)="setCategory($event)" name="complaintCateory" [(ngModel)]="complaintCateoryNumber">
                            <option value="" disabled selected> {{language.selectOption}}</option>
                            <option *ngFor="let cateory of Cateories" [value]="cateory.categoryID">{{cateory.categoryText}}</option>
                        </select>
                    </div>
                </div>

                <!-- Complaint -->
                <div class="step">
                    <p class="text-center mb-4">  {{language.complaintDetails}}</p>
                    <div class="mb-3" *ngIf="complaintCateory">
                        <div [innerHtml]="complaintCateory.helpText">

                        </div>
                    </div>

                    <div class="mb-3">
                        <div class="mb-1">
                            <h3 class="qustion"> {{language.solvedYourIssue}}</h3>

                            <a class="btn btn-secondary" routerLink="/login"> {{language.yes}}</a>

                            <button class="btn btn-primary" (click)="changeAdditional()"> {{language.no}}</button>

                            <textarea class="form-control mt-5" [placeholder]="language.enterComplaintDetails" rows="10"
                                *ngIf="isAdditional" autocomplete id="response" [(ngModel)]="response"
                                name="response" id="response"></textarea>
                        </div>
                    </div>
                </div>

                <div class="form-footer d-flex">
                    <button type="button" id="prevBtn" class="mr-2" [disabled]="currentTab<=0"
                        (click)="nextPrev(-1)"> {{language.previous}}</button>
                    <a class="btn mr-2" routerLink="/login" id="cancelBtn" > {{language.cancel}}</a>

                    <button type="button" id="nextBtn" (click)="nextPrev(1)" [disabled]="lastBtn && !response"> {{language.next}}</button>
                </div>
            </form>

        </div>
    </div>

 
</div>