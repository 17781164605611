import { 
  Component, 
  ElementRef, 
  Input, 
  Output, 
  EventEmitter, 
  OnInit, 
  OnDestroy, 
  ViewChild, 
  OnChanges,
  SimpleChanges, 
  AfterViewInit
} from '@angular/core';
import Quill from 'quill';
import QuillBetterTable from 'quill-better-table';

Quill.register({
  'modules/better-table': QuillBetterTable
}, true);

@Component({
  selector: 'app-quill-editor',
  templateUrl: './quill-editor.component.html',
  styleUrls: ['./quill-editor.component.css']
})
export class QuillEditorComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
 
  @ViewChild('quillContainer', { static: true }) quillContainer!: ElementRef;

  @Input() value: string = "";
  @Output() valueChange = new EventEmitter<[string, string]>();
  @Output() editorCreated = new EventEmitter();

  @Input() placeholder: string = '';
  @Input() readOnly: boolean = false;
  @Input() maxWordCountHighLight: boolean = true;
  @Input() maxWordCount: number = 0;

  private quill!: Quill;
  private tableModule: any;

  modules = {
    table: false,
    'better-table': {
      operationMenu: {
        items: {
          unmergeCells: {
            text: 'Another unmerge cells name'
          }
        },
        color: {
          colors: ['green', 'red', 'yellow', 'blue', 'white'],
          text: 'Background Colors:'
        }
      }
    },
    keyboard: {
      bindings: QuillBetterTable.keyboardBindings
    },
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],
      
      //[{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
      [{ 'direction': 'rtl' }],                         // text direction
      
      //[{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      
      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],
      
      ['link', 'image'/*, 'video', 'embed'*/],
      
      ['clean'],                                         // remove formatting button
      ['table'], 
      // ['save']
    ]
  };

  ngAfterViewInit(): void {
    this.editorCreated.emit();
  }

  insertFixedTable() {
    if (this.tableModule) {
      this.tableModule.insertTable(3, 3);
    }
  }

  ngOnInit() {
    this.initQuill();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['value'] && this.quill) {
      const currentContent = this.quill.root.innerHTML;
      if (changes['value'].currentValue !== currentContent) {
        this.setValue(changes['value'].currentValue || '');
      }
    }

    if (!changes['value'] && this.quill) {
      this.setValue('');
    }
  }

  ngOnDestroy() {
    if (this.quill) {
      this.quill.off('text-change');
    }
  }

  private initQuill() {
    this.quill = new Quill(this.quillContainer.nativeElement, {
      theme: 'snow',
      placeholder: this.placeholder,
      readOnly: this.readOnly,
      modules: this.modules
    });

    this.quill.scrollingContainer = document.getElementById('question-container') ?? this.quill.scrollingContainer;

    // Get the better-table module
    this.tableModule = this.quill.getModule('better-table');

    const toolbar = this.quill.getModule('toolbar');
    toolbar.addHandler('table', () => {
      this.insertFixedTable();
    });

    if (this.value) {
      this.setValue(this.value);
    }

    // Handle text changes
    this.quill.on('text-change', (delta, oldDelta, source) => {
     
      if (source === 'user') {
        const html = this.quill.root.innerHTML;
        const plainText = this.quill.getText().trim();

        this.valueChange.emit([html, plainText]);

        //this.quill.scrollIntoView();

        if (this.maxWordCountHighLight) {
          const length = this.getWordCount(plainText);
          const highLight = length[0] > this.maxWordCount && this.maxWordCount > 0;
          
          // // Reset text color
          // this.quill.formatText(0, length[1], {
          //   'color': 'rgb(0,0,0)'
          // });

          // Highlight excess text
          if (highLight) {
            this.quill.formatText(length[1], length[2], {
              'color': 'rgb(220, 20, 60)'
            });
          }
        }
      }
    });
  }

  setValue(value: string) {
    if (this.quill) {
      const currentContent = this.quill.root.innerHTML;
      if (value !== currentContent) {
        this.quill.root.innerHTML = value || '';
      }
    }
  }

  getWordCount(text: string): [number, number | [], number] {
    let words = text.split(/\s+/);
    let wordlength = words.length;
    let allowedwords = this.maxWordCount > 0 ? text.split(/\s+/, this.maxWordCount) : words;
    let allowedxters = this.maxWordCount > 0 ? allowedwords.join().length : text.length;
    return !text ? [0, [], 0] : [wordlength, allowedxters, text.length]
  }
}
