import { Component, EventEmitter, Host, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core'
import { QuestionserviceService } from '../services/questionservice.service';
import { ExamService } from '../services/exam.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { CbtserviceService } from '../services/cbtservice.service';
import { LanguageService } from '../services/language.service';
import { ScheduleQuestionAndOptions } from '../models/Exam';
import { EncryptDecryptService } from '../services/encrypt-decrypt.service';
import { Subject } from 'rxjs';
import { QuillEditorComponent } from '../quill-editor/quill-editor.component';

declare const $: any;

@Component({
  selector: 'app-essay',
  templateUrl: './essay.component.html',
  styles: ['./essay.component.css']
})
export class EssayComponent implements OnChanges, OnDestroy {
//[essayanswer]="question.essayAnswer" [scheduleid]="question.examQuestionSchedule.scheduleid" [schedulequestionid]="question.examQuestionSchedule.schedulequestionid" [maxwordcount]="question.maxwordcount"

  // @Input() essayanswer: any = "<p><b>Something </b>fishy is going on</p>"
  @Input() question: ScheduleQuestionAndOptions;
  @Input() examid: number;
  essayanswer: string;
  quillAnswerHtml: string;
  blurred = false
  focused = false;
  @ViewChild('answer') editor: QuillEditorComponent;
  previousAnswerHtml: string;
  maxwordcount: number = 0;
  scheduleid: number;
  schedulequestionid: number;
  thisurl = location.origin + '/';
  @Output() updatedanswer: EventEmitter<string> = new EventEmitter();
  currentwordcount: number = 0;
  wordcountdisplay: string;
  higlight: boolean;
  previousQuestion: ScheduleQuestionAndOptions;
  destroyed = false;

  @Input() loadingSubject: Subject<boolean>;
  @Input() networkFailureSubject: Subject<boolean>;


  //answerUploadUrl: string;
  constructor(public questionservice: QuestionserviceService, public examservice: ExamService, public toastr: ToastrService, public router: Router, private cbtservice: CbtserviceService, private languageService: LanguageService, private encryptdecryptservice: EncryptDecryptService) { }
  
  async ngOnDestroy(): Promise<void> {
    this.destroyed = true;
    await this.saveData(null);
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    //here, we need to know when we are switching from one question to another so that we save the data before displaying the next question
    //save the previous html before we set a new answer
    if(this.question?.examQuestion?.schedulequestionid != this.previousQuestion?.examQuestion?.schedulequestionid){
      //debugger;
      try{
        this.loadingSubject.next(true);
      //if the question has changed, save the html in the editor to the html of the previous question
        if(this.previousQuestion){
          //var data = this.constructAnswerSaveData(this.quill.root.innerHTML);

          //this.questionservice.saveEssayAnswerToStorage(this.schedulequestionid, this.quill.root.innerHTML);
          
          while(true){
            try{
              await this.saveData(null);
              //this.networkFailureSubject.next(false);
              break;
            }
            catch(error){
              console.error(error);
              //this.networkFailureSubject.next(true);
              await this.cbtservice.sleep(1000);
            }
          }
          //this.previousQuestion.essayAnswer = this.quill.root.innerHTML;
          
          //save that question to storage
        }
        if(this.question){
          this.previousQuestion = this.question;
          
          this.scheduleid = this.question.examination.scheduleid;
          this.schedulequestionid = this.question.examQuestion.schedulequestionid;
          this.maxwordcount = this.question.examQuestion.maxwordcount;
          const answerHtml = this.questionservice.getEssayAnswerFromStorage(this.schedulequestionid);
          this.previousAnswerHtml = answerHtml;
          this.essayanswer = answerHtml;
          this.quillAnswerHtml = answerHtml;
        } else {
          this.essayanswer = '';
          this.quillAnswerHtml = '';
          this.previousAnswerHtml = '';
        }
      }
      finally{
        // if(!environment.production){
        //   //for testing
        //   await this.cbtservice.sleep(1000);
        // }
        this.loadingSubject.next(false);
      }
    }
    
  }

  get language() {
    return this.languageService.language;
  }

  async ngOnInit() {
  }

  getWordCount(text: string): [number, number | [], number] {
    let words = text.split(/\s+/);
    let wordlength = words.length;
    let allowedwords = this.maxwordcount > 0 ? text.split(/\s+/, this.maxwordcount) : words;
    let allowedxters = this.maxwordcount > 0 ? allowedwords.join().length : text.length;
    return !text ? [0, [], 0] : [wordlength, allowedxters, text.length]
  }

  async saveData(callback) {
    //never use this.question here
    //this.question may have been updated earlier than the other relevant info
    //those are manually changed in onChanges()
    try
    {
      if (this.previousAnswerHtml !== this.quillAnswerHtml) {
        const data = this.questionservice.constructAnswerSaveData(this.schedulequestionid, this.scheduleid, [], true, this.examid);
        //we won't use captcha for theory exams
        //this.questionservice.saveQuestionOption(data);
        await this.questionservice.saveEssayAnswer(this.quillAnswerHtml, data);
        this.updatedanswer.emit(this.quillAnswerHtml)
        this.previousAnswerHtml = this.quillAnswerHtml;
      }
      else {
        console.log(`Not saving because questionHtml has not changed`);
      }
    }
    finally{
      if(!this.destroyed){
        if (callback) callback();
      }
    }
  }

  
  callSaveData() {
    this.saveData(() => {
      setTimeout(() => { this.callSaveData(); }, 1000 * this.questionservice.essaySavingIntervalSeconds);//2s seconds
    });
  }

  onEditorContentChange([html, plainText]: [string, string]) {
    this.quillAnswerHtml = html;

    const length = this.getWordCount(plainText);
    this.wordcountdisplay = !length[0] ? '' : length[0] + ' ' + 'word' + (length[0] > 1 ? 's' : '');
  }

  onEditorCreated() {
    this.callSaveData()
  }

}


