<div class="container">
    <div class="topcontainer d-flex flex-wrap">
        <button data-toggle="modal" data-target="#newRoomModal" class="btn btn-primary mx-0 flex-fill flex-md-grow-0"><i class="fa fa-plus"></i> New Room</button>
        <button (click)="shuffleParticipants()" class="btn btn-primary mx-0 flex-fill flex-md-grow-0"><i class="fa fa-random"></i> Shuffle</button>
        <!-- <button>Use Original Groups</button> -->
        <!-- <button></button> -->
    </div>
    <div class="maincontainer flex-column flex-md-row py-3">
        <div class="d-flex flex-column w-100 w-md-50 mx-auto mx-md-0 mr-md-3 shadow rounded-lg p-3">
            <form>
                <label for="searchDefaultRoomTextBox" class="sr-only">Search Default Room</label>
                <div class="d-flex justify-content-between align-items-center frame">
                    <input #searchDefaultRoomTextBox type="text" name="searchDefaultRoomTextBox" id="searchDefaultRoomTextBox" placeholder="Search Default Room" [(ngModel)]="defaultroomsearchstr" class="p-2 border-0 rounded w-100 mw-100"/>
                    <i class="fas fa-search p-2"></i>
                </div>
            </form>
            <div class="roomName"><span>{{meetingHandler.defaultRoom}}</span></div>
            <div class="participantsdiv">
                <div *ngFor="let username of this.defaultRoomUsernames" class="participantdiv">
                    <!-- {{username}} -->
                    <app-main-group-list-participant #mainroomparticipants [username]="username" [localParticipant]="username == meetingHandler.localuserinfo.username"></app-main-group-list-participant>
                </div>
            </div>
        </div>
        <div class="d-flex flex-column w-100 w-md-50 mx-auto mx-md-0 mt-3 mt-md-0 shadow rounded-lg p-3" *ngIf="meetingHandler.localuserinfo.host">
            <form>
                <label for="searchBreakoutRoomsTextBox" class="sr-only">Search Break Out Rooms</label>
                <div class="d-flex justify-content-between align-items-center frame">
                    <input #searchBreakoutRoomsTextBox type="text" name="searchBreakoutRoomsTextBox" id="searchBreakoutRoomsTextBox" placeholder="Search Break Out Rooms" [(ngModel)]="breakoutroomsearchstr" class="p-2 border-0 rounded w-100 mw-100"/>
                    <i class="fas fa-search p-2"></i>
                </div>
            </form>
            <div class="roomsdiv">
                <div class="room" *ngFor="let roomparticipants of meetingHandler.breakoutRooms | keyvalue; trackBy:identifyUser" >
                    <div *ngIf="meetingHandler.defaultRoom != roomparticipants.key" class="room-inner">
                        <!-- *ngIf="meetingHandler.currentRoom != roomparticipants.key"  -->
                        <div>
                            <span *ngIf="!editmode[roomparticipants.key]" class="roomnamespan">
                                <span class="breakout-room-name">{{roomparticipants.key}}</span>
                                <button (click)="editMode(roomparticipants.key, true)" class="btn btn-outline-warning btn-sm"><i class="fas fa-pencil-alt"></i></button>
                                <button *ngIf="!addparticipantsmode" (click)="addParticipantMode(roomparticipants.key, true)" class="btn btn-outline-warning btn-sm"><i class="fas fa-user-plus"></i></button>
                                <button *ngIf="addparticipantsmode && addparticipantsgroup == roomparticipants.key" (click)="addParticipantMode(roomparticipants.key, false)" class="btn btn-outline-success btn-sm"><i class="fas fa-user-check"></i></button>
                                <button class="btn btn-outline-danger btn-sm" (click)="removeRoom(roomparticipants.key)"><i class="fas fa-times"></i></button>
                            </span>
                            <span *ngIf="editmode[roomparticipants.key]" class="editroomnamespan">
                                <input [value]="roomparticipants.key" id="{{'room_' + roomparticipants.key + '_textbox'}}"/>
                                <button (click)="renameRoom(roomparticipants.key)" class="btn btn-outline-success btn-sm editroomnamedonebutton"><i class="fas fa-check"></i></button>
                            </span>

                            <div *ngFor="let username of getBreakoutRoomUsernames(roomparticipants.value)" class="participantdiv">
                                <app-minor-group-list-participant #minorroomparticipants [username]="username" [room]="roomparticipants.key" (removeParticipant)="moveParticipantToMainRoom($event.room, $event.username)"></app-minor-group-list-participant>
                                <!-- <span *ngIf="participant.value.handRaised"><i class="far fa-hand-paper"></i></span>
                                <span>{{participant.value.name}}</span> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bottomcontainer">
        <button (click)="breakOut()" class="btn btn-primary">Break Out</button>
    </div>
</div>


<div class="modal" tabindex="-1" role="dialog" id="newRoomModal">
    <div class="modal-dialog shadow" role="document">
        <form (ngSubmit)="createNewRoom()" class="modal-content mt-5">
            <div class="modal-header">
                <h5 class="modal-title">New Room</h5>
            </div>
            <div class="modal-body participants-modal-body">
                <label for="newRoomTextbox" class="sr-only">Room Name</label>
                <input #newRoomTextbox type="text" name="newRoomTextbox" id="newRoomTextbox" class="frame w-100 p-2" placeholder="Room Name" required />
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary">Create</button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
            </div>
        </form>
    </div>
</div>