import { Injectable } from '@angular/core';
import { Participant } from './Participant';

@Injectable({
    providedIn: 'root'
  })
export class MeetingSettings
{
    autoswitchpage: boolean = false;
    secondsperpage: number = 10;
    host: false;
    //private static settings = new Settings();
    private constructor(){

    }
    selectedCamera = '';
    selectedMicrophone = '';
    //private videoConstraints = {};
    //private audioConstaints = {};
    enableCamera = false;
    enableMicrophone = false;

    participantsperpage = 1;

    focusedParticipant: Participant;

    inFullScreen = false;

    //initialSet = false;

    // static getSettings(){
    //     return this.settings;
    // }

    // getSelectedCamera(){
    //     return this.selectedCamera;
    // }

    // getSelectedMicrophone(){
    //     return this.selectedMicrophone;
    // }

    // setSelectedCamera(deviceid: string){
    //     this.selectedCamera = deviceid;
    //     //this.constructVideoConstraints();
    // }

    // setSelectedMicrophone(deviceid: string){
    //     this.selectedMicrophone = deviceid;
    //     //this.constructAudioConstraints();
    // }

    // static getVideoConstraints(){
    //     return this.settings.videoConstraints;
    // }

    // static getAudioConstraints(){
    //     return this.settings.audioConstaints;
    // }

    getVideoConstraints(overrideDeviceId: string = null){
        
        let videoConstraints;
        
            //debugger;
        if (this.selectedCamera || overrideDeviceId) {
            
                videoConstraints = {//firefox can't restrict framerate
                    deviceId: { exact: overrideDeviceId ?? this.selectedCamera }//, frameRate: { max: 24 }//, height: { max: 720 } //, /*frameRate: { max: 12 },*/ width: { max: 640 }, height: { max: 480 }
                }
            }
        else {
            //debugger;
            videoConstraints = true;
            // videoConstraints = {
            //     frameRate: { max: 24 }
            // };
        }
        
        
        //this.settings.videoConstraints = videoConstraints;
        return videoConstraints;
    }

    getScreenShareVideoConstraints(){
        return {
            cursor: "always",//,
            //width: { max: 1920 },
            frameRate: {max: 5 }//,//it is important to specify max framerate for screen sharing because the frame rate on the server goes to infinity if the max is not set...which makes the number of frames per second really high, and then processing afterwards becomes a nightmare
            //height: { max: 1080 }
        };
    }

    getScreenShareAudioConstraints(){
        let audioConstraints = { sampleSize: 16, channelCount: 1 };
        return audioConstraints;
    }

    getAudioConstraints(overrideDeviceId: string = null){
        let audioConstraints;
        audioConstraints = { sampleSize: 16, channelCount: 1, deviceId: {} };

        if(this.selectedMicrophone || overrideDeviceId){
            audioConstraints.deviceId = { exact: overrideDeviceId ?? this.selectedMicrophone };
        }
        
        return audioConstraints;
    }

}
