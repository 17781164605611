import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserauthenticationserviceService } from '../services/userauthenticationservice.service';

@Injectable({
  providedIn: 'root'
})
export class AuthguardGuard implements CanActivateChild {

  constructor(private router: Router, private useraccessservice: UserauthenticationserviceService,private toastr:ToastrService) {

  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.authChecker(state);
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.authChecker(state);
  }

  authChecker(state: RouterStateSnapshot) {
    //for every new page, check the current user again
    if (this.useraccessservice.getCurrentUserToken() != null) {
      let returnVal = this.checkUserSession(state.url);
      return true;
    } else {
      //this.toastr.error("it got here1")
      this.router.navigate(["/access/login"], { queryParams: { url: state.url } });
      return false;
    }
  }

  checkUserSession(url) {
    var returnVal: boolean;    
    console.log(`from ${url}`)
    var x = this.useraccessservice.getCurrentUser().then(
      (data: any) => {
       // debugger
        if (data) {
          return true;
        }
        else {
      //this.toastr.error("it got here2")
      console.log("loging user out now");
          this.router.navigate(["/access/login"]);
          return false;
        }
      },
      (err: HttpErrorResponse) => {
      this.toastr.error("it got 3")
      console.log("loging user out now");
        this.router.navigate(["/access/login"]);
        return false;
      }
    );
  }
}
