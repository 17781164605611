import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Util } from '../interceptors/util';
import { CbtserviceService } from '../services/cbtservice.service';
import { ExamService } from '../services/exam.service';
import { ProctoringService } from '../services/proctoring.service';
import {DomSanitizer} from '@angular/platform-browser';
import { LanguageService } from '../services/language.service';

@Component({
  selector: 'app-sebinstructionpage',
  templateUrl: './sebinstructionpage.component.html',
  styleUrls: ['./sebinstructionpage.component.css']
})
export class SebinstructionpageComponent implements OnInit {
  examurl_examid_scheduleid: any;
  isLoading: boolean;
  userauthservice: any;
  logourl: any;
  externaltoken: any;
  externalportalname: any;
  fromexternalauth: any;
  downloadurl: any;
  sebDownloadLinkForWindows: string="https://todayssolutions.com.ng/secureexambrowser/SEB_3.9.0.787_SetupBundle.exe"
  sebDownloadLinkForMac: string="https://todayssolutions.com.ng/secureexambrowser/SafeExamBrowser-3.5.dmg"
  sebCurrentWindowsVersion: string = "3.9";
  setCurrentMacVersion:string = "3.5";  

  constructor(public toastr: ToastrService, public activatedroute: ActivatedRoute, public router: Router, public util: Util, private examservice: ExamService,private cbtservice: CbtserviceService, private sanitizer:DomSanitizer, private languageService: LanguageService) { }
  get language() {
    return this.languageService.language;
  }

  async ngOnInit() {
    var query = this.activatedroute.snapshot.queryParams.query
    if (query) {
      this.examurl_examid_scheduleid = query;
    }
    const subdomain = this.util.getSubdomain();
    if (subdomain) {
      this.isLoading = true;
      this.isLoading=false;
    }
    //await this.downloadsebconfig();

    //this.sebdownloadlink="https://pub-20042618721c4b1daa76c4e53384f296.r2.dev/SEB.exe";//r2cloudflare .dmg for mac(future update)
    //this.sebdownloadlink;//todayssolutions website godaddy .dmg for mac(future update)
    
  }
  // async downloadsebconfig() {
  //   this.isLoading=true
  //debugger
  //   var data= await this.examservice.getSEBConfig(this.examurl_examid_scheduleid).catch(err=>{this.cbtservice.showHttpError(err);this.isLoading=false;})
  //   const downloadedFile = new Blob([data as BlobPart], {
  //       type: data?.type
  //   });
  //   console.log("ddd", downloadedFile)
  //   if (downloadedFile.type != "") {
  //       const a = document.createElement('a');
  //       a.setAttribute('style', 'display:none;');
  //       document.body.appendChild(a);
  //       a.download = "ExamConfig.seb";
  //       a.href = URL.createObjectURL(downloadedFile);
  //       a.target = '_blank';
  //       a.click();
  //       document.body.removeChild(a);
  //   }    this.isLoading=false
  // }
  getSEBConfigUrl(){
    //sebs:cbtapi.webtest.ng/Exam/downloadsebconfig?&company=workforce
    //var a =`${encodeURI(this.examurl_examid_scheduleid ?? '')}`;
    let url = `${this.examservice.rootS3DBURL}Exam/downloadsebconfig?${this.examurl_examid_scheduleid}&company=${this.util.getSubdomain()}`;
    const safeUrl = this.sanitizer.bypassSecurityTrustUrl(url);
    //console.log(url);
    return safeUrl;
  }
}
