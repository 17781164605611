import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { AlertService } from './alertservice';
import { Observable, throwError } from 'rxjs';
import {catchError} from 'rxjs/operators';
import { CbtserviceService } from '../services/cbtservice.service';
import { environment } from 'src/environments/environment';
@Injectable()
export class AlertsInterceptor implements HttpInterceptor {

  constructor( private cbtservice : CbtserviceService, private notification :AlertService)  {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(            
      catchError((error : HttpErrorResponse) => {   
        //if its not one of the exam requests that we don't want to keep retrying, OR its not a gateway timeout and its not a seamless scaling error
        if(!request.headers.has('skip_error_handler_interceptor')){
          this.cbtservice.showHttpError(error);
        }
        return throwError(error);
      })
    );

  }
}
