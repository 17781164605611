<!--
=========================================================
* Argon Dashboard PRO - v1.2.0
=========================================================
* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro
* Copyright  Web Test (http://www.creative-tim.com)
* Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
-->
<!doctype html>
<html>

<head>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
  <meta name="description" content="Webtest Login">
  <meta name="author" content="Web Test">
  <title>WEB TEST</title>
  <!-- Favicon -->
  <!-- <link rel="icon" href="../../assets/images/image.ico" type="image/png"> -->
  <link rel="icon" type="image/png" sizes="32x32" href="../../assets/images/favicon-32x32.png"/>
  <!-- Fonts -->
  <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700"/>
  <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap" rel="stylesheet"/>
  <!-- Icons -->
  <!-- <link rel="stylesheet" href="../../assets/vendor/nucleo/css/nucleo.css" type="text/css"> -->
  <!-- <link rel="stylesheet" href="../../assets/vendor/@fortawesome/fontawesome-free/css/all.min.css" type="text/css"> -->
  <!-- Argon CSS -->
  <link rel="stylesheet" href="/assets/css/argon.css?v=1.2.0" type="text/css"/>
  <style type="text/css">
    body {
      background-color: #e9f3fc !important;
    }

    .navbar-horizontal.navbar-transparent {
      position: relative;
    }

    .form-control-label {
      font-size: 14px !important;
    }
  </style>
</head>

<body style="background-color: #e9f3fc"> <!-- remove bg-default class and height: 100vh;-->
  <!-- Navbar -->
  <nav id="navbar-main" class="navbar navbar-horizontal navbar-transparent navbar-main navbar-expand-lg navbar-light"
    style="display: none;">
    <div class="container">
      <a class="navbar-brand" href="../../pages/dashboards/dashboard.html">
        <!-- <img src="../../assets/img/brand/white.png"> -->
        <!-- <h2 class="logo ml-2" ><a style="color: white !important;" routerLink="/access/login" >WEB TEST</a> </h2> -->
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-collapse"
        aria-controls="navbar-collapse" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse navbar-custom-collapse collapse" id="navbar-collapse">
        <div class="navbar-collapse-header">
          <div class="row">
            <div class="col-6 collapse-brand">
              <a href="../../pages/dashboards/dashboard.html">
                <img src="../../assets/img/brand/blue.png">
              </a>
            </div>
            <div class="col-6 collapse-close">
              <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#navbar-collapse"
                aria-controls="navbar-collapse" aria-expanded="false" aria-label="Toggle navigation">
                <span></span>
                <span></span>
              </button>
            </div>
          </div>
        </div>
        <!-- <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <a href="../../pages/dashboards/dashboard.html" class="nav-link">
              <span class="nav-link-inner--text">Dashboard</span>
            </a>
          </li>
          <li class="nav-item">
            <a href="../../pages/examples/pricing.html" class="nav-link">
              <span class="nav-link-inner--text">Pricing</span>
            </a>
          </li>
          <li class="nav-item">
            <a href="../../pages/examples/login.html" class="nav-link">
              <span class="nav-link-inner--text">Login</span>
            </a>
          </li>
          <li class="nav-item">
            <a href="../../pages/examples/register.html" class="nav-link">
              <span class="nav-link-inner--text">Register</span>
            </a>
          </li>
          <li class="nav-item">
            <a href="../../pages/examples/lock.html" class="nav-link">
              <span class="nav-link-inner--text">Lock</span>
            </a>
          </li>
        </ul> -->
        <hr class="d-lg-none" />
        <!-- <ul class="navbar-nav align-items-lg-center ml-lg-auto">
          <li class="nav-item">
            <a class="nav-link nav-link-icon" href="https://www.facebook.com/creativetim" target="_blank" data-toggle="tooltip" data-original-title="Like us on Facebook">
              <i class="fab fa-facebook-square"></i>
              <span class="nav-link-inner--text d-lg-none">Facebook</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link nav-link-icon" href="https://www.instagram.com/creativetimofficial" target="_blank" data-toggle="tooltip" data-original-title="Follow us on Instagram">
              <i class="fab fa-instagram"></i>
              <span class="nav-link-inner--text d-lg-none">Instagram</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link nav-link-icon" href="https://twitter.com/creativetim" target="_blank" data-toggle="tooltip" data-original-title="Follow us on Twitter">
              <i class="fab fa-twitter-square"></i>
              <span class="nav-link-inner--text d-lg-none">Twitter</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link nav-link-icon" href="https://github.com/creativetimofficial" target="_blank" data-toggle="tooltip" data-original-title="Star us on Github">
              <i class="fab fa-github"></i>
              <span class="nav-link-inner--text d-lg-none">Github</span>
            </a>
          </li>
          <li class="nav-item d-none d-lg-block ml-lg-4">
            <a href="https://www.creative-tim.com/product/argon-dashboard-pro" target="_blank" class="btn btn-neutral btn-icon">
              <span class="btn-inner--icon">
                <i class="fas fa-shopping-cart mr-2"></i>
              </span>
              <span class="nav-link-inner--text">Purchase now</span>
            </a>
          </li>
        </ul> -->
      </div>
    </div>
  </nav>
  <!-- Main content -->
  <div class="main-content">
    <div class="container-fluid">
      <div class="end-lang">
        <app-language-layout></app-language-layout>
      </div>

      <div class="row">
        <div class="col-sm-12 webtest-logo">
          <!-- <img src="/assets/images/Unilag-Logo-Desktop.png" width="260" alt="logo" /> -->
          <!-- <img *ngIf="this.util.getSubdomain() && logourl" [src]="logourl" width="260" alt="logo" /> -->
          <a href="/"><img src="/assets/images/main-logo.png" alt="logo" /></a>
        </div>
        <div class="col-sm-12 mobile-logo">
          <a href="/"><img src="/assets/images/main-logo-block.png" width="105" alt="logo" /></a>
        </div>
      </div>
    </div>
    <!-- Header -->
    <div class="header bg-gradient-primary py-7 py-lg-8 pt-lg-9" style="display: none;">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="text-white"> {{language.welcome}}!</h1>
              <p class="text-lead text-white"> {{language.loginToTakeExam}}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div> -->
    </div>
    <!-- Page content -->
    <div class="container"><!--remove mt--8 pb-5 -->
      <router-outlet></router-outlet>
    </div>
    <!-- <div class="mobile-footer">
      <div class="container-fluid">
        <div class="row">
            <p>© 2021 webTest All rights reserved.</p>
        </div>
      </div>
    </div> -->
  </div>
  <!-- Footer -->

  <footer class="py-5" id="footer-main" style="display: none;">
    <div class="container">
      <div class="row align-items-center justify-content-xl-between">
        <div class="col-xl-6">
          <div class="copyright text-center text-xl-left text-muted">
            &copy; 2021 <a routerLink="/access/login" class="font-weight-bold ml-1" target="_blank">Web Test</a>
          </div>
        </div>
        <div class="col-xl-6">
          <ul class="nav nav-footer justify-content-center justify-content-xl-end">
            <li class="nav-item">
              <a href="https://webtest.ng" class="nav-link" target="_blank">Web Test</a>
            </li>
            <li class="nav-item">
              <a href="https://webtest.ng" class="nav-link" target="_blank">About Us</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
  <!-- Argon Scripts -->
  <!-- Core -->
  <script src="../../assets/vendor/jquery/dist/jquery.min.js"></script>
  <script src="../../assets/vendor/bootstrap/dist/js/bootstrap.bundle.min.js"></script>
  <script src="../../assets/vendor/js-cookie/js.cookie.js"></script>
  <script src="../../assets/vendor/jquery.scrollbar/jquery.scrollbar.min.js"></script>
  <script src="../../assets/vendor/jquery-scroll-lock/dist/jquery-scrollLock.min.js"></script>
  <!-- Argon JS -->
  <script src="../../assets/js/argon.js?v=1.2.0"></script>
  <!-- Demo JS - remove this in your project -->
  <script src="../../assets/js/demo.min.js"></script>

  <!-- fill the entire browser with loginbg -->

</body>

</html>