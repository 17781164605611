<ng-template #mymodal let-modal *ngIf="question">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title"> {{language.examinationFeedBack}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <h4 class="text-center mt-3 mb-5">{{question.feedbackquestion}} </h4>
        <div class="row">
            <div class="col-4 text-center" (click)="onfaceclicked('Smiling',question.questionid)">
                <div class="mini-container"> <i style="color: green;" class="fas fa-smile-beam fa-3x"></i>
                    <p class="text-center"><small><strong> {{language.happy}}</strong></small></p>
                </div>
            </div>
            <div class="col-4 text-center" (click)="onfaceclicked('Neutral',question.questionid)">
                <div class="mini-container"> <i style="color: rgb(214, 201, 14);" class="fas fa-meh fa-3x"></i>
                    <p class="text-center"><small><strong> {{language.neutral}}</strong></small></p>
                </div>
            </div>
            <div class="col-4 text-center" (click)="onfaceclicked('Sad',question.questionid)">
                <div class="mini-container"> <i style="color: red;" class="fas fa-tired fa-3x"></i>
                    <p class="text-center"><small><strong> {{language.unhappy}}</strong></small></p>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">  {{language.close}}</button>
    </div>
  </ng-template>  
  
  <ng-template #mymodal2 let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title"> {{language.examinationFeedBack}}</h4>
     
    </div>
    <div class="modal-body">
        <h4 class="text-center"> {{language.problemCategories}}</h4>

        <div class="row" *ngFor="let cq of checkquestions">
            <div class="col-sm-2 text-right" style="padding-top:3px;padding-left: 0px;padding-right: 0px;">
                <input type="checkbox" style="height: 15px;width: 17px;" (click)="onchecked(cq)">
            </div>
            <div class="col-sm-8">
                <span>{{cq.feedbackquestion}}</span>
            </div>
        </div>
        
        <div class="row">
            <div class="col-sm-2 text-right" style="padding-top:3px;padding-left: 0px;padding-right: 0px;" >
                <input type="checkbox" style="height: 15px;width: 17px;" (click)="checkedothers()">
            </div>
            <div class="col-sm-8">
                <span> {{language.othersProblemCategories}}: </span><textarea  [(ngModel)]="probmsg" *ngIf="isothers" class="col-sm-8" ></textarea>
            </div>
        </div> 

    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-lg btn-outline-primary" (click)="savefeedback()">{{language.submit}}</button>
    </div>
  </ng-template>