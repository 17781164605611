export class ExamInfo {
  subjectid: string; // String value
  scheduleid: number; // Long value
  name: string; // String value
  //statusenum: ExaminationStatus; // Enum type
  status: string;
  examdate: Date; // DateTime value
  duration: number; // Integer value
  examid: number; // Long value
  //examtypeenum: ExaminationType; // Enum type
  examtype: string;
  useproctor: boolean; // Boolean value
  selfschedule: boolean; // Boolean value
  allowreschedule: boolean; // Boolean value
  allowrescheduleAfter: boolean; // Boolean value
  requireadminstart: boolean;
  examstartedbyadmin: boolean; // Boolean value
  //questionselectionmode: ExaminationQuestionSelectionMode; // Enum type
  questionpercategory: number; // Integer value
  randomiseoptions: boolean; // Boolean value
  examscorestring: string; // String value
  showscorewhenfinished: boolean; // Boolean value
  canstart: boolean; // Boolean value
  proctorerror: string; // String value
  passportmandatory: boolean; // Boolean value
  passporterror: string; // String value
  attempt: number; // Integer value
  skipAIPassportCheck: boolean; // Boolean value
  disablePassportUpload: boolean; // Boolean value
  useSEB: boolean; // Boolean value
  useCaptcha: boolean; // Boolean value
  interviewaccesscode: string; // String value
  liteMode: boolean; // Boolean value
  examPaused: boolean; // Boolean value,
  candidateCanRetake: boolean
}

export class ExamDetails extends ExamInfo {
  examname: string; // String value
  checkfocusloss: boolean; // Boolean value
  //focuspenaltyenum: ExamLossOfFocusAction; // Enum type
  focuspenalty: string;
  nooffocusloss: number; // Integer value
  durationofloss: number; // Integer value
  maxfocuslossrestart: number; // Integer value
  reducetimelossduration: number; // Integer value
  //proctorlevel: ProctorLevel; // Enum type
  useproctor: boolean;
  examtype: string;
  noofquestions: number; // Integer value
  requireadminstart: boolean; // Boolean value
  requireproctorstart: boolean; // Boolean value
  usecalculator: boolean; // Boolean value
  displayquestiontextonquestionlist: boolean; // Boolean value
  //calculatormodeenum: CalculatorType; // Enum type
  calculatormode: string;
  defaultpausereason: string; // String value
  enforceScheduleEndTime: boolean; // Boolean value
  postExamMessages: string; // String value
  adminStartTime: Date; // DateTime value
  instructions: Instructions; // Object of type Instructions
  showSubmitButtonOnEveryQuestion: boolean; // Boolean value
  //examcategories: ExamCategory[] = []; // Array of ExamCategory objects, initialized as an empty array
}

export class Instructions {
  webtestHTML: string = ""
  companyHTML: string = ""
  examHTML: string = ""
  company: string = ""
}

export class ExamAndQuestions {
  questions: ScheduleQuestionAndOptions[]; // Array of ScheduleQuestionAndOptions objects
  focuslosscount: number; // Integer value
  examination: ExamDetails; // Object of type ExamDetails
  exampaused: boolean; // Boolean value
  timeleft: number;
}

export class ScheduleQuestionAndOptions {
  examQuestion: ScheduleQuestionProp; // Object of type ScheduleQuestionProp
  examQuestionOptions: ScheduleOptionsProp[]; // Array of ScheduleOptionsProp objects
  
  

  //the below don't come from the backend directly in the initial call
  examination: ExamDetails; 
  questionCount: number;
  focuslosscount: number;
  exampaused: boolean;
  timeleft: number;
  //essayAnswer: string; // String value,
}

export class ScheduleQuestionProp {
  questionid: number; // Long value
  schedulequestionid: number; // Long value
  questionno: number; // Integer value
  questionnolabel: string;
  attempt: number; // Integer value
  flagged: boolean; // Boolean value
  answered: boolean; // Boolean value
  marks: number; // Decimal value
  penaltymarks: number; // Decimal value
  saqanswer:string;

  //the below don't come from the backend in the initial call
  questionhtml: string;
  hasMultipleAnswers: boolean;
  maxwordcount: number;
  category;
  instructions;
}

export class ScheduleOptionsProp {
  optionid: number; // Long value
  selected: boolean; // Boolean value
  optionno: number; // Integer value

  //the below don't come from the backend in the initial call
  optionhtml: string = "";
}

export class ExaminationQuestion{
  question: ScheduleQuestionProp;
  options: ScheduleOptionsProp[];
}

