<!-- <label class="radio radio-gradient lead">
    <span class="radio__input">
      <input type="radio" name="radio" [value]="option.optionid" [checked]=option.selected (click)="onRadioButtonClick(option)">
      <span class="radio__control"></span>
    </span>
    <span [innerHTML]="option.optionhtml" class="radio__label"></span>
  </label> -->


  <label class="checkbox">
    <span class="checkbox__input">
      <input type="checkbox" [name]="schedulequestionid" [class]="schedulequestionid"  [value]="option.optionid" id="{{option.optionid}}" [checked]=option.selected (change)="checkChanged($event,option)">
      <span class="checkbox__control">
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' aria-hidden="true" focusable="false">
          <path fill='none' stroke='currentColor' stroke-width='3' d='M1.73 12.91l6.37 6.37L22.79 4.59' /></svg>
      </span>
    </span>
    <span [innerHTML]="option.optionhtml" class="radio__label"></span>
  </label>