
<div class="calc-container">
    <table align="center" id="sci-calc-v3">
        <tr>
            <td id="sciout">
                <div>
                    <div id="sciInPut">&nbsp;</div>
                    <div id="sciOutPut">0</div>
                </div>
                <div style="padding-top:3px;width:100%">
                    <div><span onclick="rCalc('sin')" class="scifunc">sin</span><span onclick="rCalc('cos')"
                            class="scifunc">cos</span><span onclick="rCalc('tan')" class="scifunc">tan</span><span
                            class="scird"><label for="scirdsettingd"><input id="scirdsettingd" type="radio"
                                    name="scirdsetting" value="deg" onClick="cnDegreeRadians='degree';"
                                    checked>Deg</label><label for="scirdsettingr"><input id="scirdsettingr" type="radio"
                                    name="scirdsetting" value="rad" onClick="cnDegreeRadians='radians';">Rad</label></span>
                    </div>
                    <div><span onclick="rCalc('asin')" class="scifunc">sin<sup>-1</sup></span><span onclick="rCalc('acos')"
                            class="scifunc">cos<sup>-1</sup></span><span onclick="rCalc('atan')"
                            class="scifunc">tan<sup>-1</sup></span><span onclick="rCalc('pi')"
                            class="scifunc">&#960;</span><span onclick="rCalc('e')" class="scifunc">e</span></div>
                    <div><span onclick="rCalc('pow')" class="scifunc">x<sup>y</sup></span><span onclick="rCalc('x3')"
                            class="scifunc">x<sup>3</sup></span><span onclick="rCalc('x2')"
                            class="scifunc">x<sup>2</sup></span><span onclick="rCalc('ex')"
                            class="scifunc">e<sup>x</sup></span><span onclick="rCalc('10x')"
                            class="scifunc">10<sup>x</sup></span></div>
                    <div><span onclick="rCalc('apow')" class="scifunc"><sup>y</sup>&#8730;x</span><span onclick="rCalc('3x')"
                            class="scifunc"><sup>3</sup>&#8730;x</span><span onclick="rCalc('sqrt')"
                            class="scifunc">&#8730;x</span><span onclick="rCalc('ln')" class="scifunc">ln</span><span
                            onclick="rCalc('log')" class="scifunc">log</span></div>
                    <div><span onclick="rCalc('(')" class="scifunc">(</span><span onclick="rCalc(')')" class="scifunc">)</span><span
                            onclick="rCalc('1/x')" class="scifunc">1/x</span><span onclick="rCalc('pc')"
                            class="scifunc">%</span><span onclick="rCalc('n!')" class="scifunc">n!</span></div>
                </div>
                <div style="padding-top:3px;">
                    <div>
                        <span onclick="rCalc(7)" class="scinm">7</span><span onclick="rCalc(8)" class="scinm">8</span>
                        <span onclick="rCalc(9)" class="scinm">9</span><span onclick="rCalc('+')" class="sciop">+</span>
                            <span onclick="rCalc('bk')" class="sciop">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-delete"><path d="M21 4H8l-7 8 7 8h13a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z"></path><line x1="18" y1="9" x2="12" y2="15"></line><line x1="12" y1="9" x2="18" y2="15"></line></svg>
                            </span>
                        </div>
                    <div><span onclick="rCalc(4)" class="scinm">4</span><span onclick="rCalc(5)" class="scinm">5</span><span
                            onclick="rCalc(6)" class="scinm">6</span><span onclick="rCalc('-')" class="sciop">&ndash;</span><span
                            onclick="rCalc('ans')" class="sciop">Ans</span></div>
                    <div><span onclick="rCalc(1)" class="scinm">1</span><span onclick="rCalc(2)" class="scinm">2</span><span
                            onclick="rCalc(3)" class="scinm">3</span><span onclick="rCalc('*')" class="sciop">&#215;</span><span
                            onclick="rCalc('M+')" class="sciop">M+</span></div>
                    <div><span onclick="rCalc(0)" class="scinm">0</span><span onclick="rCalc('.')" class="scinm">.</span><span
                            onclick="rCalc('EXP')" class="sciop">EXP</span><span onclick="rCalc('/')"
                            class="sciop">&#247;</span><span onclick="rCalc('M-')" class="sciop">M-</span></div>
                    <div><span onclick="rCalc('+/-')" class="sciop">&#177;</span><span onclick="rCalc('RND')"
                            class="sciop">RND</span><span onclick="rCalc('C')" class="scieq">AC</span><span onclick="rCalc('=')"
                            class="scieq">=</span><span onclick="rCalc('MR')" class="sciop" id="scimrc">MR</span></div>
                </div>
                <div id="scihistory"></div>
            </td>
        </tr>
    </table>
</div>
