import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Host, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Participant } from '../models/Participant';
import { MeetingHandlerService } from '../services/meeting-handler.service';
import { MainGroupListParticipantComponent } from '../main-group-list-participant/main-group-list-participant.component';
import { MeetingComponent } from '../meeting/meeting.component';

declare const $: any;

@Component({
  selector: 'app-participants-list',
  templateUrl: './participants-list.component.html',
  styleUrls: ['./participants-list.component.scss']
})
export class ParticipantsListComponent implements OnInit {

  @Input() participantsMap: Map<string, Participant>;
  @ViewChild('newRoomTextbox') newRoomTextbox: ElementRef;
  @ViewChildren('mainroomparticipants') mainroomparticipants: QueryList<MainGroupListParticipantComponent>;
  @ViewChild('searchDefaultRoomTextBox') searchDefaultRoomTextBox: ElementRef;
  @ViewChild('searchBreakoutRoomsTextBox') searchBreakoutRoomsTextBox: ElementRef;
  addparticipantsmode: boolean = false;
  addparticipantsgroup: string = '';
  editmode = {};
  @Output() breakOutClicked: EventEmitter<void> = new EventEmitter<void>();
  defaultroomsearchstr: string = '';
  breakoutroomsearchstr: string = '';
  constructor(private toastr: ToastrService, public meetingHandler: MeetingHandlerService, private ref: ChangeDetectorRef) {

  }

  ngOnInit(): void {
  }

  breakOut(){
    //debugger;
    this.breakOutClicked.emit();
    
  }

  detectChanges(){
    this.ref.detectChanges();
  }

  // searchDefaultRoom(){
  //   this.searchstr = this.searchDefaultRoomTextBox.nativeElement.value;
  // }

  //meeting.breakoutRooms.get(meeting.defaultRoom)
  get defaultRoomUsernames(){
    const usernames = [...this.meetingHandler.breakoutRooms.get(this.meetingHandler.defaultRoom)].filter(username => !this.defaultroomsearchstr || this.meetingHandler.getParticipantByUsername(username).name.toLowerCase().includes(this.defaultroomsearchstr.toLowerCase()));
    return usernames;
  }

  getBreakoutRoomUsernames(usernamesSet: Set<string>){
    const usernames = [...usernamesSet].filter(username => !this.breakoutroomsearchstr || this.meetingHandler.getParticipantByUsername(username).name.toLowerCase().includes(this.breakoutroomsearchstr.toLowerCase()));
    return usernames;
  }

  shuffleParticipants(){
    for(const [room, roomParticipants] of this.meetingHandler.breakoutRooms){
      if(room != this.meetingHandler.defaultRoom){
        for(const username of roomParticipants){
          this.moveParticipantToMainRoom(room, username);
        }
      }
    }
    const defaultRoomParticipants = [...this.meetingHandler.breakoutRooms.get(this.meetingHandler.defaultRoom)];
    const rooms = [...this.meetingHandler.breakoutRooms.keys()].filter(room => room != this.meetingHandler.defaultRoom);
    if(rooms.length > 0){
      for(let i = 0; defaultRoomParticipants.length > 0; i++){//keep iterating over rooms until there are no participants left in the default room
        const index = i % (rooms.length);
        const room = rooms[index];

        //on each iteration, get a random usernmae and move the participant to the current room i the iteration
        const userindex = Math.floor(Math.random() * defaultRoomParticipants.length);
        const username = defaultRoomParticipants[userindex];

        //move the participant to the room, the remove the user from the array
        this.moveParticipantToBreakoutRoom(room, username);
        defaultRoomParticipants.splice(userindex, 1);
      }
    }
  }

  createNewRoom(){
    const newroom = this.newRoomTextbox.nativeElement.value.trim();

    if (newroom.length === 0) {
      this.toastr.error('Room Name Cannot Be Empty')
      return;
    }

    if(!this.meetingHandler.breakoutRooms.has(newroom)){
      this.meetingHandler.breakoutRooms.set(newroom, new Set<string>());
    }
    $('#newRoomModal').modal('hide');
    this.newRoomTextbox.nativeElement.value = '';
  }

  editMode(username: string, inEdit: boolean){
    this.editmode[username] = inEdit;
  }

  renameRoom(room: string){
    const textbox: any = document.getElementById('room_' + room + '_textbox');
    const newroomname = textbox.value;
    const usernames = this.meetingHandler.breakoutRooms.get(room);
    this.meetingHandler.breakoutRooms.delete(room);
    this.meetingHandler.breakoutRooms.set(newroomname, usernames);
    this.editmode[room] = false;
  }

  identifyUser(index, item: any){
    return item.key;
  }



  addParticipantMode(group: string, addMode: boolean){
    this.addparticipantsgroup = group;
    this.addparticipantsmode = addMode;

    if(addMode){
      this.mainroomparticipants.forEach((mainroomparticipant) => {
        mainroomparticipant.selected = false;
      });
      
    }
    else{
      //move the user
      for(const mainroomparticipant of this.mainroomparticipants){
        if(mainroomparticipant.selected){
          this.moveParticipantToBreakoutRoom(group, mainroomparticipant.username);
        }
      }
    }
  }

  removeRoom(room: string){
    const usernames = this.meetingHandler.breakoutRooms.get(room);
    for(const username of usernames){
      this.moveParticipantToMainRoom(room, username);
    }
    this.meetingHandler.breakoutRooms.delete(room);
  }

  moveParticipantToBreakoutRoom(group: string, username: string) {
    this.meetingHandler.breakoutRooms.get(group).add(username);
    this.meetingHandler.breakoutRooms.get(this.meetingHandler.defaultRoom).delete(username);
  }

  moveParticipantToMainRoom(group: string, username: string){
    this.meetingHandler.breakoutRooms.get(this.meetingHandler.defaultRoom).add(username);
    this.meetingHandler.breakoutRooms.get(group).delete(username);
  }
}
