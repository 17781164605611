/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes,extraRequire}
 * tslint:disable
 */
import * as i0 from "./logout.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./logout.component";
import * as i3 from "../services/userauthenticationservice.service";
import * as i4 from "@angular/router";
import * as i5 from "../services/proctoring.service";
import * as i6 from "../services/questionservice.service";
import * as i7 from "../meeting/services/util.service";
var styles_LogoutComponent = [i0.styles];
var RenderType_LogoutComponent = /*@__PURE__*/ i1.ɵcrt({ encapsulation: 0, styles: styles_LogoutComponent, data: {} });
export { RenderType_LogoutComponent as RenderType_LogoutComponent };
export function View_LogoutComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_LogoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-logout", [], null, null, null, View_LogoutComponent_0, RenderType_LogoutComponent)), i1.ɵdid(1, 114688, null, 0, i2.LogoutComponent, [i3.UserauthenticationserviceService, i4.Router, i5.ProctoringService, i4.ActivatedRoute, i6.QuestionserviceService, i7.UtilService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LogoutComponentNgFactory = /*@__PURE__*/ i1.ɵccf("app-logout", i2.LogoutComponent, View_LogoutComponent_Host_0, {}, {}, []);
export { LogoutComponentNgFactory as LogoutComponentNgFactory };

