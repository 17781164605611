import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
//import { OptionDetails } from '../models/model'
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import Quill from 'quill'
import { QuestionserviceService } from '../services/questionservice.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { JsonPipe } from '@angular/common';
import { ExamService } from '../services/exam.service';
import { ProctoringService } from '../services/proctoring.service';
import { environment } from 'src/environments/environment';
import { CbtserviceService } from '../services/cbtservice.service';
import { LanguageService } from '../services/language.service';
import { ScheduleOptionsProp, ScheduleQuestionAndOptions } from '../models/Exam';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.css']
})
export class QuestionComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() question: ScheduleQuestionAndOptions;
  @Input() examid: any;
  @Input() url: any
  @Input() adminpaused: boolean;
  @Input() proctorpaused: boolean;
  @Input() status: string;
  @Input() scheduleid: number;
  //  {
  //   "questionhtml": '<p>Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</p><p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eumquidem.Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea.</p><img src=/"assets/img/about.jpg" class="img-fluid" alt=""><a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" class="" data-vbtype="video"    data-autoplay="true"></a>',
  //   "questionid": 11,
  //   "questionno": 1,
  // }

  @Input() essayexam: boolean
  @Input() issaq: boolean
  options: string[] = [];
  @Input() isloadingdata: boolean = false;
  thisurl = location.origin + '/';

  @Input() loadingSubject: Subject<boolean>;
  @Input() networkFailureSubject: Subject<boolean>;

  @Output() quesanswered: EventEmitter<number> = new EventEmitter<number>();
  @Output() quesunanswered: EventEmitter<number> = new EventEmitter<number>();
  @Output() quesflagged: EventEmitter<any> = new EventEmitter<any>();
  @Output() qnoclicked: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild('questionhtmlsection') questionhtmlsection: ElementRef;
  flagged: boolean = false;
  answersubmissioncheck: any;
  markingnotworking: boolean;
  answersubmissionservercheck: any;

  //essayAnswer: string;

  constructor(public questionservice: QuestionserviceService, public examservice: ExamService, public toastr: ToastrService, public router: Router, private cbtservice: CbtserviceService,private languageService: LanguageService) { }
  get language(){
    return this.languageService.language;
  }
  ngOnChanges(changes: SimpleChanges): void {
    //console.log(changes.prop);
    //debugger;
    this.setQuestion();
  }
  ngAfterViewInit(): void {
    this.setQuestion();
  }

  setQuestion() {
    //debugger;
    if (this.questionhtmlsection) {
      if (this.question) {
        this.questionhtmlsection.nativeElement.innerHTML = this.question.examQuestion.questionhtml;
        this.flagged = this.question.examQuestion.flagged;
      }
      else {
        this.questionhtmlsection.nativeElement.innerHTML = '';
      }
      //this.essayAnswer = this.question.essayAnswer;
    }
  }
  
  updatedanswer(answer: string) {
   // this.question.essayAnswer = answer;
   //this.question.essayAnswer = answer;
    if(this.question.examination.examtype == this.questionservice.Essay){
      if(this.questionservice.essayAnswerIsNotBlank(answer)){
        this.quesanswered.emit(this.question.examQuestion.questionno);
      }
      else{
        this.quesunanswered.emit(this.question.examQuestion.questionno);
      }
    }
    else{
      if (!answer) {
        this.quesunanswered.emit(this.question.examQuestion.questionno);
      }
      else {
        this.quesanswered.emit(this.question.examQuestion.questionno);
      }
    }
  }
  ngOnInit(): void {
    this.options = [];
  }


  async OnRadioSelected(radiovalue: ScheduleOptionsProp) {
    //this.isloadingdata = true
    // console.log(radiovalue);
    //this.options.push(radiovalue);
    
    this.getoptions();

    const data = this.questionservice.constructAnswerSaveData(this.question.examQuestion.schedulequestionid, this.scheduleid, this.options, false, this.question.examination.examid);
    // const data = {
    //   "schedulequestionid": this.question.examQuestion.schedulequestionid,
    //   "scheduleid": this.scheduleid,
    //   //"questionid": this.question.examQuestion.questionid,
    //   "options": this.options,
    //   "essay": false,
    //   "examid":this.question.examination.examid
    // }
    // //console.log('Check ' + this.question.examQuestionSchedule.questionno);
    if (this.options && this.options.length > 0) {
      this.questionservice.markanswer(data);

      // if (!saved) {
      //   this.options = this.options.filter(x => x != radiovalue.optionid.toString());
      //   radiovalue.selected = false;
      //   let opt: any = document.getElementById(radiovalue.optionid.toString());
      //   opt.checked = false;
      //   return
      // }
      // if (saved.logout == true) {
      //   this.toastr.error(saved.msg);
      //   // window.location.href = `${this.thisurl}logout`;
      //   this.router.navigate(["/logout"]);
      // }

      //emit
      this.quesanswered.emit(this.question.examQuestion.questionno);

    }
  }

  getoptions(scheduleid = this.question.examQuestion.schedulequestionid) {
    this.options = [];
    var ele = document.getElementsByClassName(scheduleid.toString());
    for (var i = 0; i < ele.length; i++) {
      this.getchecked(ele[i]);
    }
  }
  getchecked(element) {
    //debugger;
    if (element.checked) {
      this.options.push(element.value.toString())
    }
  }
  // enableradios() {
  //   var buttons = document.getElementsByName(this.question.examQuestionSchedule.schedulequestionid);
  //   buttons.forEach(this.radioenable)
  //   var butons = document.getElementsByClassName(this.question.examQuestionSchedule.schedulequestionid);
  //   for (var i = 0; i < butons.length; i++) {
  //     this.radioenable(butons[i]);
  //   }
  // }
  // radioenable(button) {
  //   button.disabled = false;


  // }

  async timeelapsed(elapsed: boolean) {
    if (elapsed) {
      //we get the unsaved answers here so that we get the answers as at the time of elapsed, not on the x-th iteration
      let answers = [];//this.questionservice.getAllQuestionsAnswer().filter(q => !q.saved);
      if(this.question.examination.liteMode){
        answers = this.questionservice.getAllQuestionsAnswer();
      }
      do {
        try {
          const response: any = await this.examservice.getexamstatus(this.scheduleid, elapsed,this.question.examination.examid).catch(err => this.cbtservice.showHttpError(err)).finally(() => this.isloadingdata = false);
          // console.log(response.statusmsg)

          if (response && response.statusmsg && response.statusmsg != "") {

            //await this.examservice.submitexam(this.question.examQuestionSchedule.scheduleid, this.question.examination.liteMode, elapsed, answers).catch(err => this.cbtservice.showHttpError(err));
            localStorage.clear();
            //debugger;
            this.router.navigate(["/exam/submit"], { queryParams: { scheduleid: this.scheduleid, examid: this.question.examination.examid } });

          }
          else if (response && response.statusmsg == "")
            self.location.reload();

          break;
        }
        catch (error) {
          console.error(error);
        }
      }
      while (true);
    }

  }

  async oncheckchanged(checkvalue: ScheduleOptionsProp) {
   // this.isloadingdata = true
    if (checkvalue.selected)
      this.options.push(checkvalue.optionid.toString());
    else
      this.options = this.options.filter(x => x != checkvalue.optionid.toString());

    this.getoptions();
    var data = {
      schedulequestionid: this.question.examQuestion.schedulequestionid,
      questionid: this.question.examQuestion.questionid,
      scheduleid: this.scheduleid,
      options: this.options,
      essayanswer: "",
      essay: false,
      examid: this.question.examination.examid,
      saq: false,
      saqanswer: ""
    }

    if (this.options) {
      this.questionservice.markanswer(data);
      //debugger
      //if (!saved) {
      // this.options = this.options.filter(x => x != checkvalue.optionid.toString());
      // checkvalue.selected = false;
      // let opt: any = document.getElementById(checkvalue.optionid.toString());
      // opt.checked = false;
      // this.quesunanswered.emit(this.question.examQuestionSchedule.questionno);

      // return;
      //}
      // if (saved.logout == true) {
      //   this.toastr.error(saved.msg);
      //   // window.location.href = `${this.thisurl}logout`;
      //   this.router.navigate(["/logout"]);
      // }

      if (this.options.length == 0) {
        this.quesunanswered.emit(this.question.examQuestion.questionno);
      }
      else {
        this.quesanswered.emit(this.question.examQuestion.questionno);
      }
    }
  }

  flagclicked(flagged: boolean) {
    this.flagged = flagged;
    let qno = this.question.examQuestion.questionno;
    this.quesflagged.emit({ qno, flagged });
  }


  // async gotoprevquestion(qno: number) {
  //   this.getoptions();

  //   this.isloadingdata = true
  //   var data = {
  //     "schedulequestionid": this.question.examQuestionSchedule.schedulequestionid,
  //     "scheduleid": this.question.examQuestionSchedule.scheduleid,
  //     "options": this.options,
  //     "essayanswer":"",
  //     "essay":false
  //   }
  //   console.log(JSON.stringify(data));
  //   if (this.options && this.options.length > 0) {

  //     var saved: any = await this.questionservice.markanswer(data).toPromise().catch(err => this.toastr.error(err)).finally(() => { this.enableradios(); this.isloadingdata = false });
  //     if (saved.saved == true) this.router.navigate(["/exam/question"], { queryParams: { scheduleid: data.scheduleid, questionno: qno } });
  //     if (saved.logout == true) {
  //       this.toastr.error(saved.msg);
  //       this.router.navigate(["/logout"]);
  //     }
  //   }
  //   else{
  //     this.router.navigate(["/exam/question"], { queryParams: { scheduleid: data.scheduleid, questionno: qno } });
  //   }
  // }

  //todo: cleanup
  savequestion(qno: number) {
    this.isloadingdata = true

  }
}
