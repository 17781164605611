import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MeetingHandlerService } from './meeting-handler.service';

@Injectable({
  providedIn: 'root'
})
export class AudioProcessingService {

  audioLevelReportedSubject: Subject<{participantSocketId: string, volumeLevel: number}> = new Subject<{participantSocketId: string, volumeLevel: number}>();
  remoteMediaStreamDestination: MediaStreamAudioDestinationNode;
  //localMediaStreamDestination: MediaStreamAudioDestinationNode;
  loudestParticipantReportedSubject: Subject<{participantSocketId: string, volumeLevel: number}> = new Subject<{participantSocketId: string, volumeLevel: number}>();;
  playerAudioContext: AudioContext;
  monitorAudioContext: AudioContext;
  audioWorkletPromise: Promise<void>;
  participantIdVolumeMeterNodeMap: {} = {};
  loudestParticipantSocketId: string;
  loudestParticipantVolumeLevel: number;
  loudestParticipantResetInterval;
  resumeAudioContextInterval;
  volumeLevelsMap: {} = {};
  static minimumAudioLevelOfNote = 3;
  mergedAudioStreamsMap: Map<string, MediaStreamAudioSourceNode> = new Map<string, MediaStreamAudioSourceNode>();

  constructor() { 
    this.create();
    
  }

  create(){
    this.playerAudioContext = new AudioContext();
    this.monitorAudioContext = new AudioContext();
    this.remoteMediaStreamDestination = this.playerAudioContext.createMediaStreamDestination();
    //this.localMediaStreamDestination = this.audioContext.createMediaStreamDestination();

    this.mergedAudioStreamsMap = new Map<string, MediaStreamAudioSourceNode>();

    this.audioWorkletPromise = this.monitorAudioContext.audioWorklet.addModule('../assets/js/volume-meter-processor.js');

    this.loudestParticipantResetInterval = setInterval(() => {
      this.reportHighestVolumeLevel();
    }, 1000);

    this.resumeAudioContextInterval = setInterval(() => {
      if(this.playerAudioContext.state != 'running'){
        this.playerAudioContext.resume();
      }
      if(this.monitorAudioContext.state != 'running' && !document.hidden){
        this.monitorAudioContext.resume();
      }
    }, 500);
  }

  endMonitorAudioLevel(participantSocketId: string){
    //let the listeners know that the volume level is now 0. this will help clear any volume indicators before we disconnect
    this.audioLevelReportedSubject.next({ participantSocketId, volumeLevel: 0 });
    const audioNode: MediaStreamAudioSourceNode = this.participantIdVolumeMeterNodeMap[participantSocketId];
    if(audioNode){
      audioNode.disconnect();
      delete this.participantIdVolumeMeterNodeMap[participantSocketId];
    }
    delete this.volumeLevelsMap[participantSocketId];
  }

  async monitorAudioLevel(participantSocketId: string, mediaStream: MediaStream) {
    //this.audioContext.resume();

    await this.audioWorkletPromise;

    this.endMonitorAudioLevel(participantSocketId);

    //we could have used the same media stream source to that is being merged into the main stream, but we want to be able to stop monitoring audio levels without disconnecting the stream itself
    //and we want to be able to monitor audio levels without merging it into the main destination
    //so because of these, we'll make a separate media stream source
    if(mediaStream){
      const source = this.monitorAudioContext.createMediaStreamSource(mediaStream);
      
      const volumeMeterNode = new AudioWorkletNode(this.monitorAudioContext, `volume-meter`);

      volumeMeterNode.port.onmessage = ({ data }) => {
        const volumeLevel = data * 500; //i don't know why it's 500. I got it from the example, and it wasn't explained there
        //console.log(volumeLevel)
        if (volumeLevel > AudioProcessingService.minimumAudioLevelOfNote) {
          this.volumeLevelsMap[participantSocketId] = { volumeLevel, timeStamp: new Date() };
        }
        this.audioLevelReportedSubject.next({ participantSocketId, volumeLevel });

        // setTimeout(() => {
        //   this.audioLevelReportedSubject.next({ participantSocketId, volumeLevel: 0 });
        // }, 1000);
      };

      source.connect(volumeMeterNode).connect(this.monitorAudioContext.destination);
      this.participantIdVolumeMeterNodeMap[participantSocketId] = source;
    }
    else{
      console.log(`Can't monitor audio level for participant '${participantSocketId}' because mediaStream is not supplied`);
    }
  }

  get remoteAudioStream(){
    return this.remoteMediaStreamDestination.stream;
  }

  // unmergeRemoteAudioStream(participantSocketId: string, screenShare: boolean){
  //   if(screenShare){
  //     participantSocketId += '_screenShare';
  //   }


  // }

  mergeRemoteAudioStream(participantSocketId: string, mediaStream: MediaStream, screenShare: boolean) {

    //this.audioContext.resume();
    
    //const audioTrackId = mediaStream.getAudioTracks()[0]?.id;

    if(screenShare){
      participantSocketId += '_screenShare';
    }

    if(this.mergedAudioStreamsMap.has(participantSocketId)){
      const source = this.mergedAudioStreamsMap.get(participantSocketId);
      source.disconnect();
      this.endMonitorAudioLevel(participantSocketId);
    }
    
    if(mediaStream){  
      const source = this.playerAudioContext.createMediaStreamSource(mediaStream);

      // const voiceGain = this.audioContext.createGain();
      // voiceGain.gain.value = 1;



      source.connect(this.remoteMediaStreamDestination);

      

      this.mergedAudioStreamsMap.set(participantSocketId, source);

      
      this.monitorAudioLevel(participantSocketId, mediaStream);
      
    }
  }

  

  reportHighestVolumeLevel(){
    let highestVolumeSocketId: string;
    let highestVolume: number;
    const now = new Date().getTime();
    for(const socketId in this.volumeLevelsMap){
      const volume = this.volumeLevelsMap[socketId].volumeLevel;
      const timeStamp: Date = this.volumeLevelsMap[socketId].timeStamp;
      if((highestVolume === undefined || volume > highestVolume) && 
         socketId && 
         socketId != MeetingHandlerService.previewAudioLevelId && 
         timeStamp.getTime() > now - 500)
      {//we are checking that the timestamp is not more than 500 milliseconds old
        highestVolume = volume;
        highestVolumeSocketId = socketId;
      }
    }
    if(highestVolume){
      this.loudestParticipantReportedSubject.next({ participantSocketId: highestVolumeSocketId, volumeLevel: highestVolume });
    }
  }

  destroy(){
    // this.audioLevelReportedSubject = new Subject<{participantSocketId: string, volumeLevel: number}>();
    // this.loudestParticipantReportedSubject = new Subject<{participantSocketId: string, volumeLevel: number}>();
    if(this.loudestParticipantResetInterval){
      clearInterval(this.loudestParticipantResetInterval);
    }
    if(this.resumeAudioContextInterval){
      clearInterval(this.resumeAudioContextInterval);
    }

    this.playerAudioContext.close();
    this.monitorAudioContext.close();

    this.create();
    //clearInterval(this.loudestParticipantResetInterval);//no need to clear this interval on destroy() because there will be only one anyway since the interval is created in the constructor which will be called only once throughout the lifetime of the entire app
    //this.loudestParticipantResetInterval = undefined;
  }

  
}
