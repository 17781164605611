<header id="header">
  <div class="container-fluid">
    <div *ngIf="!adminpaused && !proctorpaused" class="row mx-0">
      <div class="d-flex align-items-center justify-content-between w-100 py-2">
        <div class="d-flex justify-content-start">
          <button type="button" [disabled]="qno <= 1" (click)="prevbtnclicked()" class="btn cornblue btn-responsive">
            <i class="fa fa-long-arrow-left btn-text"></i>
            <span class="btn-text">{{language.previous}}</span>
            <span class="btn-icon-only"><i class="fa fa-angle-left"></i></span>
          </button>
        </div>
        <div class="d-flex justify-content-center title-container">
          <h2 class="question-title" [title]="getQuestionTitle()">
            {{getQuestionTitle()}}
          </h2>
        </div>
        <div class="d-flex justify-content-end">
          <button type="button" *ngIf="!islast" (click)="nextbtnclicked()" class="btn cornblue btn-responsive">
            <span class="btn-text">{{language.next}}</span> <i class="fa fa-long-arrow-right btn-text"></i>
            <span class="btn-icon-only"><i class="fa fa-angle-right"></i></span>
          </button>
          <button *ngIf="!adminpaused && !proctorpaused && (islast)" type="button" (click)="finishclicked()"
            class="btn btn-danger btn-responsive">
            <span class="btn-text">{{language.finishAndSubmit}}</span>
            <span class="btn-icon-only">{{language.finishAndSubmit}}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</header>
