<div class="row justify-content-center login-container">
  <div class="col-lg-12 mt-5">
    <div class="col-lg-12mt-5">
      <div class=" col-lg-9 p-5 mt-5">
        <div style="border-radius:200px; height:200px; width:200px; background: #F8FAF5; margin:0 auto;">
          <i class="fas fa-sync-alt fa-spin"></i>
        </div>
        <h1 class="text-center mt-2"> {{language.verifyingEmail}}...</h1>
      </div>
    </div>
  </div>
</div>
