<!-- <div *ngIf="meetingHandler.localuserinfo && socket">
    <div>Username: {{meetingHandler.localuserinfo.username}}</div>
    <div>Socketid: {{socket.id}}</div>
</div> -->
<!--Nothing should be outside the meetingcontainer because we are trying to know if the meeting is focused or not-->
<div (focus)="focused(true)" (blur)="focused(false)" class="meetingcontainer" #meetingContainer
  [ngClass]="{'chatopen': this.chatopen, 'mobile': this.util.mobile(), 'gridmode' : !this.focusedMode, 'focusedmode': this.focusedMode, 'screen-share-showing': meetingHandler.screenShareAvailable || meetingHandler.participantScreenShareAvailable }">
  <div class="label-notifications-div">
    <div class="label-notification"
      [ngStyle]="{'z-index': labelNotification.visible ? 100 : -1, opacity: labelNotification.visible ? 1 : 0}"><span><i
          class="fas fa-info-circle"></i>{{labelNotification.notification}}</span></div>
  </div>
  <div class="centerdiv"
    [ngStyle]="{'width': this.focusedMode || meetingHandler.screenShareAvailable || meetingHandler.participantScreenShareAvailable ? centredivwidth : '0px', 'height': centredivheight}">
    <div class="screensharediv"
      [ngStyle]="{'display': meetingHandler.screenShareAvailable && !this.recording ? '' : 'none'}">
      <h1>You are sharing your screen</h1>
      <button (click)="toggleUserScreenShare()" class="stop-btn">Stop Sharing</button>
    </div>
    <div class="participantscreensharediv"
      [ngStyle]="{'display': meetingHandler.participantScreenShareAvailable || (meetingHandler.screenShareAvailable && this.recording) ? '' : 'none'}">
      <video #screensharevideo playsinline autoplay class="screensharevideo"></video>
      <div class="participantnamediv" [attr.title]="meetingHandler.screenShareParticipantName">
        <span class="namespan">{{meetingHandler.screenShareParticipantName}}</span>
      </div>
      <div class="screensharecontrolsdiv">
        <button (click)="participantScreenShareToggled()" class="fa fa-stack fa-times participantscreenshareclosebutton"
          *ngIf="meetingHandler.localuserinfo && meetingHandler.localuserinfo.host"></button>
      </div>
    </div>
    <div class="participantcontainerdiv focusedparticipant"
      [ngStyle]="{'display': !meetingHandler.participantScreenShareAvailable && !meetingHandler.screenShareAvailable && this.focusedMode ? '' : 'none'}">
      <!-- <div *ngIf="meetingHandler.participantsMap.size == 0 && meetingHandler.addedCurrentProducers && !meetingHandler.connecting" class="noonehereyetdiv"><span>No one else is here</span></div> -->
    </div>
    <i [hidden]="!this.recording" class="fas fa-circle record-meeting recording-indicator"></i>
  </div>
  <div class="participantsdiv"
    [ngClass]="{'participantshidden': this.focusedMode && (meetingHandler.participantsMap.size == 0 && !meetingHandler.screenShareAvailable && !meetingHandler.participantScreenShareAvailable), 'gridmode' : !this.focusedMode}"
    #participantsdiv>
    <div class="participantscontainerdiv" #participantsContainerDiv>
      <!-- <div *ngIf="meetingHandler.participantsMap.size == 0 && meetingHandler.addedCurrentProducers && !this.focusedMode" class="noonehereyetdiv"><span>No one else is here</span></div> -->
      <div class="participantcontainerdiv meparticipantcontainerdiv"
        [ngStyle]="{'order': meParticipantReservedOrder, width: this.focusedMode && meetingHandler.participantsMap.size == 0 && !meetingHandler.screenShareAvailable && !meetingHandler.participantScreenShareAvailable ? centredivwidth : (!this.focusedMode ? gridModeWidth : ''), 'height': !this.focusedMode ? gridModeHeight : ''}"
        [ngClass]="{'focusedparticipant': this.focusedMode && meetingHandler.participantsMap.size == 0 && !meetingHandler.screenShareAvailable && !meetingHandler.participantScreenShareAvailable}">
        <app-participant-video #meParticipantVideoComponent
          [participant]="meetingHandler.meParticipant"></app-participant-video>
      </div>
      <div
        *ngFor="let participant of meetingHandler.participantsMap | filterParticipants : this.maxDisplayedParticipants ; trackBy:identifyParticipant"
        class="participantcontainerdiv"
        [ngStyle]="{'order': participant.order, width: this.focusedMode && participant.order == 1 && !meetingHandler.screenShareAvailable && !meetingHandler.participantScreenShareAvailable ? centredivwidth : (!this.focusedMode ? gridModeWidth : ''), 'height': !this.focusedMode ? gridModeHeight : ''}"
        [ngClass]="{'focusedparticipant': this.focusedMode && participant.order == 1 && !meetingHandler.screenShareAvailable && !meetingHandler.participantScreenShareAvailable}"
        #participantcontainerdiv>
        <!-- <div class="testdiv" [ngStyle]="participant.value.getStyle()"></div> -->
        <app-participant-video #participantvideo [participant]="participant"
          (audioToggled)="participantAudioToggled($event)" (videoToggled)="participantVideoToggled($event)"
          (lockToggled)="participantLockToggled($event)"></app-participant-video>
      </div>
      <i class="fas fa-circle record-meeting recording-indicator" [hidden]="!this.recording"></i>
    </div>
    <div
      *ngIf="meetingHandler.participantsMap.size > 0 && meetingHandler.addedCurrentProducers && meetingHandler.participantsMap.size > this.maxDisplayedParticipants"
      class="showmoreparticipantsbuttondiv">
      <button class="showmoreparticipantsbutton" (click)="showMoreParticipants()">Show More</button>
    </div>

    <!-- <div class="participantsnavigationdiv" *ngIf="participantsNumPages > 1">
        <button class="prevparticipants" [disabled]="participantsPageNo < 2 ? 'disabled' : null"><i class="fa fa-caret-left" (click)="prevParticipantsPage()"></i></button>
        <span class="participantspagenospan">{{participantsPageNo}} of {{participantsNumPages}}</span>
        <button class="nextparticipants" [disabled]="participantsPageNo >= participantsNumPages ? 'disabled' : null"><i class="fa fa-caret-right" (click)="nextParticipantsPage()"></i></button>
      </div> -->
  </div>
  <div class="meetingchatdiv text-body" [ngStyle]="{'width': chatopen ? null : '0px'}">
    <app-meeting-chat (closeEvent)="showHideChat()" #meetingChat></app-meeting-chat>
  </div>
  <div *ngIf="!this.meetingHandler.recorder" class="usercontrols">
    <button class="fa-stack"
      [ngClass]="{mediadisabled: !meetingHandler.audioAvailable, 'bg-grey': !meetingHandler.audioAvailable}"
      (click)="toggleUserMicrophone()">
      <i class="fa" [ngClass]="[this.meetingHandler.audioAvailable ? 'fa-microphone' : 'fa-microphone-slash']"></i>
      <!-- <i *ngIf="!meetingHandler.audioAvailable" class="fa fa-microphone-slash"></i> -->
    </button>
    <button class="fa-stack"
      [ngClass]="{mediadisabled: !meetingHandler.videoAvailable, 'bg-grey': !meetingHandler.videoAvailable}"
      (click)="toggleUserCamera()">
      <i class="fas" [ngClass]="[this.meetingHandler.videoAvailable ? 'fa-video' : 'fa-video-slash']"></i>
      <!-- <i *ngIf="!meetingHandler.videoAvailable" class="fa fa-video-slash"></i> -->
    </button>
    <button *ngIf="!util.mobile()" class="fa-stack"
      [ngClass]="{mediadisabled: !meetingHandler.screenShareAvailable, 'bg-grey': !meetingHandler.screenShareAvailable}"
      (click)="toggleUserScreenShare()">
      <i class="fa fa-desktop fa-stack-1x"></i>
      <!-- <i *ngIf="!meetingHandler.screenShareAvailable" class="fa fa-slash fa-stack-1x"></i> -->
    </button>
    <button class="fa-stack" [ngClass]="{mediadisabled: !chatopen, 'bg-grey': !chatopen}" (click)="showHideChat()">
      <i class="fas fa-comment fa-stack-1x"></i>
      <span [hidden]="this.meetingChat.unreadMessages == 0" class="sup">{{this.meetingChat.unreadMessages}}</span>
      <!-- <i *ngIf="!chatopen" class="fa fa-slash fa-stack-1x"></i> -->
    </button>
    <button class="fa-stack"
      [ngClass]="{mediadisabled: !meetingHandler.handraised, 'bg-grey': !meetingHandler.handraised}"
      (click)="toggleHandRaised()">
      <i class="fas fa-hand-paper fa-stack-1x"></i>
      <!-- <i *ngIf="!meetingHandler.handraised" class="fa fa-slash fa-stack-1x"></i> -->
    </button>
    <button *ngIf="!meetingHandler.localuserinfo.host" class="fas fa-stack fa-phone-alt" data-toggle="modal"
      data-target="#leaveModal"></button>
    <button *ngIf="meetingHandler.localuserinfo.host" class="fas fa-stack fa-phone-alt" data-toggle="modal"
      data-target="#endMeetingModal"></button>
    <!-- <button *ngIf="this.util.canRecord() && this.recording" class="end-recording-button" (click)="this.endRecordMeeting(true)"><i class="fas fa-save"></i>Save Recording</button> -->

    <div [ngClass]="{'usercontrols__right': !this.util.mobile()}" class="inline-block">
      <!-- <button *ngIf="meetingHandler.localuserinfo.host" class="fa-stack position-relative bg-grey" (click)="showParticipantsModal()" id="participantsbutton">
          <i class="fa fa-user"></i>
          <span class="sup">{{meetingHandler.participantsMap.size + 1}}</span>
        </button>
        <button *ngIf="!meetingHandler.localuserinfo.host" class="fa-stack position-relative bg-grey">
          <i class="fa fa-user"></i>
          <span class="sup">{{meetingHandler.participantsMap.size + 1}}</span>
        </button> -->
      <button class="fa-stack position-relative bg-grey">
        <i class="fa fa-user"></i>
        <span class="sup">{{meetingHandler.participantsMap.size + 1}}</span>
      </button>
      <button (click)="toggleLayout()" class="fa-stack"
        [ngClass]="{mediadisabled: this.focusedMode, 'bg-grey': this.focusedMode}">
        <i class="fas fa-th"></i>
      </button>
    </div>

    <div class="otherbuttons">
      <button type="button" id="otherbuttonsdropdownbutton" data-toggle="dropdown" aria-haspopup="true"
        aria-expanded="false"><i class="fas fa-ellipsis-h text-white"></i></button>
      <div class="otherbuttonsdropdowndiv dropdown-menu" aria-labelledby="otherbuttonsdropdownbutton">
        <button class="dropdown-item" (click)="showMediaSettings()">Settings</button>
        <button *ngIf="meetingHandler.localuserinfo.host" class="dropdown-item" (click)="muteAllParticipants()">Mute
          All</button>
        <button *ngIf="!settings.inFullScreen" class="dropdown-item" (click)="util.enterFullscreen()">Full
          Screen</button>
        <button *ngIf="settings.inFullScreen" class="dropdown-item" (click)="util.exitFullscreen()">Exit Full
          Screen</button>
        <!-- <button
          *ngIf="this.recordingService.canRecord() && (meetingHandler.localuserinfo.host || (this.meetingHandler.meetinginfo && this.meetingHandler.meetinginfo.allowNonHostsRecord)) && !this.recording"
          class="dropdown-item" (click)="this.recordMeeting()"><i class="fas fa-circle record-meeting"></i><span
            class="ml-1">Record</span></button>
        <button *ngIf="this.recordingService.canRecord() && this.recording" class="dropdown-item"
          (click)="this.endRecordMeeting(true)"><i class="fas fa-square record-meeting"></i><span class="ml-1">End
            Recording</span></button> -->
        <!-- <button class="fas fa-cog fa-stack" (click)="showMediaSettings()"></button>
            <button *ngIf="meetingHandler.localuserinfo.host" class="fa-stack" (click)="muteAllParticipants()">
              <i class="fa fa-microphone fa-stack-1x" style="top: 2px"></i>
              <i class="fa fa-slash fa-stack-1x"></i>
              <i class="fa fa-slash fa-stack-1x" style="top: 5px"></i>
            </button>
            <button *ngIf="!settings.inFullScreen" class="fas fa-stack fa-expand-arrows-alt" (click)="util.enterFullscreen()"></button>
            <button *ngIf="settings.inFullScreen" class="fas fa-stack fa-compress-arrows-alt" (click)="util.exitFullscreen()"></button> -->
      </div>

    </div>
  </div>
  <div *ngIf="!this.meetingHandler.recorder" class="d-none d-sm-block">
    <app-participant-audio #meParticipantAudioComponent
      [participant]="meetingHandler.meParticipant"></app-participant-audio>
  </div>

  <div class="modals-container text-body">
    <div class="modal" tabindex="-1" role="dialog" id="leaveModal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Leave?</h5>
            <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button> -->
          </div>
          <div class="modal-body">
            <p>Are you sure you want to leave?</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="leaveMeeting()">Yes</button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" tabindex="-1" role="dialog" id="endMeetingModal" *ngIf="meetingHandler.localuserinfo.host">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">End Meeting?</h5>
            <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button> -->
          </div>
          <div class="modal-body">
            <button type="button" class="btn btn-danger"
              (click)="this.confirm(this.endMeeting, this.closeEndMeetingModal, 'Are you sure?', 'This will end the meeting for all participants', 'btn-danger')">End
              Meeting For All</button>
            <button type="button" class="btn btn-primary"
              (click)="this.confirm(this.leaveMeeting, this.closeEndMeetingModal, 'Are you sure?', 'Are you sure you want to leave?')">Leave
              Meeting</button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal" style="float: right;">Cancel</button>
          </div>
          <!-- <div class="modal-footer">
              <button type="button" class="btn btn-primary" (click)="changeSettings()">Ok</button>
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
            </div> -->
        </div>
      </div>
    </div>

    <div class="modal" tabindex="-1" role="dialog" id="settingsModal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header d-block text-center">
            <h5 class="modal-title">Change Settings</h5>
            <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button> -->
          </div>
          <div class="modal-body">
            <div class="text-center">Note: Changes will take effect when you click "ok"</div>
            <app-select-media #selectMediaComponent></app-select-media>
            <!-- <router-outlet></router-outlet> -->
          </div>
          <div class="modal-footer text-center d-block">
            <button type="button" class="btn btn-primary" (click)="changeSettings()">Ok</button>
            <button type="button" class="btn btn-secondary" (click)="hideSettingsModal()">Cancel</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" tabindex="-1" role="dialog" id="reconnectingModal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Please Wait</h5>
          </div>
          <div class="modal-body">
            <p>Reconnecting...</p>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" tabindex="-1" role="dialog" id="participantsModal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Participants</h5>
            <button type="button" class="fa fa-times border-0 bg-transparent text-body" data-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div class="modal-body participants-modal-body">
            <app-participants-list [participantsMap]="meetingHandler.participantsMap"
              (breakOutClicked)="meetingHandler.breakIntoRooms()" #participantsList></app-participants-list>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" tabindex="-1" role="dialog" id="confirmModal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{this.confirmModalCaption}}</h5>
          </div>
          <div class="modal-body">
            <p>{{this.confirmModalBody}}</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn" [ngClass]="[(this.confirmModalYesButtonStyle)]"
              (click)="this.confirmModalYes()">{{this.confirmModalYesText}}</button>
            <button type="button" class="btn" [ngClass]="[(this.confirmModalNoButtonStyle)]"
              (click)="this.confirmModalNo()">{{this.confirmModalNoText}}</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal modal-sm" data-backdrop="false" data-keyboard="false" tabindex="-1" id="attendanceModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Attendance</h5>
          </div>
          <div class="modal-body">
            <p>Are you there?</p>
          </div>
          <div class="modal-footer">
            <button type="button" (click)="attendanceClicked()" class="btn btn-success btn-sm" data-dismiss="modal">Yes,
              I'm here</button>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="modal" data-backdrop="static" tabindex="-1" id="recordMeetingModal">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Record Meeting</h5>              
                </div>
                <div class="modal-body">
                    <div>
                      To proceed we will need to record your screen. When prompted, please allow the sharing, and select where you want the recording to be saved.
                    </div>
                    <div>
                      <ol class="recording-list mt-3">
                        <li>
                          <span>Select recording file name <button class="btn btn-primary btn-sm" (click)="selectRecordingSaveLocation()"><i class="fas fa-save mr-2"></i><span>Select File</span></button> <small [hidden]="!this.recordingFilename" class="ml-4">({{this.recordingFilename}})</small></span>
                          
                        </li>
                      </ol>
                    </div>
                    <!- <div>
                      <div><span>Click here to select the recording save location</span><button class="btn btn-primary" (click)="selectRecordingSaveLocation()"><i class="fas fa-save record-meeting"></i><span>Select File</span></button></div>
                      <div>Selected File: <span>{{this.recordingFilename}}</span></div>
                    </div>
                    <div>
                      <span>Click here to start the recording</span><button class="btn btn-primary" (click)="startRecording()"><i class="fas fa-circle record-meeting"></i><span>Start Recording</span></button>
                    </div> ->
                </div>
                <div class="modal-footer">
                  <!- <button type="button" class="btn btn-primary">Start</button> ->
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
      </div> -->

  </div>
  <div class="audiodiv" #audioDiv>
    <audio #audio autoplay id="audioPlayer"></audio>
    <app-participant-audio [participant]="participant.value" #participantaudio
      *ngFor="let participant of meetingHandler.participantsMap | keyvalue; trackBy:identifyParticipantInMap"
      [attr.socketid]="participant.socketid"></app-participant-audio>
  </div>
  <div [ngStyle]="{display: meetingHandler.connecting ? 'block' : 'none' }" class="connectingdiv"></div>
</div>

<!--Nothing should be outside the meetingcontainer because we are trying to know if the meeting is focused or not-->