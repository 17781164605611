<div [hidden]="this.ready" class="meeting-title please-wait-div">
    <div>Please wait<app-loading-ellipses></app-loading-ellipses></div>
</div>
<h2 [hidden]="!this.ready || inMeeting" class="meeting-title text-center my-4">{{this.meetinginfo.title}}</h2>
<div *ngIf="!this.recorder" class="selectmediacontainer" [hidden]="!this.ready">
    <div class="settingscontainerdiv">
        <div [hidden]="!this.authorizedUser" class="videodiv">
            <app-participant-video-setup-view #settingsVideo (videoSettingToggled)=videoSettingToggled()
                (audioSettingToggled)=audioSettingToggled() [inMeeting]="inMeeting"></app-participant-video-setup-view>
            <div class="mt-1">
                <div class="settingsdiv pb-0">
                    <div class="settingswidth">
                        <!-- <h3 class="changesettingslabel mb-2">Meeting Settings</h3> -->
                    </div>
                </div>
                <div *ngIf="this.meetinginfo.mode != this.util.meetingmode && !this.localuserinfo.host && this.ready"
                    class="nothostinfowrap">
                    <p class="settingswidth"><span class="font-weight-bold">Note: </span>Your webcam and microphone may
                        be disabled until the host allows you to speak</p>
                </div>
                <div *ngIf="!inMeeting" class="joinbuttondiv">
                    <button (click)="enterMeeting()" #joinbutton [disabled]="this.joining || !this.ready"
                        class="btn btn-primary"><span
                            *ngIf="(!meetinginfo.started || meetinginfo.ended) && localuserinfo.host; else joinTemplate">Start</span><ng-template
                            #joinTemplate><span>Join</span></ng-template></button>
                    <div *ngIf="this.joining" class="mt-1">
                        <span
                            *ngIf="(!this.meetinginfo.started || this.meetinginfo.ended) && startingmeeting; else joiningTemplate">Starting
                            Meeting. Please Wait<app-loading-ellipses></app-loading-ellipses></span>
                    </div>
                    <ng-template #joiningTemplate>
                        <span
                            *ngIf="(this.meetinginfo.started && !this.meetinginfo.ended) || this.localuserinfo.host; else waitingForHostTemplate">Please
                            wait while we connect you<app-loading-ellipses></app-loading-ellipses></span>
                        <ng-template #waitingForHostTemplate>
                            <span>Waiting for host to start the
                                meeting<app-loading-ellipses></app-loading-ellipses></span>
                        </ng-template>
                    </ng-template>

                </div>
            </div>
        </div>
        <div *ngIf="this.authorizedUser" class="settingsdiv">
            <div class="settingswidth mt-4">
                <h3 class="changesettingslabel mb-3">Device Settings</h3>
                <div>
                    <div>
                        <div class="settingsdiv-rows">
                            <div class="settingsdiv-rows-left">
                                <label for="camera" class="mb-0">Camera: </label>
                            </div>
                            <div class="settingsdiv-rows-right">
                                <select [disabled]="this.inMeeting && !this.settingsVideo.videoEnabled"
                                    [(ngModel)]="selectedCamera" (ngModelChange)="onCameraChange($event)" id="camera"
                                    class="frame p-2">
                                    <option *ngFor="let device of this.util.videoDevices; trackBy: identifyDevice"
                                        value="{{device.DeviceId}}">{{device.DeviceName}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="settingsdiv-rows">
                            <div class="settingsdiv-rows-left">
                                <label for="microphone" class="mb-0">Microphone: </label>
                            </div>
                            <div class="settingsdiv-rows-right">
                                <select [disabled]="this.inMeeting && !this.settingsVideo.audioEnabled"
                                    [(ngModel)]="selectedMicrophone" (ngModelChange)="onMicrophoneChange($event)"
                                    id="microphone" class="frame p-2">
                                    <option *ngFor="let device of this.util.audioDevices; trackBy: identifyDevice"
                                        value="{{device.DeviceId}}">{{device.DeviceName}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="this.authorizedUser !== undefined && !this.authorizedUser" class="modal d-flex justify-content-center"
    data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" id="usernamemodal">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Enter your name</h5>
            </div>
            <div class="modal-body">
                <input type="text" id="username" [(ngModel)]="username" placeholder="Enter your name" />
            </div>
            <div class="modal-footer">
                <button (click)="publicuserContinue()" class="btn btn-primary offset-md-3"><span>OK</span></button>
            </div>
        </div>
    </div>
</div>