import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProctoringService } from '../services/proctoring.service';
import { QuestionserviceService } from '../services/questionservice.service';
import { UserauthenticationserviceService } from '../services/userauthenticationservice.service';
import { environment } from 'src/environments/environment';
import { UtilService } from '../meeting/services/util.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(public userauth:UserauthenticationserviceService, public router:Router, private proctorservice: ProctoringService, public activatedroute:ActivatedRoute, public questionservice:QuestionserviceService, private utilService: UtilService) { }

  async ngOnInit() {
    const seb = this.userauth.getSEBKey();
    //this.questionservice.previousquestiondata = null;
    //this.questionservice.nextquestiondata = null;
    this.proctorservice.stopProctor('User Examination Page Init');
    const unauthorized = this.activatedroute.snapshot.queryParams.unauthorized;
    const stayinseb = this.activatedroute.snapshot.queryParams.stayinseb;
    const isexternalauth = this.userauth.getIsExternalAuth();
    let user = null;
    try{
      user = await this.userauth.getCurrentUser();
    }
    catch(e: any){
      console.error(e);
    }
    //debugger;
    const isexternalauthbool = isexternalauth == 'true' ? true : false;
    //const stayinsebbool = stayinseb == 'true' ? true : false;

    if(seb && !stayinseb){
      try{
        await this.proctorservice.quitSEB(user?.username).finally(()=>{
          window.location.href = environment.quitseburl;
        });
      }
      catch(error){
        console.error(error);
      }
    }
    //if there are still things to be sent to the server
    //we can't wait indefinitely...2 seconds should be fine
    if(this.questionservice.markingQueue.length > 0){
      await this.utilService.sleep(2 * 1000)
    }
    this.userauth.logout(unauthorized, isexternalauthbool);
  }

}
