import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CountdownComponent } from 'ngx-countdown';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { CbtserviceService } from '../services/cbtservice.service';
import { ExamService } from '../services/exam.service';
import { ProctoringService } from '../services/proctoring.service';
import { QuestionserviceService } from '../services/questionservice.service';
import { UserauthenticationserviceService } from '../services/userauthenticationservice.service';
import { CurrentUser } from '../models/CurrentUser';
import { LanguageService } from '../services/language.service';

@Component({
  selector: 'app-questionpageheader',
  templateUrl: './questionpageheader.component.html',
  styleUrls: ['./questionpageheader.component.css']
})
export class QuestionpageheaderComponent implements OnInit {
  @Output() nextclicked: EventEmitter<number> = new EventEmitter<number>();
  @Output() previousclicked: EventEmitter<number> = new EventEmitter<number>();
  @Output() saveclicked: EventEmitter<number> = new EventEmitter<number>();
  @Output() timeelapsed: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() flagclicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() adminpaused: boolean;
  @Input() proctorpaused: boolean;
  @Input() essayexam: boolean
  @Input() scheduleid: any
  @Input() examid: any
  
  @Input() status: string;
  @Input() url: any
  @Input() islast: boolean
  @Input() qno: number
  @Input() qnolabel: string;
  @Input() qcatergoy: string;
  @Input() flaggedquestion: boolean = false
  @Input() position: string; //top | bottom
  @Input() useProctor: boolean;
  @Input() liteMode: boolean;
  @Input() showSubmitButtonOnEveryQuestion: boolean
  @Input() qCount: number;
  @ViewChild('countdown') countdown: CountdownComponent;
  thisurl = location.origin + '/';
  timeleftinterval: any
  countdowntimeout: any;
  pausestatuscheckinterval: any;
  settimeleftonproctorinterval: any;
  user: CurrentUser = new CurrentUser();
  constructor(public activatedroute: ActivatedRoute, public router: Router, public toastr: ToastrService, public examservice: ExamService, private cbtservice: CbtserviceService, public proctorservice: ProctoringService, public userauth: UserauthenticationserviceService, public questionservice: QuestionserviceService, private languageService: LanguageService) { }
  get language() {
    return this.languageService.language;
  }
  async ngOnInit() {
    //debugger;
    this.user = await this.userauth.getCurrentUser();
    await this.checkstatus();

    console.log("hit on init")
    // // if (this.position == 'top') {//because we don't want to send it twice
    // //   if (this.useProctor) {
    // //     this.proctorservice.setCountdownTimer(this.user.username, this.timeleft);
    // //   }
    // //   if (!this.settimeleftonproctorinterval) {
    // //     this.settimeleftonproctorinterval = setInterval(() => {
    // //       if (this.useProctor) {//checking again here because the exam could be changed
    // //         this.proctorservice.setCountdownTimer(this.user.username, this.timeleft);
    // //       }
    // //       //debugger;

    // //     }, 10000);
    // //   }
    // //   //console.log("sent to proctor")
    // // }
    // if (!this.proctorpaused && !this.adminpaused) {
    if (!this.timeleftinterval) { this.timeleftinterval = this.timeinterval(); }
    //   console.log("interval set " + (this.position == 'top' ? "at top" : "at bottom") + this.timeleftinterval)
    // }

  }

  timeinterval() {
    return setInterval(() => {
      this.checkstatus();
      //debugger;
      //console.log("interval set " + (this.position == 'top' ? "at top" : "at bottom") + this.timeleftinterval)

    }, 1000);
  }
  async checkstatus() {
    // var res: any = await this.examservice.getexampausestatusandtimeleftschedule(scheduleid).catch(err => this.cbtservice.showHttpError(err));//TODO: get time remaining based on value already in client, not getting it from the server
    //if (!this.timeleft)// || (res.tleft != (this.timeleft - 5)))//minus 5 because the code to get timeleft executes every 5 seconds
    if (this.status) {
      if (this.status == "In Progress") {
        //this.proctorservice.setCountdownTimer(this.userauth.currentUser.username, res.tleft)
        // if (this.position == 'top') {//because we don't want to send it twice
        //   this.proctorservice.setCountdownTimer(this.userauth.currentUser.username, this.timeleft);
        //   //console.log("sent to proctor there")
        // }
        //this.timeleft = this.tleft;
      } else {
        if(!environment.production){
          debugger;
          console.trace('going to exams page');
        }
        this.router.navigate(['/exam/userexams']);
      }
    }
    if (!this.proctorpaused && !this.adminpaused /*&& this.countdown*/)
      this.countdowntimeout = setTimeout(() => {
        if (this.countdown) this.countdown.resume();
      });
  }
  ngOnDestroy() {
    if (this.timeleftinterval) {
      clearInterval(this.timeleftinterval);
      console.log("interval cleared " + (this.position == 'top' ? "at top" : "at bottom") + this.timeleftinterval)

    }
    if (this.pausestatuscheckinterval) {
      clearInterval(this.pausestatuscheckinterval);
    }
    if (this.settimeleftonproctorinterval) {
      clearInterval(this.settimeleftonproctorinterval);
    }
    this.timeleftinterval = null;
    this.pausestatuscheckinterval = null;
  }

  ngAfterViewInit() {
    this.checkstatus();
    if (!this.pausestatuscheckinterval) this.pausestatuscheckinterval = this.pauseinterval();
  }

  pauseinterval() {
    return setInterval(() => {
      const mythis = this;
      if (this.proctorpaused || this.adminpaused) {
        this.checkstatus();
        if (this.timeleftinterval) clearInterval(this.timeleftinterval);
        console.log("interval cleared " + (this.position == 'top' ? "at top" : "at bottom") + this.timeleftinterval)

        if (this.countdowntimeout) clearTimeout(this.countdowntimeout);
        this.countdown.pause();
      }
      else {
        if (!this.timeleftinterval) {
          this.checkstatus();
        }
      }
    }, 1000);
  }

  async finishclicked() {
    if (!this.liteMode) {
      await this.questionservice.waitForAllQuestionsToBeSubmitted();
    }
    //debugger;
    this.router.navigate(['/exam/submit'], { queryParams: { scheduleid: this.scheduleid, examid: this.examid, url: this.url } });
    localStorage.removeItem(`${this.scheduleid}_post_finish_data`);
  }

  radiodiabbletoggle(button) {
    button.disabled = true;
  }
  async nextbtnclicked() {
    //this.disableradios();
    //await this.gettimeleft(this.scheduleid);
    // console.log("there")
    this.nextclicked.emit(this.qno + 1);
  }
  async prevbtnclicked() {
    //this.disableradios();
    //await this.gettimeleft(this.scheduleid);
    this.previousclicked.emit(this.qno - 1);

  }
  savebtnclicked() {
    this.saveclicked.emit(this.qno);
  }
  async flagquestionforreview() {
    this.flaggedquestion = !this.flaggedquestion;
    let flag = "flagged";
    let unflag = "unflagged";
    //var res: any = await this.questionservice.flagquestion(this.scheduleid, this.qno, this.flaggedquestion, this.examno,this.examid).catch(err => { this.cbtservice.showHttpError(err); return; });//TODO: get time remaining based on value already in client, not getting it from the server
    this.flagclicked.emit(this.flaggedquestion);
    this.toastr.success(`${this.language.questionHasBeen}  ${this.flaggedquestion ? this.language.flagged : this.language.unflagged}`)

  }

  getQuestionTitle(): string {
    const prefix = this.qnolabel ? 
      `${this.qnolabel}` : 
      `${this.language.abbreviatedQuestionNo} ${this.qno} of ${this.qCount}`;
    
    return `${prefix} ${this.qcatergoy ? '(' + this.qcatergoy + ')' : ''}`;
  }
}
