

<!-- <span *ngIf="!localParticipant"><span>{{meeting.participantsUsernameMap.get(username).name}}</span> <span *ngIf="participant.host"> (Host)</span></span> -->
<span *ngIf="meetingHandler.participantsMap.has(meetingHandler.getParticipantByUsername(username).socketid) || localParticipant">
    <label>
        <span class="checkbox-span"><input type="checkbox" #selectedforgroup [(ngModel)]="selected" [ngStyle]="{'display': !participantlist.addparticipantsmode ? 'none' : ''}" /></span>
        <span *ngIf="meetingHandler.getParticipantByUsername(username).handRaised" class="mr-1">
            <i class="far fa-hand-paper"></i>
        </span>
        <span>{{meetingHandler.getParticipantByUsername(username).name}}</span> 
        <span *ngIf="meetingHandler.getParticipantByUsername(username).host"> (Host)</span>
    </label>
</span>